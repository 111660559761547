define(() => {

	class TableSelectorLocalRendererRows
	{

		constructor($tbody)
		{
			this.tbody = $tbody[0];
			this.positions = new Map;
			this.added = false;
			this.deleted = false;
			this.ordered = false;
		}

		has(position, ignoreDeleted = false)
		{
			if (this.positions.has(position))
			{
				if (ignoreDeleted) return true;
				const row = this.positions.get(position);
				return row.deleted === false;
			}
			return false;
		}

		set(position, $el, filled)
		{
			const row = {
				el: $el[0],
				filled: Boolean(filled),
				deleted: false,
				dom: false,
			};
			let addedHere = true;
			if (this.positions.has(position))
			{
				const old = this.positions.get(position);
				if (old.dom === true)
				{
					row.dom = true;
					this.tbody.replaceChild(row.el, old.el);
					addedHere = false;
				}
			}
			this.positions.set(position, row);
			this.added = addedHere || this.added;
			this.ordered = false;
		}

		delete(position)
		{
			if (this.positions.has(position))
			{
				const row = this.positions.get(position);
				if (row.deleted === false)
				{
					row.deleted = true;
					this.deleted = true;
				}
			}
		}

		keep(position)
		{
			if (!this.positions.has(position))
			{
				throw new Error(position);
			}
			const row = this.positions.get(position);
			if (row.deleted === true)
			{
				row.deleted = false;
				if (row.dom === false)
				{
					this.added = true;
				}
			}
		}

		iterate()
		{
			if (this.ordered === false)
			{
				const sorted = Array.from(this.positions.entries()).sort(([p1], [p2]) => p1 - p2);
				this.positions = new Map(sorted);
				this.ordered = true;
			}
			return this.positions;
		}

		iterateNotFilledPositions()
		{
			const r = [];
			for (const [position, row] of this.positions)
			{
				if (row.filled === false && row.deleted === false)
				{
					r.push(position);
				}
			}
			return r;
		}

		iterateToBeDeletedPositions()
		{
			const r = [];
			for (const [position, row] of this.positions)
			{
				if (row.deleted === true && row.dom === true)
				{
					r.push(position);
				}
			}
			return r;
		}

		getFirstDomPosition()
		{
			for (const [position, row] of this.iterate())
			{
				if (row.dom === true)
				{
					return position;
				}
			}
			return undefined;
		}

		flushAddedToDom(trigger = null)
		{
			if (this.added === true)
			{
				let fragment = document.createDocumentFragment();
				const flush = (before = null) => {
					if (fragment.hasChildNodes())
					{
						this.tbody.insertBefore(fragment, before);
						fragment = document.createDocumentFragment();
					}
				};
				for (const [position, row] of this.iterate())
				{
					if (row.dom === true)
					{
						flush(row.el);
					}
					else if (row.deleted === false)
					{
						trigger && trigger(position);
						row.dom = true;
						fragment.appendChild(row.el);
					}
				}
				flush();
				this.added = false;
			}
		}

		flushDeletedToDom()
		{
			if (this.deleted === true)
			{
				for (const [position, row] of this.iterate())
				{
					if (row.deleted === true)
					{
						if (row.dom === true)
						{
							this.tbody.removeChild(row.el);
							row.dom = false;
						}
						this.positions.delete(position);
					}
				}
				this.deleted = false;
			}
		}

	}

	return TableSelectorLocalRendererRows;
});
