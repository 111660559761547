
/**
 * Umi nacitat zavislosti ze 3 mist:
 * <pre>
 * 	Foo/Bar       =>  /public/js/Foo/Bar.js
 * 	libs/Foo/Bar  =>  /public/libs/Foo/Bar.js
 * 	app/Foo/Bar   =>  /app/Foo/Bar.js
 * </pre>
 */
require.config({
	enforceDefine: true,
	waitSeconds: 30,
	urlArgs: 'cache=' + Sim.config.buildKey,
	baseUrl: Sim.config.basePath + '/public/js',
	paths: {
		'libs': '../libs',
		'app': '../../app',
	},
});

define('jquery', [], _.constant(window.jQuery));
Sim.require.init();
