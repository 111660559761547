define([
	'./TableSelectorElements',
	'./TableSelectorPaginator',
	'./TableSelectorAjaxDataLoader',
	'./TableSelectorFullTable',
	'./TableSelectorLocalRenderer',
	'./TableSelectorLocalTable',
	'./TableSelectorScroller',
	'./TableSelectorToggler',
	'app/components/AClickableArea/AClickableArea',
	'./TableSelectorLocalRendererRows',
	'./TableSelectorLocalRendererAnimation',
	'libs/bezier-easing/index',
], (
	TableSelectorElements,
	TableSelectorPaginator,
	TableSelectorAjaxDataLoader,
	TableSelectorFullTable,
	TableSelectorLocalRenderer,
	TableSelectorLocalTable,
	TableSelectorScroller,
	TableSelectorToggler,
	AClickableArea,
) => {

	class TableSelector
	{

		constructor(localTDIndexes, ajax)
		{
			this.elements = new TableSelectorElements;
			this.paginator = new TableSelectorPaginator(this.elements);
			this.ajaxDataLoader = new TableSelectorAjaxDataLoader(ajax, this.elements, this.paginator);
			this.fullTable = new TableSelectorFullTable(this.elements, this.paginator, this.ajaxDataLoader);
			this.localRenderer = new TableSelectorLocalRenderer(localTDIndexes, this.elements, this.paginator);
			this.localTable = new TableSelectorLocalTable(this.elements, this.paginator, this.localRenderer, this.fullTable);
			this.scroller = new TableSelectorScroller(this.elements, this.paginator, this.localTable);
			this.toggler = new TableSelectorToggler(this.elements, this.paginator, this.localTable, this.fullTable);

			new AClickableArea({
				container: this.elements.tsTableScroll, // animated table
			});
		}

	}

	return TableSelector;
});
