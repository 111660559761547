define(['../Simditor', '../Utils'], (Simditor, Utils) => {

	class BaseButton extends Simditor.Button
	{

		iconClassOf(icon)
		{
			return icon;
		}

		mustDisableForHtmlMode()
		{
			return true;
		}

		isGloballyAllowedTag(tag, attr = null)
		{
			return Utils.isGloballyAllowedTag(this.editor, tag, attr);
		}

	}

	return BaseButton;
});
