define(['./BaseButton'], (BaseButton) => {

	const history = window.history;

	class FullscreenButton extends BaseButton
	{

		get name() { return 'fullscreen'; }
		get icon() { return 'icon-fullscreen'; }
		get needFocus() { return false; }

		mustDisableForHtmlMode()
		{
			return false;
		}

		_init()
		{
			super._init();

			this.$window = $(window);
			this.$documentElement = $('html');

			window.addEventListener('beforeunload', (e) => {
				if (this.active)
				{
					e.preventDefault();
					e.returnValue = 'Leave?';
					this.command(false);
				}
			});

			this.onEscapePress = Sim.onEscapePress(() => this.command(false));

			this.onResize = Sim.onResize.create(null, () => {
				const height = this.$window.height() - this.editor.toolbar.wrapper.outerHeight();
				const bodyPadding = this.editor.body.outerHeight() - this.editor.body.height();
				this.editor.body.height(height - bodyPadding);
				const textareaPadding = this.editor.textarea.outerHeight() - this.editor.textarea.height();
				this.editor.textarea.height(height - textareaPadding);
			});

			$('<a href="javascript:void(0)">')
				.addClass('o-htmlEditor__fullscreenCloseButton icon-remove')
				.on('click', () => this.command(false))
				.appendTo(this.editor.wrapper)
			;
		}

		_status()
		{
		}

		command(param = null)
		{
			const clsName = 'o-htmlEditor--isFullscreenMode';
			param = param === null ? !this.editor.el.hasClass(clsName) : !!param;
			this.$documentElement.toggleClass(clsName, param);
			this.editor.el.toggleClass(clsName, param);
			this.setActive(param);
			if (param)
			{
				this.onEscapePress.on();
				this.onResize.on();
			}
			else
			{
				this.onEscapePress.off();
				this.onResize.off();
				this.editor.body.height('');
				this.editor.textarea.height('');
			}
			this.onResize.trigger();
		}

	}

	return FullscreenButton;
});
