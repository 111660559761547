(() => {

	class IgnoreErrors
	{

		static #aliases = {
			0: 'name',
			1: 'message',
			2: 'userAgent',
		};

		#globalState;
		#rules;
		#predefined;

		constructor(globalState)
		{
			this.#globalState = globalState;
		}

		*getHardCodedRules()
		{
			yield [ // Google Search app (iOS)
				'TypeError',
				"null is not an object (evaluating 'elt.parentNode')",
				'* GSA/* Mobile/* Safari/*',
			];

			yield [ // https://bugs.chromium.org/p/chromium/issues/detail?id=590375
				'TypeError',
				"undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
				'* CriOS/* Mobile/* Safari/*',
			];

			yield [ // https://bugzilla.mozilla.org/show_bug.cgi?id=13942
				'TypeError',
				[
					"undefined is not an object (evaluating 'window.__firefox__.reader.checkReadability')",
					"undefined is not an object (evaluating '__firefox__.favicons.getFavicons')",
					"undefined is not an object (evaluating '__firefox__.metadata.extractMetadata')",
				],
				'* FxiOS/* Mobile/* Safari/*',
			];

			yield [
				'Content Security Policy',
				"Refused to load img-src 'https://www.gstatic.com/images/branding/product/2x/translate_24dp.png' because it violates the Content Security Policy directive at*",
				'* Chrome/*',
			];

			yield [ // lastpass and empty favicon
				'IMG GET error',
				'https://*/favicon.ico*',
				'* Chrome/*',
				{'elSelector': '* BODY > IMG'},
			];

			yield [
				'Error',
				'Could not load "*".',
				['* Firefox/*', '* Version/* Mobile/* Safari/*'],
				{'fileName': 'https://maps.googleapis.com/maps/api/js?*'},
				() => !this.#globalState.isConnectionReliable(),
			];

			yield [
				'IgnoredEventCancel[intervention]',
				'Ignored attempt to cancel a * event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.',
				'* Chrome/* Mobile*',
				{'fileName': 'https://maps.googleapis.com/maps/api/js?*'},
			];

			yield [
				'UnloadHandler[deprecation]',
				'Unload event listeners are deprecated and will be removed.',
				'* Chrome/*',
			];

			yield [
				'XMLHttpRequestSynchronousInNonWorkerOutsideBeforeUnload[deprecation]',
				"Synchronous `XMLHttpRequest` on the main thread is deprecated because of its detrimental effects to the end user's experience. For more help, check https://xhr.spec.whatwg.org/.",
				'* Chrome/*',
				'less' in window, // $build->isProductionMode()
				() => {
					if (less.temporaryAllowSyncRequestOnce)
					{
						delete less.temporaryAllowSyncRequestOnce;
						return true;
					}
				},
			];

			yield [
				'Error',
				'Mismatched anonymous define() module: [object Object] *',
				'* BW/*; bit.ly/*; *',
			];

			yield [
				'ResizeObserver loop limit exceeded',
				'null',
				'* Chrome/*',
				() => (!this.resizeObserverLoopLimitExceededIgnoredOnce && (this.resizeObserverLoopLimitExceededIgnoredOnce = true)),
			];
		}

		isIgnored(errorReport)
		{
			if (this.#rules === undefined)
			{
				this.#rules = Array.from(this.getHardCodedRules());
			}
			for (const rule of this.#rules)
			{
				if (rule.isRuleMatch === undefined)
				{
					rule.isRuleMatch = this.#prepareRule(rule);
				}
				if (rule.isRuleMatch(errorReport))
				{
					return true;
				}
			}
			return false;
		}

		#prepareRule(ruleDefinition)
		{
			const later = [];
			for (const [propertyOrAlias, matcherDefinition] of Object.entries(ruleDefinition))
			{
				if (matcherDefinition === false)
				{
					return () => false;
				}
				if (matcherDefinition === null || matcherDefinition === undefined || matcherDefinition === true)
				{
					continue;
				}
				if (_.isArray(ruleDefinition) && jQuery.isPlainObject(matcherDefinition))
				{
					for (const [property, def] of Object.entries(matcherDefinition))
					{
						later.push([property, def]);
					}
					continue;
				}
				const property = _.has(IgnoreErrors.#aliases, propertyOrAlias) ? IgnoreErrors.#aliases[propertyOrAlias] : propertyOrAlias;
				if (this.#predefined === undefined)
				{
					this.#predefined = {
						userAgent: window.navigator.userAgent,
					};
				}
				if (property in this.#predefined)
				{
					if (!IgnoreErrors.#createMatcher(matcherDefinition)(this.#predefined[property]))
					{
						return () => false;
					}
					continue;
				}
				later.push([property, matcherDefinition]);
			}
			if (!later.length)
			{
				return () => false;
			}

			const matchers = [];
			for (const [property, matcherDefinition] of later)
			{
				matchers.push([property, IgnoreErrors.#createMatcher(matcherDefinition)]);
			}
			return (errorReport) => {
				for (const [property, isMatch] of matchers)
				{
					if (!isMatch(IgnoreErrors.#getPropertyValue(errorReport, property)))
					{
						return false;
					}
				}
				return true;
			};
		}

		static #getPropertyValue(errorReport, property)
		{
			return errorReport.log[property] || errorReport.log.error[property] || errorReport.log.data[property] || '';
		}

		static #createMatcher(def)
		{
			if (_.isString(def))
			{
				def = IgnoreErrors.#normalizeString(def);
				if (def.indexOf('*') !== -1)
				{
					const defRe = Sim.escapeRegex(def).replace(new RegExp(Sim.escapeRegex(Sim.escapeRegex('*')), 'gu'), '.*');
					const re = new RegExp(`^${defRe}$`, 'us');
					return (value) => re.test(IgnoreErrors.#normalizeString(value));
				}
				return (value) => IgnoreErrors.#normalizeString(value) === def;
			}
			else if (_.isArray(def))
			{
				const matchers = def.map((d) => IgnoreErrors.#createMatcher(d));
				return (value) => _.some(matchers, (fn) => fn(value));
			}
			else if (typeof def === 'function')
			{
				return def;
			}
			throw new Error(JSON.stringify(def));
		}

		static #normalizeString(value)
		{
			return String(value).replace(/\s+/gu, ' ').trim();
		}

	}

	(window.Sim || (window.Sim = {})).getIgnoreErrors = function () {
		delete window.Sim.getIgnoreErrors;
		return IgnoreErrors;
	};
})();
