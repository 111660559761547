define('app/model/Services/Stuff/UnicodeToAscii/UnicodeToAsciiTable.jsonp',[],function () {
return {
"0":"0",
"1":"1",
"2":"2",
"3":"3",
"4":"4",
"5":"5",
"6":"6",
"7":"7",
"8":"8",
"9":"9",
"A":"a",
"B":"b",
"C":"c",
"D":"d",
"E":"e",
"F":"f",
"G":"g",
"H":"h",
"I":"i",
"J":"j",
"K":"k",
"L":"l",
"M":"m",
"N":"n",
"O":"o",
"P":"p",
"Q":"q",
"R":"r",
"S":"s",
"T":"t",
"U":"u",
"V":"v",
"W":"w",
"X":"x",
"Y":"y",
"Z":"z",
"a":"a",
"b":"b",
"c":"c",
"d":"d",
"e":"e",
"f":"f",
"g":"g",
"h":"h",
"i":"i",
"j":"j",
"k":"k",
"l":"l",
"m":"m",
"n":"n",
"o":"o",
"p":"p",
"q":"q",
"r":"r",
"s":"s",
"t":"t",
"u":"u",
"v":"v",
"w":"w",
"x":"x",
"y":"y",
"z":"z",
"\u0080":"eur",
"\u0083":"f",
"\u0089":"0",
"\u008a":"s",
"\u008c":"oe",
"\u008e":"z",
"\u0099":"tm",
"\u009a":"s",
"\u009c":"oe",
"\u009e":"z",
"\u009f":"y",
"\u00a2":"c",
"\u00a3":"lb",
"\u00a5":"yen",
"\u00a7":"ss",
"\u00a9":"c",
"\u00aa":"a",
"\u00ae":"r",
"\u00b0":"0",
"\u00b2":"2",
"\u00b3":"3",
"\u00b5":"u",
"\u00b6":"p",
"\u00b9":"1",
"\u00ba":"o",
"\u00bc":"14",
"\u00bd":"12",
"\u00be":"34",
"\u00c0":"a",
"\u00c1":"a",
"\u00c2":"a",
"\u00c3":"a",
"\u00c4":"a",
"\u00c5":"a",
"\u00c6":"ae",
"\u00c7":"c",
"\u00c8":"e",
"\u00c9":"e",
"\u00ca":"e",
"\u00cb":"e",
"\u00cc":"i",
"\u00cd":"i",
"\u00ce":"i",
"\u00cf":"i",
"\u00d0":"d",
"\u00d1":"n",
"\u00d2":"o",
"\u00d3":"o",
"\u00d4":"o",
"\u00d5":"o",
"\u00d6":"o",
"\u00d7":"x",
"\u00d8":"o",
"\u00d9":"u",
"\u00da":"u",
"\u00db":"u",
"\u00dc":"u",
"\u00dd":"y",
"\u00de":"th",
"\u00df":"ss",
"\u00e0":"a",
"\u00e1":"a",
"\u00e2":"a",
"\u00e3":"a",
"\u00e4":"a",
"\u00e5":"a",
"\u00e6":"ae",
"\u00e7":"c",
"\u00e8":"e",
"\u00e9":"e",
"\u00ea":"e",
"\u00eb":"e",
"\u00ec":"i",
"\u00ed":"i",
"\u00ee":"i",
"\u00ef":"i",
"\u00f0":"d",
"\u00f1":"n",
"\u00f2":"o",
"\u00f3":"o",
"\u00f4":"o",
"\u00f5":"o",
"\u00f6":"o",
"\u00f8":"o",
"\u00f9":"u",
"\u00fa":"u",
"\u00fb":"u",
"\u00fc":"u",
"\u00fd":"y",
"\u00fe":"th",
"\u00ff":"y",
"\u0100":"a",
"\u0101":"a",
"\u0102":"a",
"\u0103":"a",
"\u0104":"a",
"\u0105":"a",
"\u0106":"c",
"\u0107":"c",
"\u0108":"c",
"\u0109":"c",
"\u010a":"c",
"\u010b":"c",
"\u010c":"c",
"\u010d":"c",
"\u010e":"d",
"\u010f":"d",
"\u0110":"d",
"\u0111":"d",
"\u0112":"e",
"\u0113":"e",
"\u0114":"e",
"\u0115":"e",
"\u0116":"e",
"\u0117":"e",
"\u0118":"e",
"\u0119":"e",
"\u011a":"e",
"\u011b":"e",
"\u011c":"g",
"\u011d":"g",
"\u011e":"g",
"\u011f":"g",
"\u0120":"g",
"\u0121":"g",
"\u0122":"g",
"\u0123":"g",
"\u0124":"h",
"\u0125":"h",
"\u0126":"h",
"\u0127":"h",
"\u0128":"i",
"\u0129":"i",
"\u012a":"i",
"\u012b":"i",
"\u012c":"i",
"\u012d":"i",
"\u012e":"i",
"\u012f":"i",
"\u0130":"i",
"\u0131":"i",
"\u0132":"ij",
"\u0133":"ij",
"\u0134":"j",
"\u0135":"j",
"\u0136":"k",
"\u0137":"k",
"\u0138":"k",
"\u0139":"l",
"\u013a":"l",
"\u013b":"l",
"\u013c":"l",
"\u013d":"l",
"\u013e":"l",
"\u013f":"l",
"\u0140":"l",
"\u0141":"l",
"\u0142":"l",
"\u0143":"n",
"\u0144":"n",
"\u0145":"n",
"\u0146":"n",
"\u0147":"n",
"\u0148":"n",
"\u0149":"n",
"\u014a":"ng",
"\u014b":"ng",
"\u014c":"o",
"\u014d":"o",
"\u014e":"o",
"\u014f":"o",
"\u0150":"o",
"\u0151":"o",
"\u0152":"oe",
"\u0153":"oe",
"\u0154":"r",
"\u0155":"r",
"\u0156":"r",
"\u0157":"r",
"\u0158":"r",
"\u0159":"r",
"\u015a":"s",
"\u015b":"s",
"\u015c":"s",
"\u015d":"s",
"\u015e":"s",
"\u015f":"s",
"\u0160":"s",
"\u0161":"s",
"\u0162":"t",
"\u0163":"t",
"\u0164":"t",
"\u0165":"t",
"\u0166":"t",
"\u0167":"t",
"\u0168":"u",
"\u0169":"u",
"\u016a":"u",
"\u016b":"u",
"\u016c":"u",
"\u016d":"u",
"\u016e":"u",
"\u016f":"u",
"\u0170":"u",
"\u0171":"u",
"\u0172":"u",
"\u0173":"u",
"\u0174":"w",
"\u0175":"w",
"\u0176":"y",
"\u0177":"y",
"\u0178":"y",
"\u0179":"z",
"\u017a":"z",
"\u017b":"z",
"\u017c":"z",
"\u017d":"z",
"\u017e":"z",
"\u017f":"s",
"\u0180":"b",
"\u0181":"b",
"\u0182":"b",
"\u0183":"b",
"\u0184":"6",
"\u0185":"6",
"\u0186":"o",
"\u0187":"c",
"\u0188":"c",
"\u0189":"d",
"\u018a":"d",
"\u018b":"d",
"\u018c":"d",
"\u018d":"d",
"\u018e":"3",
"\u0190":"e",
"\u0191":"f",
"\u0192":"f",
"\u0193":"g",
"\u0194":"g",
"\u0195":"hv",
"\u0196":"i",
"\u0197":"i",
"\u0198":"k",
"\u0199":"k",
"\u019a":"l",
"\u019b":"l",
"\u019c":"w",
"\u019d":"n",
"\u019e":"n",
"\u019f":"o",
"\u01a0":"o",
"\u01a1":"o",
"\u01a2":"oi",
"\u01a3":"oi",
"\u01a4":"p",
"\u01a5":"p",
"\u01a6":"yr",
"\u01a7":"2",
"\u01a8":"2",
"\u01a9":"sh",
"\u01aa":"sh",
"\u01ab":"t",
"\u01ac":"t",
"\u01ad":"t",
"\u01ae":"t",
"\u01af":"u",
"\u01b0":"u",
"\u01b1":"y",
"\u01b2":"v",
"\u01b3":"y",
"\u01b4":"y",
"\u01b5":"z",
"\u01b6":"z",
"\u01b7":"zh",
"\u01b8":"zh",
"\u01b9":"zh",
"\u01ba":"zh",
"\u01bb":"2",
"\u01bc":"5",
"\u01bd":"5",
"\u01be":"ts",
"\u01bf":"w",
"\u01c4":"dz",
"\u01c5":"dz",
"\u01c6":"dz",
"\u01c7":"lj",
"\u01c8":"lj",
"\u01c9":"lj",
"\u01ca":"nj",
"\u01cb":"nj",
"\u01cc":"nj",
"\u01cd":"a",
"\u01ce":"a",
"\u01cf":"i",
"\u01d0":"i",
"\u01d1":"o",
"\u01d2":"o",
"\u01d3":"u",
"\u01d4":"u",
"\u01d5":"u",
"\u01d6":"u",
"\u01d7":"u",
"\u01d8":"u",
"\u01d9":"u",
"\u01da":"u",
"\u01db":"u",
"\u01dc":"u",
"\u01de":"a",
"\u01df":"a",
"\u01e0":"a",
"\u01e1":"a",
"\u01e2":"ae",
"\u01e3":"ae",
"\u01e4":"g",
"\u01e5":"g",
"\u01e6":"g",
"\u01e7":"g",
"\u01e8":"k",
"\u01e9":"k",
"\u01ea":"o",
"\u01eb":"o",
"\u01ec":"o",
"\u01ed":"o",
"\u01ee":"zh",
"\u01ef":"zh",
"\u01f0":"j",
"\u01f1":"dz",
"\u01f2":"dz",
"\u01f3":"dz",
"\u01f4":"g",
"\u01f5":"g",
"\u01f6":"hv",
"\u01f7":"w",
"\u01f8":"n",
"\u01f9":"n",
"\u01fa":"a",
"\u01fb":"a",
"\u01fc":"ae",
"\u01fd":"ae",
"\u01fe":"o",
"\u01ff":"o",
"\u0200":"a",
"\u0201":"a",
"\u0202":"a",
"\u0203":"a",
"\u0204":"e",
"\u0205":"e",
"\u0206":"e",
"\u0207":"e",
"\u0208":"i",
"\u0209":"i",
"\u020a":"i",
"\u020b":"i",
"\u020c":"o",
"\u020d":"o",
"\u020e":"o",
"\u020f":"o",
"\u0210":"r",
"\u0211":"r",
"\u0212":"r",
"\u0213":"r",
"\u0214":"u",
"\u0215":"u",
"\u0216":"u",
"\u0217":"u",
"\u0218":"s",
"\u0219":"s",
"\u021a":"t",
"\u021b":"t",
"\u021c":"y",
"\u021d":"y",
"\u021e":"h",
"\u021f":"h",
"\u0220":"n",
"\u0221":"d",
"\u0222":"ou",
"\u0223":"ou",
"\u0224":"z",
"\u0225":"z",
"\u0226":"a",
"\u0227":"a",
"\u0228":"e",
"\u0229":"e",
"\u022a":"o",
"\u022b":"o",
"\u022c":"o",
"\u022d":"o",
"\u022e":"o",
"\u022f":"o",
"\u0230":"o",
"\u0231":"o",
"\u0232":"y",
"\u0233":"y",
"\u0234":"l",
"\u0235":"n",
"\u0236":"t",
"\u0237":"j",
"\u0238":"db",
"\u0239":"qp",
"\u023a":"a",
"\u023b":"c",
"\u023c":"c",
"\u023d":"l",
"\u023e":"t",
"\u023f":"s",
"\u0240":"z",
"\u0243":"b",
"\u0244":"u",
"\u0246":"e",
"\u0247":"e",
"\u0248":"j",
"\u0249":"j",
"\u024a":"q",
"\u024b":"q",
"\u024c":"r",
"\u024d":"r",
"\u024e":"y",
"\u024f":"y",
"\u0250":"a",
"\u0251":"a",
"\u0252":"a",
"\u0253":"b",
"\u0254":"o",
"\u0255":"c",
"\u0256":"d",
"\u0257":"d",
"\u0258":"e",
"\u025b":"e",
"\u025c":"e",
"\u025d":"e",
"\u025e":"e",
"\u025f":"j",
"\u0260":"g",
"\u0261":"g",
"\u0262":"g",
"\u0263":"g",
"\u0264":"u",
"\u0265":"y",
"\u0266":"h",
"\u0267":"h",
"\u0268":"i",
"\u0269":"i",
"\u026a":"i",
"\u026b":"l",
"\u026c":"l",
"\u026d":"l",
"\u026e":"lz",
"\u026f":"w",
"\u0270":"w",
"\u0271":"m",
"\u0272":"n",
"\u0273":"n",
"\u0274":"n",
"\u0275":"o",
"\u0276":"oe",
"\u0277":"o",
"\u0278":"f",
"\u0279":"r",
"\u027a":"r",
"\u027b":"r",
"\u027c":"r",
"\u027d":"r",
"\u027e":"r",
"\u027f":"r",
"\u0280":"r",
"\u0281":"r",
"\u0282":"s",
"\u0283":"s",
"\u0284":"j",
"\u0285":"s",
"\u0286":"s",
"\u0287":"t",
"\u0288":"t",
"\u0289":"u",
"\u028a":"u",
"\u028b":"v",
"\u028d":"w",
"\u028e":"y",
"\u028f":"y",
"\u0290":"z",
"\u0291":"z",
"\u0292":"z",
"\u0293":"z",
"\u0297":"c",
"\u0299":"b",
"\u029a":"e",
"\u029b":"g",
"\u029c":"h",
"\u029d":"j",
"\u029e":"k",
"\u029f":"l",
"\u02a0":"q",
"\u02a3":"dz",
"\u02a4":"dz",
"\u02a5":"dz",
"\u02a6":"ts",
"\u02a7":"ts",
"\u02a8":"tc",
"\u02a9":"fn",
"\u02aa":"ls",
"\u02ab":"lz",
"\u02ac":"ww",
"\u02ae":"h",
"\u02af":"h",
"\u02b0":"h",
"\u02b1":"h",
"\u02b2":"j",
"\u02b3":"r",
"\u02b4":"r",
"\u02b5":"r",
"\u02b6":"r",
"\u02b7":"w",
"\u02b8":"y",
"\u02c5":"v",
"\u02c7":"v",
"\u02d5":"v",
"\u02d8":"v",
"\u02de":"r",
"\u02df":"x",
"\u02e0":"g",
"\u02e1":"l",
"\u02e2":"s",
"\u02e3":"x",
"\u02e5":"5",
"\u02e6":"4",
"\u02e7":"3",
"\u02e8":"2",
"\u02e9":"1",
"\u02ec":"v",
"\u02ef":"v",
"\u02f3":"o",
"\u0363":"a",
"\u0364":"e",
"\u0365":"i",
"\u0366":"o",
"\u0367":"u",
"\u0368":"c",
"\u0369":"d",
"\u036a":"h",
"\u036b":"m",
"\u036c":"r",
"\u036d":"t",
"\u036e":"v",
"\u036f":"x",
"\u0386":"a",
"\u0388":"e",
"\u0389":"e",
"\u038a":"i",
"\u038c":"o",
"\u038e":"u",
"\u038f":"o",
"\u0390":"i",
"\u0391":"a",
"\u0392":"b",
"\u0393":"g",
"\u0394":"d",
"\u0395":"e",
"\u0396":"z",
"\u0397":"e",
"\u0398":"th",
"\u0399":"i",
"\u039a":"k",
"\u039b":"l",
"\u039c":"m",
"\u039d":"n",
"\u039e":"ks",
"\u039f":"o",
"\u03a0":"p",
"\u03a1":"r",
"\u03a3":"s",
"\u03a4":"t",
"\u03a5":"u",
"\u03a6":"ph",
"\u03a7":"kh",
"\u03a8":"ps",
"\u03a9":"o",
"\u03aa":"i",
"\u03ab":"u",
"\u03ac":"a",
"\u03ad":"e",
"\u03ae":"e",
"\u03af":"i",
"\u03b0":"u",
"\u03b1":"a",
"\u03b2":"b",
"\u03b3":"g",
"\u03b4":"d",
"\u03b5":"e",
"\u03b6":"z",
"\u03b7":"e",
"\u03b8":"th",
"\u03b9":"i",
"\u03ba":"k",
"\u03bb":"l",
"\u03bc":"m",
"\u03bd":"n",
"\u03be":"x",
"\u03bf":"o",
"\u03c0":"p",
"\u03c1":"r",
"\u03c2":"s",
"\u03c3":"s",
"\u03c4":"t",
"\u03c5":"u",
"\u03c6":"ph",
"\u03c7":"kh",
"\u03c8":"ps",
"\u03c9":"o",
"\u03ca":"i",
"\u03cb":"u",
"\u03cc":"o",
"\u03cd":"u",
"\u03ce":"o",
"\u03d0":"b",
"\u03d1":"th",
"\u03d2":"u",
"\u03d3":"u",
"\u03d4":"u",
"\u03d5":"ph",
"\u03d6":"p",
"\u03da":"st",
"\u03db":"st",
"\u03dc":"w",
"\u03dd":"w",
"\u03de":"q",
"\u03df":"q",
"\u03e0":"sp",
"\u03e1":"sp",
"\u03e2":"sh",
"\u03e3":"sh",
"\u03e4":"f",
"\u03e5":"f",
"\u03e6":"kh",
"\u03e7":"kh",
"\u03e8":"h",
"\u03e9":"h",
"\u03ea":"g",
"\u03eb":"g",
"\u03ec":"ch",
"\u03ed":"ch",
"\u03ee":"ti",
"\u03ef":"ti",
"\u03f0":"k",
"\u03f1":"r",
"\u03f2":"c",
"\u03f3":"j",
"\u03f4":"th",
"\u03f5":"e",
"\u03f6":"e",
"\u03f7":"sh",
"\u03f8":"sh",
"\u03f9":"s",
"\u03fc":"r",
"\u03fd":"s",
"\u03fe":"s",
"\u03ff":"s",
"\u0400":"ie",
"\u0401":"io",
"\u0402":"dj",
"\u0403":"gj",
"\u0404":"e",
"\u0405":"dz",
"\u0406":"i",
"\u0407":"yi",
"\u0408":"j",
"\u0409":"lj",
"\u040a":"nj",
"\u040b":"tsh",
"\u040c":"kj",
"\u040d":"i",
"\u040e":"u",
"\u040f":"dzh",
"\u0410":"a",
"\u0411":"b",
"\u0412":"v",
"\u0413":"g",
"\u0414":"d",
"\u0415":"e",
"\u0416":"zh",
"\u0417":"z",
"\u0418":"i",
"\u0419":"i",
"\u041a":"k",
"\u041b":"l",
"\u041c":"m",
"\u041d":"n",
"\u041e":"o",
"\u041f":"p",
"\u0420":"r",
"\u0421":"s",
"\u0422":"t",
"\u0423":"u",
"\u0424":"f",
"\u0425":"kh",
"\u0426":"ts",
"\u0427":"ch",
"\u0428":"sh",
"\u0429":"shch",
"\u042b":"y",
"\u042d":"e",
"\u042e":"iu",
"\u042f":"ia",
"\u0430":"a",
"\u0431":"b",
"\u0432":"v",
"\u0433":"g",
"\u0434":"d",
"\u0435":"e",
"\u0436":"zh",
"\u0437":"z",
"\u0438":"i",
"\u0439":"i",
"\u043a":"k",
"\u043b":"l",
"\u043c":"m",
"\u043d":"n",
"\u043e":"o",
"\u043f":"p",
"\u0440":"r",
"\u0441":"s",
"\u0442":"t",
"\u0443":"u",
"\u0444":"f",
"\u0445":"kh",
"\u0446":"ts",
"\u0447":"ch",
"\u0448":"sh",
"\u0449":"shch",
"\u044b":"y",
"\u044d":"e",
"\u044e":"iu",
"\u044f":"ia",
"\u0450":"ie",
"\u0451":"io",
"\u0452":"dj",
"\u0453":"gj",
"\u0454":"ie",
"\u0455":"dz",
"\u0456":"i",
"\u0457":"yi",
"\u0458":"j",
"\u0459":"lj",
"\u045a":"nj",
"\u045b":"tsh",
"\u045c":"kj",
"\u045d":"i",
"\u045e":"u",
"\u045f":"dzh",
"\u0460":"o",
"\u0461":"o",
"\u0462":"e",
"\u0463":"e",
"\u0464":"ie",
"\u0465":"ie",
"\u0466":"e",
"\u0467":"e",
"\u0468":"ie",
"\u0469":"ie",
"\u046a":"o",
"\u046b":"o",
"\u046c":"io",
"\u046d":"io",
"\u046e":"ks",
"\u046f":"ks",
"\u0470":"ps",
"\u0471":"ps",
"\u0472":"f",
"\u0473":"f",
"\u0474":"y",
"\u0475":"y",
"\u0476":"y",
"\u0477":"y",
"\u0478":"u",
"\u0479":"u",
"\u047a":"o",
"\u047b":"o",
"\u047c":"o",
"\u047d":"o",
"\u047e":"ot",
"\u047f":"ot",
"\u0480":"q",
"\u0481":"q",
"\u0482":"1000",
"\u0488":"100000",
"\u0489":"1000000",
"\u048e":"r",
"\u048f":"r",
"\u0490":"g",
"\u0491":"g",
"\u0492":"g",
"\u0493":"g",
"\u0494":"g",
"\u0495":"g",
"\u0496":"zh",
"\u0497":"zh",
"\u0498":"z",
"\u0499":"z",
"\u049a":"k",
"\u049b":"k",
"\u049c":"k",
"\u049d":"k",
"\u049e":"k",
"\u049f":"k",
"\u04a0":"k",
"\u04a1":"k",
"\u04a2":"n",
"\u04a3":"n",
"\u04a4":"ng",
"\u04a5":"ng",
"\u04a6":"p",
"\u04a7":"p",
"\u04a8":"kh",
"\u04a9":"kh",
"\u04aa":"s",
"\u04ab":"s",
"\u04ac":"t",
"\u04ad":"t",
"\u04ae":"u",
"\u04af":"u",
"\u04b0":"u",
"\u04b1":"u",
"\u04b2":"kh",
"\u04b3":"kh",
"\u04b4":"tts",
"\u04b5":"tts",
"\u04b6":"ch",
"\u04b7":"ch",
"\u04b8":"ch",
"\u04b9":"ch",
"\u04ba":"h",
"\u04bb":"h",
"\u04bc":"ch",
"\u04bd":"ch",
"\u04be":"ch",
"\u04bf":"ch",
"\u04c1":"zh",
"\u04c2":"zh",
"\u04c3":"k",
"\u04c4":"k",
"\u04c7":"n",
"\u04c8":"n",
"\u04cb":"ch",
"\u04cc":"ch",
"\u04d0":"a",
"\u04d1":"a",
"\u04d2":"a",
"\u04d3":"a",
"\u04d4":"ae",
"\u04d5":"ae",
"\u04d6":"ie",
"\u04d7":"ie",
"\u04dc":"zh",
"\u04dd":"zh",
"\u04de":"z",
"\u04df":"z",
"\u04e0":"dz",
"\u04e1":"dz",
"\u04e2":"i",
"\u04e3":"i",
"\u04e4":"i",
"\u04e5":"i",
"\u04e6":"o",
"\u04e7":"o",
"\u04e8":"o",
"\u04e9":"o",
"\u04ea":"o",
"\u04eb":"o",
"\u04ec":"e",
"\u04ed":"e",
"\u04ee":"u",
"\u04ef":"u",
"\u04f0":"u",
"\u04f1":"u",
"\u04f2":"u",
"\u04f3":"u",
"\u04f4":"ch",
"\u04f5":"ch",
"\u04f8":"y",
"\u04f9":"y",
"\u0531":"a",
"\u0532":"b",
"\u0533":"g",
"\u0534":"d",
"\u0535":"e",
"\u0536":"z",
"\u0537":"e",
"\u0538":"e",
"\u0539":"t",
"\u053a":"zh",
"\u053b":"i",
"\u053c":"l",
"\u053d":"kh",
"\u053e":"ts",
"\u053f":"k",
"\u0540":"h",
"\u0541":"dz",
"\u0542":"gh",
"\u0543":"ch",
"\u0544":"m",
"\u0545":"y",
"\u0546":"n",
"\u0547":"sh",
"\u0548":"o",
"\u0549":"ch",
"\u054a":"p",
"\u054b":"j",
"\u054c":"rh",
"\u054d":"s",
"\u054e":"v",
"\u054f":"t",
"\u0550":"r",
"\u0551":"ts",
"\u0552":"w",
"\u0553":"p",
"\u0554":"k",
"\u0555":"o",
"\u0556":"f",
"\u0561":"a",
"\u0562":"b",
"\u0563":"g",
"\u0564":"d",
"\u0565":"e",
"\u0566":"z",
"\u0567":"e",
"\u0568":"e",
"\u0569":"t",
"\u056a":"zh",
"\u056b":"i",
"\u056c":"l",
"\u056d":"kh",
"\u056e":"ts",
"\u056f":"k",
"\u0570":"h",
"\u0571":"dz",
"\u0572":"gh",
"\u0573":"ch",
"\u0574":"m",
"\u0575":"y",
"\u0576":"n",
"\u0577":"sh",
"\u0578":"o",
"\u0579":"ch",
"\u057a":"p",
"\u057b":"j",
"\u057c":"rh",
"\u057d":"s",
"\u057e":"v",
"\u057f":"t",
"\u0580":"r",
"\u0581":"ts",
"\u0582":"w",
"\u0583":"p",
"\u0584":"k",
"\u0585":"o",
"\u0586":"f",
"\u0587":"ew",
"\u05b1":"e",
"\u05b2":"a",
"\u05b3":"o",
"\u05b4":"i",
"\u05b5":"e",
"\u05b6":"e",
"\u05b7":"a",
"\u05b8":"a",
"\u05b9":"o",
"\u05bb":"u",
"\u05d1":"b",
"\u05d2":"g",
"\u05d3":"d",
"\u05d4":"h",
"\u05d5":"v",
"\u05d6":"z",
"\u05d7":"kh",
"\u05d8":"t",
"\u05d9":"y",
"\u05da":"k",
"\u05db":"k",
"\u05dc":"l",
"\u05dd":"m",
"\u05de":"m",
"\u05df":"n",
"\u05e0":"n",
"\u05e1":"s",
"\u05e3":"p",
"\u05e4":"p",
"\u05e5":"ts",
"\u05e6":"ts",
"\u05e7":"q",
"\u05e8":"r",
"\u05e9":"sh",
"\u05ea":"t",
"\u05f0":"v",
"\u05f1":"oy",
"\u05f2":"i",
"\u0622":"a",
"\u0624":"w",
"\u0626":"y",
"\u0628":"b",
"\u062a":"t",
"\u062b":"th",
"\u062c":"j",
"\u062d":"h",
"\u062e":"kh",
"\u062f":"d",
"\u0630":"dh",
"\u0631":"r",
"\u0632":"z",
"\u0633":"s",
"\u0634":"sh",
"\u0635":"s",
"\u0636":"d",
"\u0637":"t",
"\u0638":"z",
"\u063a":"g",
"\u0641":"f",
"\u0642":"q",
"\u0643":"k",
"\u0644":"l",
"\u0645":"m",
"\u0646":"n",
"\u0647":"h",
"\u0648":"w",
"\u064a":"y",
"\u064b":"an",
"\u064c":"un",
"\u064d":"in",
"\u064e":"a",
"\u064f":"u",
"\u0650":"i",
"\u0651":"w",
"\u0660":"0",
"\u0661":"1",
"\u0662":"2",
"\u0663":"3",
"\u0664":"4",
"\u0665":"5",
"\u0666":"6",
"\u0667":"7",
"\u0668":"8",
"\u0669":"9",
"\u0676":"w",
"\u0677":"u",
"\u0678":"y",
"\u0679":"tt",
"\u067a":"tth",
"\u067b":"b",
"\u067c":"t",
"\u067d":"t",
"\u067e":"p",
"\u067f":"th",
"\u0680":"bh",
"\u0681":"h",
"\u0682":"h",
"\u0683":"ny",
"\u0684":"dy",
"\u0685":"h",
"\u0686":"ch",
"\u0687":"cch",
"\u0688":"dd",
"\u0689":"d",
"\u068a":"d",
"\u068b":"dt",
"\u068c":"dh",
"\u068d":"ddh",
"\u068e":"d",
"\u068f":"d",
"\u0690":"d",
"\u0691":"rr",
"\u0692":"r",
"\u0693":"r",
"\u0694":"r",
"\u0695":"r",
"\u0696":"r",
"\u0697":"r",
"\u0698":"j",
"\u0699":"r",
"\u069a":"s",
"\u069b":"s",
"\u069c":"s",
"\u069d":"s",
"\u069e":"s",
"\u069f":"t",
"\u06a0":"gh",
"\u06a1":"f",
"\u06a2":"f",
"\u06a3":"f",
"\u06a4":"v",
"\u06a5":"f",
"\u06a6":"ph",
"\u06a7":"q",
"\u06a8":"q",
"\u06a9":"kh",
"\u06aa":"k",
"\u06ab":"k",
"\u06ac":"k",
"\u06ad":"ng",
"\u06ae":"k",
"\u06af":"g",
"\u06b0":"g",
"\u06b1":"n",
"\u06b2":"g",
"\u06b3":"g",
"\u06b4":"g",
"\u06b5":"l",
"\u06b6":"l",
"\u06b7":"l",
"\u06b8":"l",
"\u06b9":"n",
"\u06ba":"n",
"\u06bb":"n",
"\u06bc":"n",
"\u06bd":"n",
"\u06be":"h",
"\u06bf":"ch",
"\u06c0":"hy",
"\u06c1":"h",
"\u06c2":"h",
"\u06c4":"w",
"\u06c5":"oe",
"\u06c6":"oe",
"\u06c7":"u",
"\u06c8":"yu",
"\u06c9":"yu",
"\u06ca":"w",
"\u06cb":"v",
"\u06cc":"y",
"\u06cd":"y",
"\u06ce":"y",
"\u06cf":"w",
"\u06d2":"y",
"\u06d3":"y",
"\u06d5":"ae",
"\u06f0":"0",
"\u06f1":"1",
"\u06f2":"2",
"\u06f3":"3",
"\u06f4":"4",
"\u06f5":"5",
"\u06f6":"6",
"\u06f7":"7",
"\u06f8":"8",
"\u06f9":"9",
"\u06fa":"sh",
"\u06fb":"d",
"\u06fc":"gh",
"\u06fe":"m",
"\u06ff":"h",
"\u0712":"b",
"\u0713":"g",
"\u0714":"g",
"\u0715":"d",
"\u0716":"d",
"\u0717":"h",
"\u0718":"w",
"\u0719":"z",
"\u071a":"h",
"\u071b":"t",
"\u071c":"t",
"\u071d":"y",
"\u071e":"yh",
"\u071f":"k",
"\u0720":"l",
"\u0721":"m",
"\u0722":"n",
"\u0723":"s",
"\u0724":"s",
"\u0726":"p",
"\u0727":"p",
"\u0728":"s",
"\u0729":"q",
"\u072a":"r",
"\u072b":"sh",
"\u072c":"t",
"\u0730":"a",
"\u0731":"a",
"\u0732":"a",
"\u0733":"a",
"\u0734":"a",
"\u0735":"a",
"\u0736":"e",
"\u0737":"e",
"\u0738":"e",
"\u0739":"e",
"\u073a":"i",
"\u073b":"i",
"\u073c":"u",
"\u073d":"u",
"\u073e":"u",
"\u073f":"o",
"\u0745":"x",
"\u0746":"q",
"\u0780":"h",
"\u0781":"sh",
"\u0782":"n",
"\u0783":"r",
"\u0784":"b",
"\u0785":"l",
"\u0786":"k",
"\u0788":"v",
"\u0789":"m",
"\u078a":"f",
"\u078b":"dh",
"\u078c":"th",
"\u078d":"l",
"\u078e":"g",
"\u078f":"ny",
"\u0790":"s",
"\u0791":"d",
"\u0792":"z",
"\u0793":"t",
"\u0794":"y",
"\u0795":"p",
"\u0796":"j",
"\u0797":"ch",
"\u0798":"tt",
"\u0799":"hh",
"\u079a":"kh",
"\u079b":"th",
"\u079c":"z",
"\u079d":"sh",
"\u079e":"s",
"\u079f":"d",
"\u07a0":"t",
"\u07a1":"z",
"\u07a3":"gh",
"\u07a4":"q",
"\u07a5":"w",
"\u07a6":"a",
"\u07a7":"aa",
"\u07a8":"i",
"\u07a9":"ee",
"\u07aa":"u",
"\u07ab":"oo",
"\u07ac":"e",
"\u07ad":"ey",
"\u07ae":"o",
"\u07af":"oa",
"\u0901":"n",
"\u0902":"n",
"\u0903":"h",
"\u0905":"a",
"\u0906":"aa",
"\u0907":"i",
"\u0908":"ii",
"\u0909":"u",
"\u090a":"uu",
"\u090b":"r",
"\u090c":"l",
"\u090d":"en",
"\u090e":"e",
"\u090f":"e",
"\u0910":"ai",
"\u0911":"on",
"\u0912":"o",
"\u0913":"o",
"\u0914":"au",
"\u0915":"k",
"\u0916":"kh",
"\u0917":"g",
"\u0918":"gh",
"\u0919":"ng",
"\u091a":"c",
"\u091b":"ch",
"\u091c":"j",
"\u091d":"jh",
"\u091e":"ny",
"\u091f":"tt",
"\u0920":"tth",
"\u0921":"dd",
"\u0922":"ddh",
"\u0923":"nn",
"\u0924":"t",
"\u0925":"th",
"\u0926":"d",
"\u0927":"dh",
"\u0928":"n",
"\u0929":"nnn",
"\u092a":"p",
"\u092b":"ph",
"\u092c":"b",
"\u092d":"bh",
"\u092e":"m",
"\u092f":"y",
"\u0930":"r",
"\u0931":"rr",
"\u0932":"l",
"\u0933":"l",
"\u0934":"lll",
"\u0935":"v",
"\u0936":"sh",
"\u0937":"ss",
"\u0938":"s",
"\u0939":"h",
"\u093e":"aa",
"\u093f":"i",
"\u0940":"ii",
"\u0941":"u",
"\u0942":"uu",
"\u0943":"r",
"\u0944":"rr",
"\u0945":"en",
"\u0946":"e",
"\u0947":"e",
"\u0948":"ai",
"\u0949":"on",
"\u094a":"o",
"\u094b":"o",
"\u094c":"au",
"\u0950":"aum",
"\u0958":"q",
"\u0959":"khh",
"\u095a":"ghh",
"\u095b":"z",
"\u095c":"dddh",
"\u095d":"rh",
"\u095e":"f",
"\u095f":"yy",
"\u0960":"rr",
"\u0961":"ll",
"\u0962":"l",
"\u0963":"ll",
"\u0966":"0",
"\u0967":"1",
"\u0968":"2",
"\u0969":"3",
"\u096a":"4",
"\u096b":"5",
"\u096c":"6",
"\u096d":"7",
"\u096e":"8",
"\u096f":"9",
"\u0981":"n",
"\u0982":"n",
"\u0983":"h",
"\u0985":"a",
"\u0986":"aa",
"\u0987":"i",
"\u0988":"ii",
"\u0989":"u",
"\u098a":"uu",
"\u098b":"r",
"\u098c":"rr",
"\u098f":"e",
"\u0990":"ai",
"\u0993":"o",
"\u0994":"au",
"\u0995":"k",
"\u0996":"kh",
"\u0997":"g",
"\u0998":"gh",
"\u0999":"ng",
"\u099a":"c",
"\u099b":"ch",
"\u099c":"j",
"\u099d":"jh",
"\u099e":"ny",
"\u099f":"tt",
"\u09a0":"tth",
"\u09a1":"dd",
"\u09a2":"ddh",
"\u09a3":"nn",
"\u09a4":"t",
"\u09a5":"th",
"\u09a6":"d",
"\u09a7":"dh",
"\u09a8":"n",
"\u09aa":"p",
"\u09ab":"ph",
"\u09ac":"b",
"\u09ad":"bh",
"\u09ae":"m",
"\u09af":"y",
"\u09b0":"r",
"\u09b2":"l",
"\u09b6":"sh",
"\u09b7":"ss",
"\u09b8":"s",
"\u09b9":"h",
"\u09be":"aa",
"\u09bf":"i",
"\u09c0":"ii",
"\u09c1":"u",
"\u09c2":"uu",
"\u09c3":"r",
"\u09c4":"rr",
"\u09c7":"e",
"\u09c8":"ai",
"\u09cb":"o",
"\u09cc":"au",
"\u09dc":"rr",
"\u09dd":"rh",
"\u09df":"yy",
"\u09e0":"rr",
"\u09e1":"ll",
"\u09e2":"l",
"\u09e3":"ll",
"\u09e6":"0",
"\u09e7":"1",
"\u09e8":"2",
"\u09e9":"3",
"\u09ea":"4",
"\u09eb":"5",
"\u09ec":"6",
"\u09ed":"7",
"\u09ee":"8",
"\u09ef":"9",
"\u09f0":"r",
"\u09f1":"r",
"\u09f2":"rs",
"\u09f3":"rs",
"\u09f4":"1",
"\u09f5":"2",
"\u09f6":"3",
"\u09f7":"4",
"\u09f8":"11",
"\u09f9":"16",
"\u0a02":"n",
"\u0a05":"a",
"\u0a06":"aa",
"\u0a07":"i",
"\u0a08":"ii",
"\u0a09":"u",
"\u0a0a":"uu",
"\u0a0f":"ee",
"\u0a10":"ai",
"\u0a13":"oo",
"\u0a14":"au",
"\u0a15":"k",
"\u0a16":"kh",
"\u0a17":"g",
"\u0a18":"gh",
"\u0a19":"ng",
"\u0a1a":"c",
"\u0a1b":"ch",
"\u0a1c":"j",
"\u0a1d":"jh",
"\u0a1e":"ny",
"\u0a1f":"tt",
"\u0a20":"tth",
"\u0a21":"dd",
"\u0a22":"ddh",
"\u0a23":"nn",
"\u0a24":"t",
"\u0a25":"th",
"\u0a26":"d",
"\u0a27":"dh",
"\u0a28":"n",
"\u0a2a":"p",
"\u0a2b":"ph",
"\u0a2c":"b",
"\u0a2d":"bb",
"\u0a2e":"m",
"\u0a2f":"y",
"\u0a30":"r",
"\u0a32":"l",
"\u0a33":"ll",
"\u0a35":"v",
"\u0a36":"sh",
"\u0a38":"s",
"\u0a39":"h",
"\u0a3e":"aa",
"\u0a3f":"i",
"\u0a40":"ii",
"\u0a41":"u",
"\u0a42":"uu",
"\u0a47":"ee",
"\u0a48":"ai",
"\u0a4b":"oo",
"\u0a4c":"au",
"\u0a59":"khh",
"\u0a5a":"ghh",
"\u0a5b":"z",
"\u0a5c":"rr",
"\u0a5e":"f",
"\u0a66":"0",
"\u0a67":"1",
"\u0a68":"2",
"\u0a69":"3",
"\u0a6a":"4",
"\u0a6b":"5",
"\u0a6c":"6",
"\u0a6d":"7",
"\u0a6e":"8",
"\u0a6f":"9",
"\u0a70":"n",
"\u0a71":"h",
"\u0a74":"geo",
"\u0a81":"n",
"\u0a82":"n",
"\u0a83":"h",
"\u0a85":"a",
"\u0a86":"aa",
"\u0a87":"i",
"\u0a88":"ii",
"\u0a89":"u",
"\u0a8a":"uu",
"\u0a8b":"r",
"\u0a8d":"en",
"\u0a8f":"e",
"\u0a90":"ai",
"\u0a91":"on",
"\u0a93":"o",
"\u0a94":"au",
"\u0a95":"k",
"\u0a96":"kh",
"\u0a97":"g",
"\u0a98":"gh",
"\u0a99":"ng",
"\u0a9a":"c",
"\u0a9b":"ch",
"\u0a9c":"j",
"\u0a9d":"jh",
"\u0a9e":"ny",
"\u0a9f":"tt",
"\u0aa0":"tth",
"\u0aa1":"dd",
"\u0aa2":"ddh",
"\u0aa3":"nn",
"\u0aa4":"t",
"\u0aa5":"th",
"\u0aa6":"d",
"\u0aa7":"dh",
"\u0aa8":"n",
"\u0aaa":"p",
"\u0aab":"ph",
"\u0aac":"b",
"\u0aad":"bh",
"\u0aae":"m",
"\u0aaf":"ya",
"\u0ab0":"r",
"\u0ab2":"l",
"\u0ab3":"ll",
"\u0ab5":"v",
"\u0ab6":"sh",
"\u0ab7":"ss",
"\u0ab8":"s",
"\u0ab9":"h",
"\u0abe":"aa",
"\u0abf":"i",
"\u0ac0":"ii",
"\u0ac1":"u",
"\u0ac2":"uu",
"\u0ac3":"r",
"\u0ac4":"rr",
"\u0ac5":"en",
"\u0ac7":"e",
"\u0ac8":"ai",
"\u0ac9":"on",
"\u0acb":"o",
"\u0acc":"au",
"\u0ad0":"aum",
"\u0ae0":"rr",
"\u0ae6":"0",
"\u0ae7":"1",
"\u0ae8":"2",
"\u0ae9":"3",
"\u0aea":"4",
"\u0aeb":"5",
"\u0aec":"6",
"\u0aed":"7",
"\u0aee":"8",
"\u0aef":"9",
"\u0af1":"r",
"\u0af9":"zh",
"\u0b01":"n",
"\u0b02":"n",
"\u0b03":"h",
"\u0b05":"a",
"\u0b06":"aa",
"\u0b07":"i",
"\u0b08":"ii",
"\u0b09":"u",
"\u0b0a":"uu",
"\u0b0b":"r",
"\u0b0c":"l",
"\u0b0f":"e",
"\u0b10":"ai",
"\u0b13":"o",
"\u0b14":"au",
"\u0b15":"k",
"\u0b16":"kh",
"\u0b17":"g",
"\u0b18":"gh",
"\u0b19":"ng",
"\u0b1a":"c",
"\u0b1b":"ch",
"\u0b1c":"j",
"\u0b1d":"jh",
"\u0b1e":"ny",
"\u0b1f":"tt",
"\u0b20":"tth",
"\u0b21":"dd",
"\u0b22":"ddh",
"\u0b23":"nn",
"\u0b24":"t",
"\u0b25":"th",
"\u0b26":"d",
"\u0b27":"dh",
"\u0b28":"n",
"\u0b2a":"p",
"\u0b2b":"ph",
"\u0b2c":"b",
"\u0b2d":"bh",
"\u0b2e":"m",
"\u0b2f":"y",
"\u0b30":"r",
"\u0b32":"l",
"\u0b33":"ll",
"\u0b36":"sh",
"\u0b37":"ss",
"\u0b38":"s",
"\u0b39":"h",
"\u0b3e":"aa",
"\u0b3f":"i",
"\u0b40":"ii",
"\u0b41":"u",
"\u0b42":"uu",
"\u0b43":"r",
"\u0b47":"e",
"\u0b48":"ai",
"\u0b4b":"o",
"\u0b4c":"au",
"\u0b5c":"rr",
"\u0b5d":"rh",
"\u0b5f":"yy",
"\u0b60":"rr",
"\u0b61":"ll",
"\u0b66":"0",
"\u0b67":"1",
"\u0b68":"2",
"\u0b69":"3",
"\u0b6a":"4",
"\u0b6b":"5",
"\u0b6c":"6",
"\u0b6d":"7",
"\u0b6e":"8",
"\u0b6f":"9",
"\u0b82":"n",
"\u0b83":"h",
"\u0b85":"a",
"\u0b86":"aa",
"\u0b87":"i",
"\u0b88":"ii",
"\u0b89":"u",
"\u0b8a":"uu",
"\u0b8e":"e",
"\u0b8f":"ee",
"\u0b90":"ai",
"\u0b92":"o",
"\u0b93":"oo",
"\u0b94":"au",
"\u0b95":"k",
"\u0b99":"ng",
"\u0b9a":"c",
"\u0b9c":"j",
"\u0b9e":"ny",
"\u0b9f":"tt",
"\u0ba3":"nn",
"\u0ba4":"t",
"\u0ba8":"n",
"\u0ba9":"nnn",
"\u0baa":"p",
"\u0bae":"m",
"\u0baf":"y",
"\u0bb0":"r",
"\u0bb1":"rr",
"\u0bb2":"l",
"\u0bb3":"ll",
"\u0bb4":"lll",
"\u0bb5":"v",
"\u0bb7":"ss",
"\u0bb8":"s",
"\u0bb9":"h",
"\u0bbe":"aa",
"\u0bbf":"i",
"\u0bc0":"ii",
"\u0bc1":"u",
"\u0bc2":"uu",
"\u0bc6":"e",
"\u0bc7":"ee",
"\u0bc8":"ai",
"\u0bca":"o",
"\u0bcb":"oo",
"\u0bcc":"au",
"\u0be6":"0",
"\u0be7":"1",
"\u0be8":"2",
"\u0be9":"3",
"\u0bea":"4",
"\u0beb":"5",
"\u0bec":"6",
"\u0bed":"7",
"\u0bee":"8",
"\u0bef":"9",
"\u0bf0":"10",
"\u0bf1":"100",
"\u0bf2":"1000",
"\u0c01":"n",
"\u0c02":"n",
"\u0c03":"h",
"\u0c05":"a",
"\u0c06":"aa",
"\u0c07":"i",
"\u0c08":"ii",
"\u0c09":"u",
"\u0c0a":"uu",
"\u0c0b":"r",
"\u0c0c":"l",
"\u0c0e":"e",
"\u0c0f":"ee",
"\u0c10":"ai",
"\u0c12":"o",
"\u0c13":"oo",
"\u0c14":"au",
"\u0c15":"k",
"\u0c16":"kh",
"\u0c17":"g",
"\u0c18":"gh",
"\u0c19":"ng",
"\u0c1a":"c",
"\u0c1b":"ch",
"\u0c1c":"j",
"\u0c1d":"jh",
"\u0c1e":"ny",
"\u0c1f":"tt",
"\u0c20":"tth",
"\u0c21":"dd",
"\u0c22":"ddh",
"\u0c23":"nn",
"\u0c24":"t",
"\u0c25":"th",
"\u0c26":"d",
"\u0c27":"dh",
"\u0c28":"n",
"\u0c2a":"p",
"\u0c2b":"ph",
"\u0c2c":"b",
"\u0c2d":"bh",
"\u0c2e":"m",
"\u0c2f":"y",
"\u0c30":"r",
"\u0c31":"rr",
"\u0c32":"l",
"\u0c33":"ll",
"\u0c35":"v",
"\u0c36":"sh",
"\u0c37":"ss",
"\u0c38":"s",
"\u0c39":"h",
"\u0c3e":"aa",
"\u0c3f":"i",
"\u0c40":"ii",
"\u0c41":"u",
"\u0c42":"uu",
"\u0c43":"r",
"\u0c44":"rr",
"\u0c46":"e",
"\u0c47":"ee",
"\u0c48":"ai",
"\u0c4a":"o",
"\u0c4b":"oo",
"\u0c4c":"au",
"\u0c60":"rr",
"\u0c61":"ll",
"\u0c66":"0",
"\u0c67":"1",
"\u0c68":"2",
"\u0c69":"3",
"\u0c6a":"4",
"\u0c6b":"5",
"\u0c6c":"6",
"\u0c6d":"7",
"\u0c6e":"8",
"\u0c6f":"9",
"\u0c82":"n",
"\u0c83":"h",
"\u0c85":"a",
"\u0c86":"aa",
"\u0c87":"i",
"\u0c88":"ii",
"\u0c89":"u",
"\u0c8a":"uu",
"\u0c8b":"r",
"\u0c8c":"l",
"\u0c8e":"e",
"\u0c8f":"ee",
"\u0c90":"ai",
"\u0c92":"o",
"\u0c93":"oo",
"\u0c94":"au",
"\u0c95":"k",
"\u0c96":"kh",
"\u0c97":"g",
"\u0c98":"gh",
"\u0c99":"ng",
"\u0c9a":"c",
"\u0c9b":"ch",
"\u0c9c":"j",
"\u0c9d":"jh",
"\u0c9e":"ny",
"\u0c9f":"tt",
"\u0ca0":"tth",
"\u0ca1":"dd",
"\u0ca2":"ddh",
"\u0ca3":"nn",
"\u0ca4":"t",
"\u0ca5":"th",
"\u0ca6":"d",
"\u0ca7":"dh",
"\u0ca8":"n",
"\u0caa":"p",
"\u0cab":"ph",
"\u0cac":"b",
"\u0cad":"bh",
"\u0cae":"m",
"\u0caf":"y",
"\u0cb0":"r",
"\u0cb1":"rr",
"\u0cb2":"l",
"\u0cb3":"ll",
"\u0cb5":"v",
"\u0cb6":"sh",
"\u0cb7":"ss",
"\u0cb8":"s",
"\u0cb9":"h",
"\u0cbe":"aa",
"\u0cbf":"i",
"\u0cc0":"ii",
"\u0cc1":"u",
"\u0cc2":"uu",
"\u0cc3":"r",
"\u0cc4":"rr",
"\u0cc6":"e",
"\u0cc7":"ee",
"\u0cc8":"ai",
"\u0cca":"o",
"\u0ccb":"oo",
"\u0ccc":"au",
"\u0cde":"lll",
"\u0ce0":"rr",
"\u0ce1":"ll",
"\u0ce6":"0",
"\u0ce7":"1",
"\u0ce8":"2",
"\u0ce9":"3",
"\u0cea":"4",
"\u0ceb":"5",
"\u0cec":"6",
"\u0ced":"7",
"\u0cee":"8",
"\u0cef":"9",
"\u0d02":"n",
"\u0d03":"h",
"\u0d05":"a",
"\u0d06":"aa",
"\u0d07":"i",
"\u0d08":"ii",
"\u0d09":"u",
"\u0d0a":"uu",
"\u0d0b":"r",
"\u0d0c":"l",
"\u0d0e":"e",
"\u0d0f":"ee",
"\u0d10":"ai",
"\u0d12":"o",
"\u0d13":"oo",
"\u0d14":"au",
"\u0d15":"k",
"\u0d16":"kh",
"\u0d17":"g",
"\u0d18":"gh",
"\u0d19":"ng",
"\u0d1a":"c",
"\u0d1b":"ch",
"\u0d1c":"j",
"\u0d1d":"jh",
"\u0d1e":"ny",
"\u0d1f":"tt",
"\u0d20":"tth",
"\u0d21":"dd",
"\u0d22":"ddh",
"\u0d23":"nn",
"\u0d24":"t",
"\u0d25":"th",
"\u0d26":"d",
"\u0d27":"dh",
"\u0d28":"n",
"\u0d2a":"p",
"\u0d2b":"ph",
"\u0d2c":"b",
"\u0d2d":"bh",
"\u0d2e":"m",
"\u0d2f":"y",
"\u0d30":"r",
"\u0d31":"rr",
"\u0d32":"l",
"\u0d33":"ll",
"\u0d34":"lll",
"\u0d35":"v",
"\u0d36":"sh",
"\u0d37":"ss",
"\u0d38":"s",
"\u0d39":"h",
"\u0d3e":"aa",
"\u0d3f":"i",
"\u0d40":"ii",
"\u0d41":"u",
"\u0d42":"uu",
"\u0d43":"r",
"\u0d46":"e",
"\u0d47":"ee",
"\u0d48":"ai",
"\u0d4a":"o",
"\u0d4b":"oo",
"\u0d4c":"au",
"\u0d60":"rr",
"\u0d61":"ll",
"\u0d66":"0",
"\u0d67":"1",
"\u0d68":"2",
"\u0d69":"3",
"\u0d6a":"4",
"\u0d6b":"5",
"\u0d6c":"6",
"\u0d6d":"7",
"\u0d6e":"8",
"\u0d6f":"9",
"\u0d82":"n",
"\u0d83":"h",
"\u0d85":"a",
"\u0d86":"aa",
"\u0d87":"ae",
"\u0d88":"aae",
"\u0d89":"i",
"\u0d8a":"ii",
"\u0d8b":"u",
"\u0d8c":"uu",
"\u0d8d":"r",
"\u0d8e":"rr",
"\u0d8f":"l",
"\u0d90":"ll",
"\u0d91":"e",
"\u0d92":"ee",
"\u0d93":"ai",
"\u0d94":"o",
"\u0d95":"oo",
"\u0d96":"au",
"\u0d9a":"k",
"\u0d9b":"kh",
"\u0d9c":"g",
"\u0d9d":"gh",
"\u0d9e":"ng",
"\u0d9f":"nng",
"\u0da0":"c",
"\u0da1":"ch",
"\u0da2":"j",
"\u0da3":"jh",
"\u0da4":"ny",
"\u0da5":"jny",
"\u0da6":"nyj",
"\u0da7":"tt",
"\u0da8":"tth",
"\u0da9":"dd",
"\u0daa":"ddh",
"\u0dab":"nn",
"\u0dac":"nndd",
"\u0dad":"t",
"\u0dae":"th",
"\u0daf":"d",
"\u0db0":"dh",
"\u0db1":"n",
"\u0db3":"nd",
"\u0db4":"p",
"\u0db5":"ph",
"\u0db6":"b",
"\u0db7":"bh",
"\u0db8":"m",
"\u0db9":"mb",
"\u0dba":"y",
"\u0dbb":"r",
"\u0dbd":"l",
"\u0dc0":"v",
"\u0dc1":"sh",
"\u0dc2":"ss",
"\u0dc3":"s",
"\u0dc4":"h",
"\u0dc5":"ll",
"\u0dc6":"f",
"\u0dcf":"aa",
"\u0dd0":"ae",
"\u0dd1":"aae",
"\u0dd2":"i",
"\u0dd3":"ii",
"\u0dd4":"u",
"\u0dd6":"uu",
"\u0dd8":"r",
"\u0dd9":"e",
"\u0dda":"ee",
"\u0ddb":"ai",
"\u0ddc":"o",
"\u0ddd":"oo",
"\u0dde":"au",
"\u0ddf":"l",
"\u0df2":"rr",
"\u0df3":"ll",
"\u0e01":"k",
"\u0e02":"kh",
"\u0e03":"kh",
"\u0e04":"kh",
"\u0e05":"kh",
"\u0e06":"kh",
"\u0e07":"ng",
"\u0e08":"cch",
"\u0e09":"ch",
"\u0e0a":"ch",
"\u0e0b":"ch",
"\u0e0c":"ch",
"\u0e0d":"y",
"\u0e0e":"d",
"\u0e0f":"t",
"\u0e10":"th",
"\u0e11":"th",
"\u0e12":"th",
"\u0e13":"n",
"\u0e14":"d",
"\u0e15":"t",
"\u0e16":"th",
"\u0e17":"th",
"\u0e18":"th",
"\u0e19":"n",
"\u0e1a":"b",
"\u0e1b":"p",
"\u0e1c":"ph",
"\u0e1d":"f",
"\u0e1e":"ph",
"\u0e1f":"f",
"\u0e20":"ph",
"\u0e21":"m",
"\u0e22":"y",
"\u0e23":"r",
"\u0e24":"r",
"\u0e25":"l",
"\u0e26":"l",
"\u0e27":"w",
"\u0e28":"s",
"\u0e29":"s",
"\u0e2a":"s",
"\u0e2b":"h",
"\u0e2c":"l",
"\u0e2e":"h",
"\u0e30":"a",
"\u0e31":"a",
"\u0e32":"aa",
"\u0e33":"am",
"\u0e34":"i",
"\u0e35":"ii",
"\u0e36":"ue",
"\u0e37":"uue",
"\u0e38":"u",
"\u0e39":"uu",
"\u0e3f":"bh",
"\u0e40":"e",
"\u0e41":"ae",
"\u0e42":"o",
"\u0e43":"ai",
"\u0e44":"ai",
"\u0e45":"ao",
"\u0e4d":"m",
"\u0e50":"0",
"\u0e51":"1",
"\u0e52":"2",
"\u0e53":"3",
"\u0e54":"4",
"\u0e55":"5",
"\u0e56":"6",
"\u0e57":"7",
"\u0e58":"8",
"\u0e59":"9",
"\u0e81":"k",
"\u0e82":"kh",
"\u0e84":"kh",
"\u0e87":"ng",
"\u0e88":"ch",
"\u0e8a":"s",
"\u0e8d":"ny",
"\u0e94":"d",
"\u0e95":"h",
"\u0e96":"th",
"\u0e97":"th",
"\u0e99":"n",
"\u0e9a":"b",
"\u0e9b":"p",
"\u0e9c":"ph",
"\u0e9d":"f",
"\u0e9e":"ph",
"\u0e9f":"f",
"\u0ea1":"m",
"\u0ea2":"y",
"\u0ea3":"r",
"\u0ea5":"l",
"\u0ea7":"w",
"\u0eaa":"s",
"\u0eab":"h",
"\u0eb0":"a",
"\u0eb2":"aa",
"\u0eb3":"am",
"\u0eb4":"i",
"\u0eb5":"ii",
"\u0eb6":"y",
"\u0eb7":"yy",
"\u0eb8":"u",
"\u0eb9":"uu",
"\u0ebb":"o",
"\u0ebc":"l",
"\u0ebd":"ny",
"\u0ec0":"e",
"\u0ec1":"ei",
"\u0ec2":"o",
"\u0ec3":"ay",
"\u0ec4":"ai",
"\u0ecd":"m",
"\u0ed0":"0",
"\u0ed1":"1",
"\u0ed2":"2",
"\u0ed3":"3",
"\u0ed4":"4",
"\u0ed5":"5",
"\u0ed6":"6",
"\u0ed7":"7",
"\u0ed8":"8",
"\u0ed9":"9",
"\u0edc":"hn",
"\u0edd":"hm",
"\u0f00":"aum",
"\u0f11":"x",
"\u0f12":"xx",
"\u0f13":"x",
"\u0f20":"0",
"\u0f21":"1",
"\u0f22":"2",
"\u0f23":"3",
"\u0f24":"4",
"\u0f25":"5",
"\u0f26":"6",
"\u0f27":"7",
"\u0f28":"8",
"\u0f29":"9",
"\u0f2a":"5",
"\u0f2b":"15",
"\u0f2c":"25",
"\u0f2d":"35",
"\u0f2e":"45",
"\u0f2f":"55",
"\u0f30":"65",
"\u0f31":"75",
"\u0f32":"85",
"\u0f33":"5",
"\u0f40":"k",
"\u0f41":"kh",
"\u0f42":"g",
"\u0f43":"gh",
"\u0f44":"ng",
"\u0f45":"c",
"\u0f46":"ch",
"\u0f47":"j",
"\u0f49":"ny",
"\u0f4a":"tt",
"\u0f4b":"tth",
"\u0f4c":"dd",
"\u0f4d":"ddh",
"\u0f4e":"nn",
"\u0f4f":"t",
"\u0f50":"th",
"\u0f51":"d",
"\u0f52":"dh",
"\u0f53":"n",
"\u0f54":"p",
"\u0f55":"ph",
"\u0f56":"b",
"\u0f57":"bh",
"\u0f58":"m",
"\u0f59":"ts",
"\u0f5a":"tsh",
"\u0f5b":"dz",
"\u0f5c":"dzh",
"\u0f5d":"w",
"\u0f5e":"zh",
"\u0f5f":"z",
"\u0f61":"y",
"\u0f62":"r",
"\u0f63":"l",
"\u0f64":"sh",
"\u0f65":"ssh",
"\u0f66":"s",
"\u0f67":"h",
"\u0f68":"a",
"\u0f69":"kss",
"\u0f6a":"r",
"\u0f71":"aa",
"\u0f72":"i",
"\u0f73":"ii",
"\u0f74":"u",
"\u0f75":"uu",
"\u0f76":"r",
"\u0f77":"rr",
"\u0f78":"l",
"\u0f79":"ll",
"\u0f7a":"e",
"\u0f7b":"ee",
"\u0f7c":"o",
"\u0f7d":"oo",
"\u0f7e":"m",
"\u0f7f":"h",
"\u0f80":"i",
"\u0f81":"ii",
"\u0f90":"k",
"\u0f91":"kh",
"\u0f92":"g",
"\u0f93":"gh",
"\u0f94":"ng",
"\u0f95":"c",
"\u0f96":"ch",
"\u0f97":"j",
"\u0f99":"ny",
"\u0f9a":"tt",
"\u0f9b":"tth",
"\u0f9c":"dd",
"\u0f9d":"ddh",
"\u0f9e":"nn",
"\u0f9f":"t",
"\u0fa0":"th",
"\u0fa1":"d",
"\u0fa2":"dh",
"\u0fa3":"n",
"\u0fa4":"p",
"\u0fa5":"ph",
"\u0fa6":"b",
"\u0fa7":"bh",
"\u0fa8":"m",
"\u0fa9":"ts",
"\u0faa":"tsh",
"\u0fab":"dz",
"\u0fac":"dzh",
"\u0fad":"w",
"\u0fae":"zh",
"\u0faf":"z",
"\u0fb1":"y",
"\u0fb2":"r",
"\u0fb3":"l",
"\u0fb4":"sh",
"\u0fb5":"ss",
"\u0fb6":"s",
"\u0fb7":"h",
"\u0fb8":"a",
"\u0fb9":"kss",
"\u0fba":"w",
"\u0fbb":"y",
"\u0fbc":"r",
"\u0fbe":"x",
"\u0fbf":"x",
"\u0fc0":"o",
"\u0fc1":"o",
"\u0fc2":"o",
"\u0fc3":"o",
"\u1000":"k",
"\u1001":"kh",
"\u1002":"g",
"\u1003":"gh",
"\u1004":"ng",
"\u1005":"c",
"\u1006":"ch",
"\u1007":"j",
"\u1008":"jh",
"\u1009":"ny",
"\u100a":"nny",
"\u100b":"tt",
"\u100c":"tth",
"\u100d":"dd",
"\u100e":"ddh",
"\u100f":"nn",
"\u1010":"tt",
"\u1011":"th",
"\u1012":"d",
"\u1013":"dh",
"\u1014":"n",
"\u1015":"p",
"\u1016":"ph",
"\u1017":"b",
"\u1018":"bh",
"\u1019":"m",
"\u101a":"y",
"\u101b":"r",
"\u101c":"l",
"\u101d":"w",
"\u101e":"s",
"\u101f":"h",
"\u1020":"ll",
"\u1021":"a",
"\u1023":"i",
"\u1024":"ii",
"\u1025":"u",
"\u1026":"uu",
"\u1027":"e",
"\u1029":"o",
"\u102a":"au",
"\u102c":"aa",
"\u102d":"i",
"\u102e":"ii",
"\u102f":"u",
"\u1030":"uu",
"\u1031":"e",
"\u1032":"ai",
"\u1036":"n",
"\u1040":"0",
"\u1041":"1",
"\u1042":"2",
"\u1043":"3",
"\u1044":"4",
"\u1045":"5",
"\u1046":"6",
"\u1047":"7",
"\u1048":"8",
"\u1049":"9",
"\u104c":"n",
"\u104d":"r",
"\u104e":"l",
"\u104f":"e",
"\u1050":"sh",
"\u1051":"ss",
"\u1052":"r",
"\u1053":"rr",
"\u1054":"l",
"\u1055":"ll",
"\u1056":"r",
"\u1057":"rr",
"\u1058":"l",
"\u1059":"ll",
"\u10a0":"a",
"\u10a1":"b",
"\u10a2":"g",
"\u10a3":"d",
"\u10a4":"e",
"\u10a5":"v",
"\u10a6":"z",
"\u10a7":"t",
"\u10a8":"i",
"\u10a9":"k",
"\u10aa":"l",
"\u10ab":"m",
"\u10ac":"n",
"\u10ad":"o",
"\u10ae":"p",
"\u10af":"zh",
"\u10b0":"r",
"\u10b1":"s",
"\u10b2":"t",
"\u10b3":"u",
"\u10b4":"p",
"\u10b5":"k",
"\u10b6":"g",
"\u10b7":"q",
"\u10b8":"sh",
"\u10b9":"ch",
"\u10ba":"c",
"\u10bb":"z",
"\u10bc":"c",
"\u10bd":"ch",
"\u10be":"x",
"\u10bf":"j",
"\u10c0":"h",
"\u10c1":"e",
"\u10c2":"y",
"\u10c3":"w",
"\u10c4":"xh",
"\u10c5":"oe",
"\u10d0":"a",
"\u10d1":"b",
"\u10d2":"g",
"\u10d3":"d",
"\u10d4":"e",
"\u10d5":"v",
"\u10d6":"z",
"\u10d7":"t",
"\u10d8":"i",
"\u10d9":"k",
"\u10da":"l",
"\u10db":"m",
"\u10dc":"n",
"\u10dd":"o",
"\u10de":"p",
"\u10df":"zh",
"\u10e0":"r",
"\u10e1":"s",
"\u10e2":"t",
"\u10e3":"u",
"\u10e4":"p",
"\u10e5":"k",
"\u10e6":"g",
"\u10e7":"q",
"\u10e8":"sh",
"\u10e9":"ch",
"\u10ea":"c",
"\u10eb":"z",
"\u10ec":"c",
"\u10ed":"ch",
"\u10ee":"x",
"\u10ef":"j",
"\u10f0":"h",
"\u10f1":"e",
"\u10f2":"y",
"\u10f3":"w",
"\u10f4":"xh",
"\u10f5":"oe",
"\u10f6":"f",
"\u13a0":"a",
"\u13a1":"e",
"\u13a2":"i",
"\u13a3":"o",
"\u13a4":"u",
"\u13a5":"v",
"\u13a6":"ga",
"\u13a7":"ka",
"\u13a8":"ge",
"\u13a9":"gi",
"\u13aa":"go",
"\u13ab":"gu",
"\u13ac":"gv",
"\u13ad":"ha",
"\u13ae":"he",
"\u13af":"hi",
"\u13b0":"ho",
"\u13b1":"hu",
"\u13b2":"hv",
"\u13b3":"la",
"\u13b4":"le",
"\u13b5":"li",
"\u13b6":"lo",
"\u13b7":"lu",
"\u13b8":"lv",
"\u13b9":"ma",
"\u13ba":"me",
"\u13bb":"mi",
"\u13bc":"mo",
"\u13bd":"mu",
"\u13be":"na",
"\u13bf":"hna",
"\u13c0":"nah",
"\u13c1":"ne",
"\u13c2":"ni",
"\u13c3":"no",
"\u13c4":"nu",
"\u13c5":"nv",
"\u13c6":"qua",
"\u13c7":"que",
"\u13c8":"qui",
"\u13c9":"quo",
"\u13ca":"quu",
"\u13cb":"quv",
"\u13cc":"sa",
"\u13cd":"s",
"\u13ce":"se",
"\u13cf":"si",
"\u13d0":"so",
"\u13d1":"su",
"\u13d2":"sv",
"\u13d3":"da",
"\u13d4":"ta",
"\u13d5":"de",
"\u13d6":"te",
"\u13d7":"di",
"\u13d8":"ti",
"\u13d9":"do",
"\u13da":"du",
"\u13db":"dv",
"\u13dc":"dla",
"\u13dd":"tla",
"\u13de":"tle",
"\u13df":"tli",
"\u13e0":"tlo",
"\u13e1":"tlu",
"\u13e2":"tlv",
"\u13e3":"tsa",
"\u13e4":"tse",
"\u13e5":"tsi",
"\u13e6":"tso",
"\u13e7":"tsu",
"\u13e8":"tsv",
"\u13e9":"wa",
"\u13ea":"we",
"\u13eb":"wi",
"\u13ec":"wo",
"\u13ed":"wu",
"\u13ee":"wv",
"\u13ef":"ya",
"\u13f0":"ye",
"\u13f1":"yi",
"\u13f2":"yo",
"\u13f3":"yu",
"\u13f4":"yv",
"\u13f5":"mv",
"\u13f8":"ye",
"\u13f9":"yi",
"\u13fa":"yo",
"\u13fb":"yu",
"\u13fc":"yv",
"\u13fd":"mv",
"\u1681":"b",
"\u1682":"l",
"\u1683":"f",
"\u1684":"s",
"\u1685":"n",
"\u1686":"h",
"\u1687":"d",
"\u1688":"t",
"\u1689":"c",
"\u168a":"q",
"\u168b":"m",
"\u168c":"g",
"\u168d":"ng",
"\u168e":"z",
"\u168f":"r",
"\u1690":"a",
"\u1691":"o",
"\u1692":"u",
"\u1693":"e",
"\u1694":"i",
"\u1695":"ch",
"\u1696":"th",
"\u1697":"ph",
"\u1698":"p",
"\u1699":"x",
"\u169a":"p",
"\u16a0":"f",
"\u16a1":"v",
"\u16a2":"u",
"\u16a3":"yr",
"\u16a4":"y",
"\u16a5":"w",
"\u16a6":"th",
"\u16a7":"th",
"\u16a8":"a",
"\u16a9":"o",
"\u16aa":"ac",
"\u16ab":"ae",
"\u16ac":"o",
"\u16ad":"o",
"\u16ae":"o",
"\u16af":"oe",
"\u16b0":"on",
"\u16b1":"r",
"\u16b2":"k",
"\u16b3":"c",
"\u16b4":"k",
"\u16b5":"g",
"\u16b6":"ng",
"\u16b7":"g",
"\u16b8":"g",
"\u16b9":"w",
"\u16ba":"h",
"\u16bb":"h",
"\u16bc":"h",
"\u16bd":"h",
"\u16be":"n",
"\u16bf":"n",
"\u16c0":"n",
"\u16c1":"i",
"\u16c2":"e",
"\u16c3":"j",
"\u16c4":"g",
"\u16c5":"ae",
"\u16c6":"a",
"\u16c7":"eo",
"\u16c8":"p",
"\u16c9":"z",
"\u16ca":"s",
"\u16cb":"s",
"\u16cc":"s",
"\u16cd":"c",
"\u16ce":"z",
"\u16cf":"t",
"\u16d0":"t",
"\u16d1":"d",
"\u16d2":"b",
"\u16d3":"b",
"\u16d4":"p",
"\u16d5":"p",
"\u16d6":"e",
"\u16d7":"m",
"\u16d8":"m",
"\u16d9":"m",
"\u16da":"l",
"\u16db":"l",
"\u16dc":"ng",
"\u16dd":"ng",
"\u16de":"d",
"\u16df":"o",
"\u16e0":"ear",
"\u16e1":"ior",
"\u16e2":"qu",
"\u16e3":"qu",
"\u16e4":"qu",
"\u16e5":"s",
"\u16e6":"yr",
"\u16e7":"yr",
"\u16e8":"yr",
"\u16e9":"q",
"\u16ea":"x",
"\u16ee":"17",
"\u16ef":"18",
"\u16f0":"19",
"\u1780":"k",
"\u1781":"kh",
"\u1782":"g",
"\u1783":"gh",
"\u1784":"ng",
"\u1785":"c",
"\u1786":"ch",
"\u1787":"j",
"\u1788":"jh",
"\u1789":"ny",
"\u178a":"t",
"\u178b":"tth",
"\u178c":"d",
"\u178d":"ddh",
"\u178e":"nn",
"\u178f":"t",
"\u1790":"th",
"\u1791":"d",
"\u1792":"dh",
"\u1793":"n",
"\u1794":"p",
"\u1795":"ph",
"\u1796":"b",
"\u1797":"bh",
"\u1798":"m",
"\u1799":"y",
"\u179a":"r",
"\u179b":"l",
"\u179c":"v",
"\u179d":"sh",
"\u179e":"ss",
"\u179f":"s",
"\u17a0":"h",
"\u17a1":"l",
"\u17a2":"q",
"\u17a3":"a",
"\u17a4":"aa",
"\u17a5":"i",
"\u17a6":"ii",
"\u17a7":"u",
"\u17a8":"uk",
"\u17a9":"uu",
"\u17aa":"uuv",
"\u17ab":"ry",
"\u17ac":"ryy",
"\u17ad":"ly",
"\u17ae":"lyy",
"\u17af":"e",
"\u17b0":"ai",
"\u17b1":"oo",
"\u17b2":"oo",
"\u17b3":"au",
"\u17b4":"a",
"\u17b5":"aa",
"\u17b6":"aa",
"\u17b7":"i",
"\u17b8":"ii",
"\u17b9":"y",
"\u17ba":"yy",
"\u17bb":"u",
"\u17bc":"uu",
"\u17bd":"ua",
"\u17be":"oe",
"\u17bf":"ya",
"\u17c0":"ie",
"\u17c1":"e",
"\u17c2":"ae",
"\u17c3":"ai",
"\u17c4":"oo",
"\u17c5":"au",
"\u17c6":"m",
"\u17c7":"h",
"\u17c8":"a",
"\u17cc":"r",
"\u17db":"kr",
"\u17e0":"0",
"\u17e1":"1",
"\u17e2":"2",
"\u17e3":"3",
"\u17e4":"4",
"\u17e5":"5",
"\u17e6":"6",
"\u17e7":"7",
"\u17e8":"8",
"\u17e9":"9",
"\u1810":"0",
"\u1811":"1",
"\u1812":"2",
"\u1813":"3",
"\u1814":"4",
"\u1815":"5",
"\u1816":"6",
"\u1817":"7",
"\u1818":"8",
"\u1819":"9",
"\u1820":"a",
"\u1821":"e",
"\u1822":"i",
"\u1823":"o",
"\u1824":"u",
"\u1825":"o",
"\u1826":"u",
"\u1827":"ee",
"\u1828":"n",
"\u1829":"ng",
"\u182a":"b",
"\u182b":"p",
"\u182c":"q",
"\u182d":"g",
"\u182e":"m",
"\u182f":"l",
"\u1830":"s",
"\u1831":"sh",
"\u1832":"t",
"\u1833":"d",
"\u1834":"ch",
"\u1835":"j",
"\u1836":"y",
"\u1837":"r",
"\u1838":"w",
"\u1839":"f",
"\u183a":"k",
"\u183b":"kha",
"\u183c":"ts",
"\u183d":"z",
"\u183e":"h",
"\u183f":"zr",
"\u1840":"lh",
"\u1841":"zh",
"\u1842":"ch",
"\u1844":"e",
"\u1845":"i",
"\u1846":"o",
"\u1847":"u",
"\u1848":"o",
"\u1849":"u",
"\u184a":"ng",
"\u184b":"b",
"\u184c":"p",
"\u184d":"q",
"\u184e":"g",
"\u184f":"m",
"\u1850":"t",
"\u1851":"d",
"\u1852":"ch",
"\u1853":"j",
"\u1854":"ts",
"\u1855":"y",
"\u1856":"w",
"\u1857":"k",
"\u1858":"g",
"\u1859":"h",
"\u185a":"jy",
"\u185b":"ny",
"\u185c":"dz",
"\u185d":"e",
"\u185e":"i",
"\u185f":"iy",
"\u1860":"u",
"\u1861":"u",
"\u1862":"ng",
"\u1863":"k",
"\u1864":"g",
"\u1865":"h",
"\u1866":"p",
"\u1867":"sh",
"\u1868":"t",
"\u1869":"d",
"\u186a":"j",
"\u186b":"f",
"\u186c":"g",
"\u186d":"h",
"\u186e":"ts",
"\u186f":"z",
"\u1870":"r",
"\u1871":"ch",
"\u1872":"zh",
"\u1873":"i",
"\u1874":"k",
"\u1875":"r",
"\u1876":"f",
"\u1877":"zh",
"\u1881":"h",
"\u1882":"x",
"\u1883":"w",
"\u1884":"m",
"\u1885":"3",
"\u1886":"333",
"\u1887":"a",
"\u1888":"i",
"\u1889":"k",
"\u188a":"ng",
"\u188b":"c",
"\u188c":"tt",
"\u188d":"tth",
"\u188e":"dd",
"\u188f":"nn",
"\u1890":"t",
"\u1891":"d",
"\u1892":"p",
"\u1893":"ph",
"\u1894":"ss",
"\u1895":"zh",
"\u1896":"z",
"\u1897":"a",
"\u1898":"t",
"\u1899":"zh",
"\u189a":"gh",
"\u189b":"ng",
"\u189c":"c",
"\u189d":"jh",
"\u189e":"tta",
"\u189f":"ddh",
"\u18a0":"t",
"\u18a1":"dh",
"\u18a2":"ss",
"\u18a3":"cy",
"\u18a4":"zh",
"\u18a5":"z",
"\u18a6":"u",
"\u18a7":"y",
"\u18a8":"bh",
"\u1e00":"a",
"\u1e01":"a",
"\u1e02":"b",
"\u1e03":"b",
"\u1e04":"b",
"\u1e05":"b",
"\u1e06":"b",
"\u1e07":"b",
"\u1e08":"c",
"\u1e09":"c",
"\u1e0a":"d",
"\u1e0b":"d",
"\u1e0c":"d",
"\u1e0d":"d",
"\u1e0e":"d",
"\u1e0f":"d",
"\u1e10":"d",
"\u1e11":"d",
"\u1e12":"d",
"\u1e13":"d",
"\u1e14":"e",
"\u1e15":"e",
"\u1e16":"e",
"\u1e17":"e",
"\u1e18":"e",
"\u1e19":"e",
"\u1e1a":"e",
"\u1e1b":"e",
"\u1e1c":"e",
"\u1e1d":"e",
"\u1e1e":"f",
"\u1e1f":"f",
"\u1e20":"g",
"\u1e21":"g",
"\u1e22":"h",
"\u1e23":"h",
"\u1e24":"h",
"\u1e25":"h",
"\u1e26":"h",
"\u1e27":"h",
"\u1e28":"h",
"\u1e29":"h",
"\u1e2a":"h",
"\u1e2b":"h",
"\u1e2c":"i",
"\u1e2d":"i",
"\u1e2e":"i",
"\u1e2f":"i",
"\u1e30":"k",
"\u1e31":"k",
"\u1e32":"k",
"\u1e33":"k",
"\u1e34":"k",
"\u1e35":"k",
"\u1e36":"l",
"\u1e37":"l",
"\u1e38":"l",
"\u1e39":"l",
"\u1e3a":"l",
"\u1e3b":"l",
"\u1e3c":"l",
"\u1e3d":"l",
"\u1e3e":"m",
"\u1e3f":"m",
"\u1e40":"m",
"\u1e41":"m",
"\u1e42":"m",
"\u1e43":"m",
"\u1e44":"n",
"\u1e45":"n",
"\u1e46":"n",
"\u1e47":"n",
"\u1e48":"n",
"\u1e49":"n",
"\u1e4a":"n",
"\u1e4b":"n",
"\u1e4c":"o",
"\u1e4d":"o",
"\u1e4e":"o",
"\u1e4f":"o",
"\u1e50":"o",
"\u1e51":"o",
"\u1e52":"o",
"\u1e53":"o",
"\u1e54":"p",
"\u1e55":"p",
"\u1e56":"p",
"\u1e57":"p",
"\u1e58":"r",
"\u1e59":"r",
"\u1e5a":"r",
"\u1e5b":"r",
"\u1e5c":"r",
"\u1e5d":"r",
"\u1e5e":"r",
"\u1e5f":"r",
"\u1e60":"s",
"\u1e61":"s",
"\u1e62":"s",
"\u1e63":"s",
"\u1e64":"s",
"\u1e65":"s",
"\u1e66":"s",
"\u1e67":"s",
"\u1e68":"s",
"\u1e69":"s",
"\u1e6a":"t",
"\u1e6b":"t",
"\u1e6c":"t",
"\u1e6d":"t",
"\u1e6e":"t",
"\u1e6f":"t",
"\u1e70":"t",
"\u1e71":"t",
"\u1e72":"u",
"\u1e73":"u",
"\u1e74":"u",
"\u1e75":"u",
"\u1e76":"u",
"\u1e77":"u",
"\u1e78":"u",
"\u1e79":"u",
"\u1e7a":"u",
"\u1e7b":"u",
"\u1e7c":"v",
"\u1e7d":"v",
"\u1e7e":"v",
"\u1e7f":"v",
"\u1e80":"w",
"\u1e81":"w",
"\u1e82":"w",
"\u1e83":"w",
"\u1e84":"w",
"\u1e85":"w",
"\u1e86":"w",
"\u1e87":"w",
"\u1e88":"w",
"\u1e89":"w",
"\u1e8a":"x",
"\u1e8b":"x",
"\u1e8c":"x",
"\u1e8d":"x",
"\u1e8e":"y",
"\u1e8f":"y",
"\u1e90":"z",
"\u1e91":"z",
"\u1e92":"z",
"\u1e93":"z",
"\u1e94":"z",
"\u1e95":"z",
"\u1e96":"h",
"\u1e97":"t",
"\u1e98":"w",
"\u1e99":"y",
"\u1e9a":"a",
"\u1e9b":"s",
"\u1e9c":"s",
"\u1e9d":"s",
"\u1e9e":"ss",
"\u1e9f":"d",
"\u1ea0":"a",
"\u1ea1":"a",
"\u1ea2":"a",
"\u1ea3":"a",
"\u1ea4":"a",
"\u1ea5":"a",
"\u1ea6":"a",
"\u1ea7":"a",
"\u1ea8":"a",
"\u1ea9":"a",
"\u1eaa":"a",
"\u1eab":"a",
"\u1eac":"a",
"\u1ead":"a",
"\u1eae":"a",
"\u1eaf":"a",
"\u1eb0":"a",
"\u1eb1":"a",
"\u1eb2":"a",
"\u1eb3":"a",
"\u1eb4":"a",
"\u1eb5":"a",
"\u1eb6":"a",
"\u1eb7":"a",
"\u1eb8":"e",
"\u1eb9":"e",
"\u1eba":"e",
"\u1ebb":"e",
"\u1ebc":"e",
"\u1ebd":"e",
"\u1ebe":"e",
"\u1ebf":"e",
"\u1ec0":"e",
"\u1ec1":"e",
"\u1ec2":"e",
"\u1ec3":"e",
"\u1ec4":"e",
"\u1ec5":"e",
"\u1ec6":"e",
"\u1ec7":"e",
"\u1ec8":"i",
"\u1ec9":"i",
"\u1eca":"i",
"\u1ecb":"i",
"\u1ecc":"o",
"\u1ecd":"o",
"\u1ece":"o",
"\u1ecf":"o",
"\u1ed0":"o",
"\u1ed1":"o",
"\u1ed2":"o",
"\u1ed3":"o",
"\u1ed4":"o",
"\u1ed5":"o",
"\u1ed6":"o",
"\u1ed7":"o",
"\u1ed8":"o",
"\u1ed9":"o",
"\u1eda":"o",
"\u1edb":"o",
"\u1edc":"o",
"\u1edd":"o",
"\u1ede":"o",
"\u1edf":"o",
"\u1ee0":"o",
"\u1ee1":"o",
"\u1ee2":"o",
"\u1ee3":"o",
"\u1ee4":"u",
"\u1ee5":"u",
"\u1ee6":"u",
"\u1ee7":"u",
"\u1ee8":"u",
"\u1ee9":"u",
"\u1eea":"u",
"\u1eeb":"u",
"\u1eec":"u",
"\u1eed":"u",
"\u1eee":"u",
"\u1eef":"u",
"\u1ef0":"u",
"\u1ef1":"u",
"\u1ef2":"y",
"\u1ef3":"y",
"\u1ef4":"y",
"\u1ef5":"y",
"\u1ef6":"y",
"\u1ef7":"y",
"\u1ef8":"y",
"\u1ef9":"y",
"\u1efa":"ll",
"\u1efb":"ll",
"\u1efc":"v",
"\u1efd":"v",
"\u1efe":"y",
"\u1eff":"y",
"\u1f00":"a",
"\u1f01":"a",
"\u1f02":"a",
"\u1f03":"a",
"\u1f04":"a",
"\u1f05":"a",
"\u1f06":"a",
"\u1f07":"a",
"\u1f08":"a",
"\u1f09":"a",
"\u1f0a":"a",
"\u1f0b":"a",
"\u1f0c":"a",
"\u1f0d":"a",
"\u1f0e":"a",
"\u1f0f":"a",
"\u1f10":"e",
"\u1f11":"e",
"\u1f12":"e",
"\u1f13":"e",
"\u1f14":"e",
"\u1f15":"e",
"\u1f18":"e",
"\u1f19":"e",
"\u1f1a":"e",
"\u1f1b":"e",
"\u1f1c":"e",
"\u1f1d":"e",
"\u1f20":"e",
"\u1f21":"e",
"\u1f22":"e",
"\u1f23":"e",
"\u1f24":"e",
"\u1f25":"e",
"\u1f26":"e",
"\u1f27":"e",
"\u1f28":"e",
"\u1f29":"e",
"\u1f2a":"e",
"\u1f2b":"e",
"\u1f2c":"e",
"\u1f2d":"e",
"\u1f2e":"e",
"\u1f2f":"e",
"\u1f30":"i",
"\u1f31":"i",
"\u1f32":"i",
"\u1f33":"i",
"\u1f34":"i",
"\u1f35":"i",
"\u1f36":"i",
"\u1f37":"i",
"\u1f38":"i",
"\u1f39":"i",
"\u1f3a":"i",
"\u1f3b":"i",
"\u1f3c":"i",
"\u1f3d":"i",
"\u1f3e":"i",
"\u1f3f":"i",
"\u1f40":"o",
"\u1f41":"o",
"\u1f42":"o",
"\u1f43":"o",
"\u1f44":"o",
"\u1f45":"o",
"\u1f48":"o",
"\u1f49":"o",
"\u1f4a":"o",
"\u1f4b":"o",
"\u1f4c":"o",
"\u1f4d":"o",
"\u1f50":"u",
"\u1f51":"u",
"\u1f52":"u",
"\u1f53":"u",
"\u1f54":"u",
"\u1f55":"u",
"\u1f56":"u",
"\u1f57":"u",
"\u1f59":"u",
"\u1f5b":"u",
"\u1f5d":"u",
"\u1f5f":"u",
"\u1f60":"o",
"\u1f61":"o",
"\u1f62":"o",
"\u1f63":"o",
"\u1f64":"o",
"\u1f65":"o",
"\u1f66":"o",
"\u1f67":"o",
"\u1f68":"o",
"\u1f69":"o",
"\u1f6a":"o",
"\u1f6b":"o",
"\u1f6c":"o",
"\u1f6d":"o",
"\u1f6e":"o",
"\u1f6f":"o",
"\u1f70":"a",
"\u1f71":"a",
"\u1f72":"e",
"\u1f73":"e",
"\u1f74":"e",
"\u1f75":"e",
"\u1f76":"i",
"\u1f77":"i",
"\u1f78":"o",
"\u1f79":"o",
"\u1f7a":"u",
"\u1f7b":"u",
"\u1f7c":"o",
"\u1f7d":"o",
"\u1f80":"a",
"\u1f81":"a",
"\u1f82":"a",
"\u1f83":"a",
"\u1f84":"a",
"\u1f85":"a",
"\u1f86":"a",
"\u1f87":"a",
"\u1f88":"a",
"\u1f89":"a",
"\u1f8a":"a",
"\u1f8b":"a",
"\u1f8c":"a",
"\u1f8d":"a",
"\u1f8e":"a",
"\u1f8f":"a",
"\u1f90":"e",
"\u1f91":"e",
"\u1f92":"e",
"\u1f93":"e",
"\u1f94":"e",
"\u1f95":"e",
"\u1f96":"e",
"\u1f97":"e",
"\u1f98":"e",
"\u1f99":"e",
"\u1f9a":"e",
"\u1f9b":"e",
"\u1f9c":"e",
"\u1f9d":"e",
"\u1f9e":"e",
"\u1f9f":"e",
"\u1fa0":"o",
"\u1fa1":"o",
"\u1fa2":"o",
"\u1fa3":"o",
"\u1fa4":"o",
"\u1fa5":"o",
"\u1fa6":"o",
"\u1fa7":"o",
"\u1fa8":"o",
"\u1fa9":"o",
"\u1faa":"o",
"\u1fab":"o",
"\u1fac":"o",
"\u1fad":"o",
"\u1fae":"o",
"\u1faf":"o",
"\u1fb0":"a",
"\u1fb1":"a",
"\u1fb2":"a",
"\u1fb3":"a",
"\u1fb4":"a",
"\u1fb6":"a",
"\u1fb7":"a",
"\u1fb8":"a",
"\u1fb9":"a",
"\u1fba":"a",
"\u1fbb":"a",
"\u1fbc":"a",
"\u1fbe":"i",
"\u1fc2":"e",
"\u1fc3":"e",
"\u1fc4":"e",
"\u1fc6":"e",
"\u1fc7":"e",
"\u1fc8":"e",
"\u1fc9":"e",
"\u1fca":"e",
"\u1fcb":"e",
"\u1fcc":"e",
"\u1fd0":"i",
"\u1fd1":"i",
"\u1fd2":"i",
"\u1fd3":"i",
"\u1fd6":"i",
"\u1fd7":"i",
"\u1fd8":"i",
"\u1fd9":"i",
"\u1fda":"i",
"\u1fdb":"i",
"\u1fe0":"u",
"\u1fe1":"u",
"\u1fe2":"u",
"\u1fe3":"u",
"\u1fe4":"r",
"\u1fe5":"r",
"\u1fe6":"u",
"\u1fe7":"u",
"\u1fe8":"u",
"\u1fe9":"u",
"\u1fea":"u",
"\u1feb":"u",
"\u1fec":"r",
"\u1ff2":"o",
"\u1ff3":"o",
"\u1ff4":"o",
"\u1ff6":"o",
"\u1ff7":"o",
"\u1ff8":"o",
"\u1ff9":"o",
"\u1ffa":"o",
"\u1ffb":"o",
"\u1ffc":"o",
"\u2030":"ooo",
"\u2031":"00",
"\u204a":"7",
"\u204b":"pp",
"\u2070":"0",
"\u2074":"4",
"\u2075":"5",
"\u2076":"6",
"\u2077":"7",
"\u2078":"8",
"\u2079":"9",
"\u207f":"n",
"\u2080":"0",
"\u2081":"1",
"\u2082":"2",
"\u2083":"3",
"\u2084":"4",
"\u2085":"5",
"\u2086":"6",
"\u2087":"7",
"\u2088":"8",
"\u2089":"9",
"\u20a0":"ecu",
"\u20a1":"cl",
"\u20a2":"cr",
"\u20a3":"ff",
"\u20a4":"l",
"\u20a5":"mil",
"\u20a6":"n",
"\u20a7":"pts",
"\u20a8":"rs",
"\u20a9":"w",
"\u20aa":"ns",
"\u20ab":"d",
"\u20ac":"eur",
"\u20ad":"k",
"\u20ae":"t",
"\u20af":"dr",
"\u2122":"tm",
"\u2153":"13",
"\u2154":"23",
"\u2155":"15",
"\u2156":"25",
"\u2157":"35",
"\u2158":"45",
"\u2159":"16",
"\u215a":"56",
"\u215b":"18",
"\u215c":"38",
"\u215d":"58",
"\u215e":"78",
"\u215f":"1",
"\u2160":"i",
"\u2161":"ii",
"\u2162":"iii",
"\u2163":"iv",
"\u2164":"v",
"\u2165":"vi",
"\u2166":"vii",
"\u2167":"viii",
"\u2168":"ix",
"\u2169":"x",
"\u216a":"xi",
"\u216b":"xii",
"\u216c":"l",
"\u216d":"c",
"\u216e":"d",
"\u216f":"m",
"\u2170":"i",
"\u2171":"ii",
"\u2172":"iii",
"\u2173":"iv",
"\u2174":"v",
"\u2175":"vi",
"\u2176":"vii",
"\u2177":"viii",
"\u2178":"ix",
"\u2179":"x",
"\u217a":"xi",
"\u217b":"xii",
"\u217c":"l",
"\u217d":"c",
"\u217e":"d",
"\u217f":"m",
"\u2180":"d",
"\u2181":"d",
"\u21b7":"v",
"\u21ba":"v",
"\u2573":"x",
"\u25bc":"v",
"\u25bd":"v",
"\u25be":"v",
"\u25bf":"v",
"\u25ef":"o",
"\u25f4":"o",
"\u25f5":"o",
"\u25f6":"o",
"\u25f7":"o",
"\u3041":"a",
"\u3042":"a",
"\u3043":"i",
"\u3044":"i",
"\u3045":"u",
"\u3046":"u",
"\u3047":"e",
"\u3048":"e",
"\u3049":"o",
"\u304a":"o",
"\u304b":"ka",
"\u304c":"ga",
"\u304d":"ki",
"\u304e":"gi",
"\u304f":"ku",
"\u3050":"gu",
"\u3051":"ke",
"\u3052":"ge",
"\u3053":"ko",
"\u3054":"go",
"\u3055":"sa",
"\u3056":"za",
"\u3057":"si",
"\u3058":"zi",
"\u3059":"su",
"\u305a":"zu",
"\u305b":"se",
"\u305c":"ze",
"\u305d":"so",
"\u305e":"zo",
"\u305f":"ta",
"\u3060":"da",
"\u3061":"ti",
"\u3062":"di",
"\u3063":"tu",
"\u3064":"tu",
"\u3065":"du",
"\u3066":"te",
"\u3067":"de",
"\u3068":"to",
"\u3069":"do",
"\u306a":"na",
"\u306b":"ni",
"\u306c":"nu",
"\u306d":"ne",
"\u306e":"no",
"\u306f":"ha",
"\u3070":"ba",
"\u3071":"pa",
"\u3072":"hi",
"\u3073":"bi",
"\u3074":"pi",
"\u3075":"hu",
"\u3076":"bu",
"\u3077":"pu",
"\u3078":"he",
"\u3079":"be",
"\u307a":"pe",
"\u307b":"ho",
"\u307c":"bo",
"\u307d":"po",
"\u307e":"ma",
"\u307f":"mi",
"\u3080":"mu",
"\u3081":"me",
"\u3082":"mo",
"\u3083":"ya",
"\u3084":"ya",
"\u3085":"yu",
"\u3086":"yu",
"\u3087":"yo",
"\u3088":"yo",
"\u3089":"ra",
"\u308a":"ri",
"\u308b":"ru",
"\u308c":"re",
"\u308d":"ro",
"\u308e":"wa",
"\u308f":"wa",
"\u3090":"wi",
"\u3091":"we",
"\u3092":"wo",
"\u3093":"n",
"\u3094":"vu",
"\u30a1":"a",
"\u30a2":"a",
"\u30a3":"i",
"\u30a4":"i",
"\u30a5":"u",
"\u30a6":"u",
"\u30a7":"e",
"\u30a8":"e",
"\u30a9":"o",
"\u30aa":"o",
"\u30ab":"ka",
"\u30ac":"ga",
"\u30ad":"ki",
"\u30ae":"gi",
"\u30af":"ku",
"\u30b0":"gu",
"\u30b1":"ke",
"\u30b2":"ge",
"\u30b3":"ko",
"\u30b4":"go",
"\u30b5":"sa",
"\u30b6":"za",
"\u30b7":"si",
"\u30b8":"zi",
"\u30b9":"su",
"\u30ba":"zu",
"\u30bb":"se",
"\u30bc":"ze",
"\u30bd":"so",
"\u30be":"zo",
"\u30bf":"ta",
"\u30c0":"da",
"\u30c1":"ti",
"\u30c2":"di",
"\u30c3":"tu",
"\u30c4":"tu",
"\u30c5":"du",
"\u30c6":"te",
"\u30c7":"de",
"\u30c8":"to",
"\u30c9":"do",
"\u30ca":"na",
"\u30cb":"ni",
"\u30cc":"nu",
"\u30cd":"ne",
"\u30ce":"no",
"\u30cf":"ha",
"\u30d0":"ba",
"\u30d1":"pa",
"\u30d2":"hi",
"\u30d3":"bi",
"\u30d4":"pi",
"\u30d5":"hu",
"\u30d6":"bu",
"\u30d7":"pu",
"\u30d8":"he",
"\u30d9":"be",
"\u30da":"pe",
"\u30db":"ho",
"\u30dc":"bo",
"\u30dd":"po",
"\u30de":"ma",
"\u30df":"mi",
"\u30e0":"mu",
"\u30e1":"me",
"\u30e2":"mo",
"\u30e3":"ya",
"\u30e4":"ya",
"\u30e5":"yu",
"\u30e6":"yu",
"\u30e7":"yo",
"\u30e8":"yo",
"\u30e9":"ra",
"\u30ea":"ri",
"\u30eb":"ru",
"\u30ec":"re",
"\u30ed":"ro",
"\u30ee":"wa",
"\u30ef":"wa",
"\u30f0":"wi",
"\u30f1":"we",
"\u30f2":"wo",
"\u30f3":"n",
"\u30f4":"vu",
"\u30f5":"ka",
"\u30f6":"ke",
"\u30f7":"va",
"\u30f8":"vi",
"\u30f9":"ve",
"\u30fa":"vo",
"\u30ff":"koto",
"\u3105":"b",
"\u3106":"p",
"\u3107":"m",
"\u3108":"f",
"\u3109":"d",
"\u310a":"t",
"\u310b":"n",
"\u310c":"l",
"\u310d":"g",
"\u310e":"k",
"\u310f":"h",
"\u3110":"j",
"\u3111":"q",
"\u3112":"x",
"\u3113":"zh",
"\u3114":"ch",
"\u3115":"sh",
"\u3116":"r",
"\u3117":"z",
"\u3118":"c",
"\u3119":"s",
"\u311a":"a",
"\u311b":"o",
"\u311c":"e",
"\u311d":"eh",
"\u311e":"ai",
"\u311f":"ei",
"\u3120":"au",
"\u3121":"ou",
"\u3122":"an",
"\u3123":"en",
"\u3124":"ang",
"\u3125":"eng",
"\u3126":"er",
"\u3127":"i",
"\u3128":"u",
"\u3129":"iu",
"\u312a":"v",
"\u312b":"ng",
"\u312c":"gn",
"\u31a0":"bu",
"\u31a1":"zi",
"\u31a2":"ji",
"\u31a3":"gu",
"\u31a4":"ee",
"\u31a5":"enn",
"\u31a6":"oo",
"\u31a7":"onn",
"\u31a8":"ir",
"\u31a9":"ann",
"\u31aa":"inn",
"\u31ab":"unn",
"\u31ac":"im",
"\u31ad":"ngg",
"\u31ae":"ainn",
"\u31af":"aunn",
"\u31b0":"am",
"\u31b1":"om",
"\u31b2":"ong",
"\u31b3":"innn",
"\u31b4":"p",
"\u31b5":"t",
"\u31b6":"k",
"\u31b7":"h",
"\ufb00":"ff",
"\ufb01":"fi",
"\ufb02":"fl",
"\ufb03":"ffi",
"\ufb04":"ffl",
"\ufb05":"st",
"\ufb06":"st",
"\ufb13":"mn",
"\ufb14":"me",
"\ufb15":"mi",
"\ufb16":"vn",
"\ufb17":"mkh",
"\ufb1d":"yi",
"\ufb1f":"ay",
"\ufb22":"d",
"\ufb23":"h",
"\ufb24":"k",
"\ufb25":"l",
"\ufb26":"m",
"\ufb27":"m",
"\ufb28":"t",
"\ufb2a":"sh",
"\ufb2b":"s",
"\ufb2c":"sh",
"\ufb2d":"s",
"\ufb2e":"a",
"\ufb2f":"a",
"\ufb31":"b",
"\ufb32":"g",
"\ufb33":"d",
"\ufb34":"h",
"\ufb35":"v",
"\ufb36":"z",
"\ufb38":"t",
"\ufb39":"y",
"\ufb3a":"k",
"\ufb3b":"k",
"\ufb3c":"l",
"\ufb3e":"l",
"\ufb40":"n",
"\ufb41":"n",
"\ufb43":"p",
"\ufb44":"p",
"\ufb46":"ts",
"\ufb47":"ts",
"\ufb48":"r",
"\ufb49":"sh",
"\ufb4a":"t",
"\ufb4b":"vo",
"\ufb4c":"b",
"\ufb4d":"k",
"\ufb4e":"p",
"\ufb4f":"l",
"\ufdf0":"salla",
"\ufdf1":"qala",
"\ufdf2":"allah",
"\ufdf3":"akbar",
"\ufdf4":"mohammed",
"\ufdf5":"slm",
"\ufdf6":"rasul",
"\ufdf7":"alayhi",
"\ufdf8":"wasallam",
"\ufdf9":"salla",
"\ufdfa":"sallallahualayhiwasallam",
"\ufdfb":"jallajalalahu",
"\ufdfc":"rial",
"\ufdfd":"bismillaharrahmanarrahimi",
"\uff10":"0",
"\uff11":"1",
"\uff12":"2",
"\uff13":"3",
"\uff14":"4",
"\uff15":"5",
"\uff16":"6",
"\uff17":"7",
"\uff18":"8",
"\uff19":"9",
"\uff21":"a",
"\uff22":"b",
"\uff23":"c",
"\uff24":"d",
"\uff25":"e",
"\uff26":"f",
"\uff27":"g",
"\uff28":"h",
"\uff29":"i",
"\uff2a":"j",
"\uff2b":"k",
"\uff2c":"l",
"\uff2d":"m",
"\uff2e":"n",
"\uff2f":"o",
"\uff30":"p",
"\uff31":"q",
"\uff32":"r",
"\uff33":"s",
"\uff34":"t",
"\uff35":"u",
"\uff36":"v",
"\uff37":"w",
"\uff38":"x",
"\uff39":"y",
"\uff3a":"z",
"\uff41":"a",
"\uff42":"b",
"\uff43":"c",
"\uff44":"d",
"\uff45":"e",
"\uff46":"f",
"\uff47":"g",
"\uff48":"h",
"\uff49":"i",
"\uff4a":"j",
"\uff4b":"k",
"\uff4c":"l",
"\uff4d":"m",
"\uff4e":"n",
"\uff4f":"o",
"\uff50":"p",
"\uff51":"q",
"\uff52":"r",
"\uff53":"s",
"\uff54":"t",
"\uff55":"u",
"\uff56":"v",
"\uff57":"w",
"\uff58":"x",
"\uff59":"y",
"\uff5a":"z",
"\uff66":"wo",
"\uff67":"a",
"\uff68":"i",
"\uff69":"u",
"\uff6a":"e",
"\uff6b":"o",
"\uff6c":"ya",
"\uff6d":"yu",
"\uff6e":"yo",
"\uff6f":"tu",
"\uff71":"a",
"\uff72":"i",
"\uff73":"u",
"\uff74":"e",
"\uff75":"o",
"\uff76":"ka",
"\uff77":"ki",
"\uff78":"ku",
"\uff79":"ke",
"\uff7a":"ko",
"\uff7b":"sa",
"\uff7c":"si",
"\uff7d":"su",
"\uff7e":"se",
"\uff7f":"so",
"\uff80":"ta",
"\uff81":"ti",
"\uff82":"tu",
"\uff83":"te",
"\uff84":"to",
"\uff85":"na",
"\uff86":"ni",
"\uff87":"nu",
"\uff88":"ne",
"\uff89":"no",
"\uff8a":"ha",
"\uff8b":"hi",
"\uff8c":"hu",
"\uff8d":"he",
"\uff8e":"ho",
"\uff8f":"ma",
"\uff90":"mi",
"\uff91":"mu",
"\uff92":"me",
"\uff93":"mo",
"\uff94":"ya",
"\uff95":"yu",
"\uff96":"yo",
"\uff97":"ra",
"\uff98":"ri",
"\uff99":"ru",
"\uff9a":"re",
"\uff9b":"ro",
"\uff9c":"wa",
"\uff9d":"n",
"\uffa1":"g",
"\uffa2":"gg",
"\uffa3":"gs",
"\uffa4":"n",
"\uffa5":"nj",
"\uffa6":"nh",
"\uffa7":"d",
"\uffa8":"dd",
"\uffa9":"r",
"\uffaa":"lg",
"\uffab":"lm",
"\uffac":"lb",
"\uffad":"ls",
"\uffae":"lt",
"\uffaf":"lp",
"\uffb0":"rh",
"\uffb1":"m",
"\uffb2":"b",
"\uffb3":"bb",
"\uffb4":"bs",
"\uffb5":"s",
"\uffb6":"ss",
"\uffb8":"j",
"\uffb9":"jj",
"\uffba":"c",
"\uffbb":"k",
"\uffbc":"t",
"\uffbd":"p",
"\uffbe":"h",
"\uffc2":"a",
"\uffc3":"ae",
"\uffc4":"ya",
"\uffc5":"yae",
"\uffc6":"eo",
"\uffc7":"e",
"\uffca":"yeo",
"\uffcb":"ye",
"\uffcc":"o",
"\uffcd":"wa",
"\uffce":"wae",
"\uffcf":"oe",
"\uffd2":"yo",
"\uffd3":"u",
"\uffd4":"weo",
"\uffd5":"we",
"\uffd6":"wi",
"\uffd7":"yu",
"\uffda":"eu",
"\uffdb":"yi",
"\uffdc":"i",
"\uffe0":"c",
"\uffe1":"lb",
"\uffe5":"yen",
"\uffe6":"w",
"\uffee":"o",
"\u00ac":"not",
"\u2022":"o",
"\u2100":"ac",
"\u2101":"as",
"\u2102":"c",
"\u2103":"0c",
"\u2105":"co",
"\u2106":"cu",
"\u2109":"0f",
"\u210a":"g",
"\u210b":"h",
"\u210c":"h",
"\u210d":"h",
"\u210e":"h",
"\u210f":"h",
"\u2110":"i",
"\u2111":"i",
"\u2112":"l",
"\u2113":"l",
"\u2115":"n",
"\u2116":"no",
"\u2119":"p",
"\u211a":"q",
"\u211b":"r",
"\u211c":"r",
"\u211d":"r",
"\u2121":"tel",
"\u2124":"z",
"\u2126":"ohm",
"\u2128":"z",
"\u212c":"b",
"\u212d":"c",
"\u212e":"e",
"\u212f":"e",
"\u2130":"e",
"\u2131":"f",
"\u2133":"m",
"\u2134":"o",
"\u2139":"i",
"\u213b":"fax",
"\u2145":"d",
"\u2146":"d",
"\u2147":"e",
"\u2148":"i",
"\u2149":"j",
"\u2193":"v",
"\u2219":"o",
"\u2400":"nul",
"\u2401":"soh",
"\u2402":"stx",
"\u2403":"etx",
"\u2404":"eot",
"\u2405":"enq",
"\u2406":"ack",
"\u2407":"bel",
"\u2408":"bs",
"\u2409":"ht",
"\u240a":"lf",
"\u240b":"vt",
"\u240c":"ff",
"\u240d":"cr",
"\u240e":"so",
"\u240f":"si",
"\u2410":"dle",
"\u2411":"dc1",
"\u2412":"dc2",
"\u2413":"dc3",
"\u2414":"dc4",
"\u2415":"nak",
"\u2416":"syn",
"\u2417":"etb",
"\u2418":"can",
"\u2419":"em",
"\u241a":"sub",
"\u241b":"esc",
"\u241c":"fs",
"\u241d":"gs",
"\u241e":"rs",
"\u241f":"us",
"\u2420":"sp",
"\u2421":"del",
"\u2424":"nl",
"\u2460":"1",
"\u2461":"2",
"\u2462":"3",
"\u2463":"4",
"\u2464":"5",
"\u2465":"6",
"\u2466":"7",
"\u2467":"8",
"\u2468":"9",
"\u2469":"10",
"\u246a":"11",
"\u246b":"12",
"\u246c":"13",
"\u246d":"14",
"\u246e":"15",
"\u246f":"16",
"\u2470":"17",
"\u2471":"18",
"\u2472":"19",
"\u2473":"20",
"\u2474":"1",
"\u2475":"2",
"\u2476":"3",
"\u2477":"4",
"\u2478":"5",
"\u2479":"6",
"\u247a":"7",
"\u247b":"8",
"\u247c":"9",
"\u247d":"10",
"\u247e":"11",
"\u247f":"12",
"\u2480":"13",
"\u2481":"14",
"\u2482":"15",
"\u2483":"16",
"\u2484":"17",
"\u2485":"18",
"\u2486":"19",
"\u2487":"20",
"\u2488":"1",
"\u2489":"2",
"\u248a":"3",
"\u248b":"4",
"\u248c":"5",
"\u248d":"6",
"\u248e":"7",
"\u248f":"8",
"\u2490":"9",
"\u2491":"10",
"\u2492":"11",
"\u2493":"12",
"\u2494":"13",
"\u2495":"14",
"\u2496":"15",
"\u2497":"16",
"\u2498":"17",
"\u2499":"18",
"\u249a":"19",
"\u249b":"20",
"\u249c":"a",
"\u249d":"b",
"\u249e":"c",
"\u249f":"d",
"\u24a0":"e",
"\u24a1":"f",
"\u24a2":"g",
"\u24a3":"h",
"\u24a4":"i",
"\u24a5":"j",
"\u24a6":"k",
"\u24a7":"l",
"\u24a8":"m",
"\u24a9":"n",
"\u24aa":"o",
"\u24ab":"p",
"\u24ac":"q",
"\u24ad":"r",
"\u24ae":"s",
"\u24af":"t",
"\u24b0":"u",
"\u24b1":"v",
"\u24b2":"w",
"\u24b3":"x",
"\u24b4":"y",
"\u24b5":"z",
"\u24b6":"a",
"\u24b7":"b",
"\u24b8":"c",
"\u24b9":"d",
"\u24ba":"e",
"\u24bb":"f",
"\u24bc":"g",
"\u24bd":"h",
"\u24be":"i",
"\u24bf":"j",
"\u24c0":"k",
"\u24c1":"l",
"\u24c2":"m",
"\u24c3":"n",
"\u24c4":"o",
"\u24c5":"p",
"\u24c6":"q",
"\u24c7":"r",
"\u24c8":"s",
"\u24c9":"t",
"\u24ca":"u",
"\u24cb":"v",
"\u24cc":"w",
"\u24cd":"x",
"\u24ce":"y",
"\u24cf":"z",
"\u24d0":"a",
"\u24d1":"b",
"\u24d2":"c",
"\u24d3":"d",
"\u24d4":"e",
"\u24d5":"f",
"\u24d6":"g",
"\u24d7":"h",
"\u24d8":"i",
"\u24d9":"j",
"\u24da":"k",
"\u24db":"l",
"\u24dc":"m",
"\u24dd":"n",
"\u24de":"o",
"\u24df":"p",
"\u24e0":"q",
"\u24e1":"r",
"\u24e2":"s",
"\u24e3":"t",
"\u24e4":"u",
"\u24e5":"v",
"\u24e6":"w",
"\u24e7":"x",
"\u24e8":"y",
"\u24e9":"z",
"\u24ea":"0",
"\u25e6":"o",
"\uffe2":"not",
"\uffec":"v"
};
});

