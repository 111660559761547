(() => {

	const Sim = window.Sim || (window.Sim = {});

	const key = 'Sim.observeAddedNodes';

	const triggerForObserver = (list, filters, recordType) => {
		if (filters.length > 0)
		{
			const els = [];
			for (const record of list)
			{
				els.push(...record[recordType]);
			}
			if (els.length > 0)
			{
				const $els = $(els);
				for (const {filter, callback} of filters)
				{
					callback(filter($els));
				}
			}
		}
	};

	const createHandle = (target) => {
		const addedNodesFilters = [];
		const removedNodesFilters = [];
		const observer = new window.MutationObserver((list) => {
			triggerForObserver(list, addedNodesFilters, 'addedNodes');
			triggerForObserver(list, removedNodesFilters, 'removedNodes');
		});
		observer.observe(target, {subtree: true, childList: true});
		return {
			addedNodes: (def) => addedNodesFilters.push(def),
			removedNodes: (def) => removedNodesFilters.push(def),
		};
	};

	const createFilter = (selectorOrFilter) => {
		if (_.isFunction(selectorOrFilter))
		{
			return ($els) => [].filter.call($els, selectorOrFilter);
		}
		return ($els) => $els.filter(selectorOrFilter).add($els.find(selectorOrFilter));
	};

	const createCallback = (cbEach, cbAll) => {
		const each = (els) => {
			for (const el of els || [])
			{
				cbEach($(el));
			}
		};
		const all = (els) => {
			if (els && els.length > 0)
			{
				cbAll($(els));
			}
		};
		if (!cbEach) return all;
		else if (!cbAll) return each;
		return ($els) => {
			all($els);
			each($els);
		};
	};

	/**
	 * Trigger `callbackEach` for every element (or `callbackAll` for all elements) matching `selector` now or when they are added to/removed from `target`.
	 * @param {($|Element|string)} target single root element which to observe
	 * @param {(string|function($):($|Element[]|Element|null))} selector or filter, rather make it simple, and filter further later
	 */
	Sim.observeNodes = (target, selector, {
		addedNodes = false, // {Boolean} trigger for added nodes
		removedNodes = false, // {Boolean} trigger for removed nodes
		callbackEach = null, // {function($):void} callback for every element
		callbackAll = null, // {function($):void} callback for all elements
	}) => {
		const $target = $(target);
		if ($target.length !== 1) throw new Error;
		target = $target[0];
		if (!(key in target))
		{
			target[key] = createHandle(target);
		}
		const filter = createFilter(selector);
		const callback = createCallback(callbackEach, callbackAll);
		if (!addedNodes && !removedNodes) throw new Error;
		if (addedNodes)
		{
			target[key].addedNodes({filter, callback});
			callback(filter($target));
		}
		if (removedNodes)
		{
			target[key].removedNodes({filter, callback});
		}
	};

})();
