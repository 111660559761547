
define(['SimpleEvent', 'Nette'], (SimpleEvent, Nette) => {

	var FormRepeater = function (rootId, formName, containerName) {
		new SimpleEvent(this, 'changed');
		this.$root = $(document.getElementById(rootId)).filter('[data-repeater=""][id^="frm"]');
		if (this.$root.length !== 1) throw new Error;
		if (this.$root.data('repeater')) throw new Error;
		this.$root.data('repeater', this);
		this.templateHtml = this.findRepeater(this.$root, 'template').detach().html();
		this.$target = this.findRepeater(this.$root, 'target');
		this.initRowsIndex(formName, containerName);
		this.registerEvents();
		this.createSortable();
		setTimeout(this.changed.trigger);
	};

	FormRepeater.prototype.findRepeater = function (root, what) {
		if (_.isElement(what) || what instanceof $)
		{
			what = $(what);
		}
		else
		{
			what = root.find('[data-repeater="' + what + '"]');
		}
		return what.filter(function () {
			return $(this).parent().closest('[data-repeater]').is(root);
		});
	};

	FormRepeater.prototype.registerEvents = function () {
		var self = this;
		this.$root.on('click', '[data-repeater="add"]', function (e) {
			if (self.findRepeater(self.$root, this).length === 0) return;
			self.add();
			e.preventDefault();
		});
		this.$target.on('click', '[data-repeater-row] [data-repeater="remove"]', function (e) {
			if (e.isDefaultPrevented()) return;
			if (self.findRepeater(self.$target, this).length === 0) return;
			self.remove(this);
			e.preventDefault();
		});

		this.$target.on('change', 'input,select', (event) => {
			this.changed.trigger(event);
		});
		this.$target.on('input', 'input[type="text"]', (event) => {
			this.changed.trigger(event);
		});
		this.changed.on((...args) => {
			this.$root.parents('[data-repeater=""]').each(function () {
				$(this).data('repeater').changed.trigger(...args);
			});
		});
		this.changed.onNow(function () {
			var toggles;
			this.$root.find('[data-repeater-toggle]').each(function (i, el) {
				var $el = $(el);
				var toggle = $el.attr('data-repeater-toggle');
				if (toggles === undefined)
				{
					var rows = this.getRows().length;
					toggles = {
						'empty': rows === 0,
						'non-empty': rows > 0,
					};
				}
				if (typeof toggles[toggle] !== 'boolean') throw new Error('FormRepeater: invalid data-repeater-toggle ' + toggle);
				$el.toggleClass('repeater-toggled', toggles[toggle]);
			}.bind(this));
		}, this);

		this.changed.onNow((event) => {
			Nette.toggleForm(
				this.$root.closest('form').get(0),
				(event instanceof $.Event || event instanceof window.Event) ? event : undefined
			);
		});
	};

	FormRepeater.prototype.preparePrefixes = function (formName, containerName) {
		formName.replace(/[^\w]/g, function () { throw new Error; });
		containerName.replace(/[^\w-]/g, function () { throw new Error; });
		var htmlId = this.$root.attr('id');
		var htmlName = containerName.indexOf('-') !== -1 ? containerName.replace(/-/g, '][').replace(']', '') + ']' : containerName; // Nette\Forms\Controls\BaseControl::getHtmlName
		var htmlIdRe = Sim.escapeRegex(htmlId);
		var htmlNameRe = Sim.escapeRegex(htmlName);
		var templateIdRe = Sim.escapeRegex('_'); // Sim\FormRepeaterContainer::TEMPLATE_ID
		return {
			createInputHtmlName: function (index, name) {
				return htmlName + '[' + parseInt(index, 10) + '][' + name.replace(/-/g, '][') + ']';
			},
			inputsSelector: '[name^="' + $.escapeSelector(htmlName + '[') + '"], [id^="' + $.escapeSelector(htmlId + '-') + '"]',
			matchIndexRegexps: {
				name: new RegExp('^' + htmlNameRe + '\\[(\\d+)\\]\\['),
				id: new RegExp('^' + htmlIdRe + '-(\\d+)-'),
			},
			fillIndexRegexps: [
				{
					// containerName[TEMPLATE_ID] => containerName[index]
					re: new RegExp('((?:\'|"|^|&quot;|&#39;)' + htmlNameRe + '\\[)' + templateIdRe + '(\\]\\[)', 'g'),
					rep: function (index) { return '$1' + index + '$2'; },
				},
				{
					// frmformName-containerName-TEMPLATE_ID- => frmformName-containerName-index-
					re: new RegExp('((?:\'|"|^|&quot;|&#39;)' + htmlIdRe + '-)' + templateIdRe + '(-)', 'g'),
					rep: function (index) { return '$1' + index + '$2'; },
				},
				{
					// containerName-TEMPLATE_ID- => containerName-index-
					re: new RegExp('(^' + Sim.escapeRegex(containerName) + '-)' + templateIdRe + '(-)', 'g'),
					rep: function (index) { return '$1' + index + '$2'; },
				},
			],
		};
	};

	FormRepeater.prototype.initRowsIndex = function (formName, containerName) {
		var prefix = this.preparePrefixes(formName, containerName);
		this.prefix = {
			createInputHtmlName: prefix.createInputHtmlName,
			fillIndexRegexps: prefix.fillIndexRegexps,
		};
		if (this.$target.length !== 1) throw new Error;
		var all = this.$target.find('> *');
		var indexes = [all.length - 1];
		var notFounds = [];
		_.each(all, function (el) {
			var $el = $(el);
			if ($el.attr('data-repeater-row') !== undefined) throw new Error;
			var foundIndex;
			_.each($el.find(prefix.inputsSelector), function (el) {
				_.each(prefix.matchIndexRegexps, function (re, key) {
					var match = re.exec(el[key]);
					if (match)
					{
						var index = parseInt(match[1], 10);
						if (foundIndex === undefined) foundIndex = index;
						else if (foundIndex !== index) throw new Error;
					}
				});
			});
			if (foundIndex !== undefined)
			{
				indexes.push(foundIndex);
				this.appendHelperInput($el, foundIndex);
				$el.attr('data-repeater-row', foundIndex);
			}
			else
			{
				notFounds.push($el);
			}
		}, this);
		this.index = _.max(indexes) + 1;
		_.each(notFounds, function ($el) {
			var index = this.index++;
			this.appendHelperInput($el, index);
			$el.attr('data-repeater-row', index);
		}, this);
	};

	FormRepeater.prototype.appendHelperInput = function ($row, index) {
		// when row has no :input, or all are disabled, or only unchecked :checkbox, then row is not in the POST
		var helperName = '__hiddenFormRepeaterHelper';
		$('<input type="hidden">')
			.attr('name', this.prefix.createInputHtmlName(index, helperName))
			.appendTo($row)
		;
	};

	FormRepeater.prototype.fillIndex = function (html, index) {
		if ((typeof index !== 'number' && typeof index !== 'string') || !/^\d+$/u.test(String(index)))
		{
			throw new Error;
		}
		_.each(this.prefix.fillIndexRegexps, function (p) {
			html = html.replace(p.re, p.rep(index));
		});
		return html;
	};

	FormRepeater.prototype.add = function ($el) {
		if (!$el)
		{
			var html = this.templateHtml;
			var index = this.index++;
			html = this.fillIndex(html, index);
			$el = $('<div>').html(html).children();
			this.appendHelperInput($el, index);
			$el.attr('data-repeater-row', index);
			Sim.init($el);
		}
		if ($el.length !== 1 || !$el.is('[data-repeater-row]')) throw new Error;
		$el.appendTo(this.$target);
		this.changed.trigger();
		return $el;
	};

	FormRepeater.prototype.remove = function (el) {
		var $el = this.findRepeater(this.$target, $(el).closest('[data-repeater="target"] > [data-repeater-row]'));
		$el.detach();
		this.changed.trigger();
		return $el;
	};

	FormRepeater.prototype.getRows = function (el) {
		return this.$target.find('> [data-repeater-row]');
	};

	FormRepeater.prototype.createSortable = function () {
		var templateSort = this.findRepeater($('<div data-repeater>').html(this.templateHtml), 'sort');
		if (!templateSort.length)
		{
			return;
		}
		var self = this;

		this.$target.on({
			'mouseenter': function (e) {
				$(this).closest('[data-repeater-row]').addClass('ui-sortable-placeholder-possible');
			},
			'mouseleave': function (e) {
				$(this).closest('[data-repeater-row]').removeClass('ui-sortable-placeholder-possible');
			},
		}, '[data-repeater="sort"]');

		this.$target.sortable({
			items: '> [data-repeater-row]',
			axis: templateSort.attr('data-repeater-sort-axis') || 'y',
			cursor: 'move',
			distance: 3,
			tolerance: 'pointer',
			cancel: 'input,textarea,button,select,option,.input-append,[data-repeater="remove"]',
			handle: templateSort.is('[data-repeater-sort-handle]') ? '[data-repeater="sort"]' : undefined,
			helper: function () {
				return $('<b style="display: block;">')[0];
			},
			placeholder: {
				element: function (item) {
					item.show().addClass('ui-sortable-placeholder');
					return item;
				},
				update: function () {},
			},
			beforeStop: function (event, ui) {
				ui.item.removeClass('ui-sortable-placeholder');
				self.sortable.placeholder = $('<div>').appendTo(document.body); // aby se smazal tento misto item
				$('body').css('cursor', ''); // jquery ui sortable bug
			},
			update: function () {
				self.changed.trigger();
			},
		});
		this.sortable = this.$target.sortable('instance');
		this.changed.onNow(function () {
			var enable = this.getRows().length > 1;
			this.sortable[enable ? 'enable' : 'disable']();
			this.$target.find('[data-repeater="sort"]').css('visibility', enable ? 'visible' : 'hidden');
		}, this);
	};

	return FormRepeater;
});
