define(function () {

	function SettingLocale (root) {
		this.root = $(root);
		this.language = this.root.find('select[name="language"], select[name="experimentalLanguage"]');
		this.updateDateFormat();
		this.updateNumberFormat();
	}
	SettingLocale.prototype = {

		updateDateFormat: function () {
			if (!this.dateFormat)
			{
				this.exampleDate = new Date('2000-01-02');
				this.dateFormat = this.root.find('select[name="dateFormat"]');
				this.dateFormatDefault = this.dateFormat.find('option[value="default"]');
				this.dateFormatCustom = this.root.find('input[name="dateFormatCustom"]');
				this.dateFormatExample = this.root.find('.dateFormatExample');
				this.dateFormatDefaultText = this.dateFormat.attr('data-default-text');
				this.languageDefaultDateFormats = this.dateFormat.data('language-default-formats');
				this.dateFormatsTransmute = (function () {
					var table = this.dateFormat.data('format-platform-transmutations');
					var re = new RegExp(_(table).keys().map(Sim.escapeRegex).join('|'), 'g');
					return function (input) {
						var valid = '';
						input.replace(re, function (value) {
							valid += table[value] || '';
						}.bind(this));
						return valid;
					};
				}).call(this);
				$().add(this.dateFormat).add(this.dateFormatCustom).add(this.language).on('change keyup', this.updateDateFormat.bind(this));
			}
			var dateFormat = this.dateFormat.val();
			var defaultDateFormat = this.languageDefaultDateFormats[this.getLanguageValue()] || 'yyyy-mm-dd';
			if (dateFormat === 'default')
			{
				dateFormat = defaultDateFormat;
			}
			else if (dateFormat === 'custom')
			{
				dateFormat = this.dateFormatCustom.val();
			}
			this.dateFormatDefault.text(this.dateFormatDefaultText.replace(/%format%/, defaultDateFormat));
			this.dateFormatExample.text($.datepicker.formatDate(this.dateFormatsTransmute(dateFormat), this.exampleDate));
		},

		updateNumberFormat: function () {
			if (!this.numberFormat)
			{
				this.numberFormat = this.root.find('select[name="numberFormat"]');
				this.numberFormatDefault = this.numberFormat.find('option[value="default"]');
				this.numberFormatDefaultText = this.numberFormat.attr('data-default-text');
				this.languageDefaultNumberFormats = this.numberFormat.data('language-default-formats');
				this.language.on('change keyup', this.updateNumberFormat.bind(this));
			}
			var defaultNumberFormat = this.languageDefaultNumberFormats[this.getLanguageValue()] || ['?', '?', ['?', '?']];
			var exampleNumber = Sim.number.createFormatFunction(defaultNumberFormat)(1234567.89);
			this.numberFormatDefault.text(this.numberFormatDefaultText.replace(/%format%/, exampleNumber));
		},

		getLanguageValue: function () {
			var a = this.language.filter('select[name="experimentalLanguage"]');
			var b = this.language.filter('select[name="language"]');
			var av = a.val();
			return ((av && av.split('/')[0]) || b.val());
		},

	};
	return SettingLocale;
});
