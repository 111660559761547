
define(['Helpers/Gzip'], (Gzip) => {

	class PopupNameCompress
	{

		static #LENGTH = 1024;
		static #CHAR = '_';

		static encode(data)
		{
			if (data.charAt(0) === PopupNameCompress.#CHAR)
			{
				throw new Error;
			}
			if (data.length < PopupNameCompress.#LENGTH)
			{
				return data;
			}
			data = Gzip.encode(data, 'base64');
			data = data
				.replaceAll('+', '_2B')
				.replaceAll('/', '_2E')
				.replaceAll('=', '')
			;
			return PopupNameCompress.#CHAR + data;
		}

		static decode(data)
		{
			if (data.charAt(0) !== PopupNameCompress.#CHAR)
			{
				return data;
			}
			data = data.substr(1);
			data = data
				.replaceAll('_2B', '+')
				.replaceAll('_2E', '/')
				.replaceAll('_3D', '=') // bc
			;
			data = Gzip.decode(data, 'base64');
			return data;
		}

	}

	return PopupNameCompress;
});
