define(['libs/bezier-easing/index'], (bezier) => {

	class TableSelectorLocalRendererAnimation
	{

		constructor(localRenderer, property, container, table)
		{
			this.property = property;
			this.localRenderer = localRenderer;
			this.localRenderer[this.property] = this;
			this.rows = localRenderer.rows;
			this.paginator = localRenderer.paginator;
			this.rowConstantSize = localRenderer.rowConstantSize;
			this.container = container;
			this.table = table;

			this.finished = false;
			this.start = 0;
			this.offset = 0;
			this.target = 0;
			this.needDOMUpdate = false;

			this.step = this.step.bind(this);
			requestAnimationFrame(this.begin.bind(this));
		}

		begin(timestamp)
		{
			this.container.addClass('animate');
			this.startTimestamp = timestamp;
			this.useNativeAnimate = ('animate' in this.table[0] && this.paginator.current.offsetPixels === undefined);
			this.step(timestamp);
		}

		finish()
		{
			if (this.finished === true) throw new Error;
			this.rows.flushDeletedToDom();
			this.container.removeClass('animate');
			this.table.css('top', '');
			this.finished = true;
			this.localRenderer[this.property] = undefined;
		}

		changed()
		{
			if (this.finished === true) throw new Error;
			this.needDOMUpdate = true;
			if (this.useNativeAnimate === true)
			{
				requestAnimationFrame(this.step);
			}
		}

		updateDOM()
		{
			if (this.needDOMUpdate === false)
			{
				return;
			}
			let topAddCount = 0;
			let animationPosition = undefined;
			this.rows.flushAddedToDom((position) => {
				if (animationPosition === undefined)
				{
					animationPosition = Math.ceil(this.rows.getFirstDomPosition() - (this.offset / this.rowConstantSize));
				}
				if (position < animationPosition)
				{
					topAddCount++;
				}
			});
			const change = topAddCount * -this.rowConstantSize;
			this.start += change;
			this.offset += change;

			let topRemoveCount = 0;
			for (const position of this.rows.iterateToBeDeletedPositions())
			{
				if (position < this.paginator.current.start)
				{
					topRemoveCount++;
				}
			}
			this.target = topRemoveCount * -this.rowConstantSize;

			this.needDOMUpdate = false;
		}

		pixelOffset()
		{
			this.offset = this.start + this.paginator.current.offsetPixels;
			return this.offset;
		}

		customOffset(timestamp)
		{
			const direction = this.offset <= this.target;
			if (this.lastTimestamp !== undefined)
			{
				const totalSize = Math.abs(this.target - this.offset);
				const speed = Math.max(0.25, totalSize / 500);
				const step = (timestamp - this.lastTimestamp) * speed;
				this.offset += direction ? step : -step;
			}
			if (direction ? this.offset >= this.target : this.offset <= this.target)
			{
				return null;
			}
			return this.offset;
		}

		step(timestamp)
		{
			if (this.finished === true) throw new Error;
			if (this.useNativeAnimate === true)
			{
				this.native(timestamp);
			}
			else
			{
				this.custom(timestamp);
			}
			this.lastTimestamp = timestamp;
		}

		custom(timestamp)
		{
			this.updateDOM();
			const pixelScrolling = this.paginator.current.offsetPixels !== undefined;
			const offset = pixelScrolling ? this.pixelOffset() : this.customOffset(timestamp);
			if (offset === null)
			{
				this.finish();
			}
			else
			{
				this.table.css('top', `${offset}px`);
				requestAnimationFrame(this.step);
			}
		}

		native(timestamp)
		{
			const totalDuration = 1000;
			const extendDuration = 750;
			const easing = 'cubic-bezier(0, 0, 0.30, 1)';
			if (TableSelectorLocalRendererAnimation.easing === undefined)
			{
				TableSelectorLocalRendererAnimation.easing = _.memoize(bezier(0, 0, 0.30, 1));
			}
			const prevAnim = this.prevNativeAnimation;
			let duration = totalDuration;
			if (prevAnim)
			{
				const sinceStart = timestamp - this.startTimestamp;
				'commitStyles' in prevAnim && prevAnim.commitStyles();
				this.offset = $.css(this.table[0], 'top', true) || 0;
				duration = sinceStart > (totalDuration - extendDuration) ? extendDuration : totalDuration;
				prevAnim.cancel();
			}
			this.updateDOM();
			this.table.css('top', `${this.offset}px`);
			const easingKeyframeOffset = TableSelectorLocalRendererAnimation.easing(Math.max(Math.min(1 - (duration / totalDuration), 1), 0));
			const keyframes = [
				...[{top: `${((this.offset - this.target) / (1 - easingKeyframeOffset)) + this.target}px`, offset: 0}], // keyframes offset is after easing is applied, but it is not documented, so rather not rely on it and calculate value to 0
				...[{top: `${this.target}px`, offset: 1}],
			];
			const options = {
				duration: totalDuration,
				easing,
				delay: duration - totalDuration, // negative which works like iterationStart but correctly affect easing
			};
			const animation = this.table[0].animate(keyframes, options);
			animation.onfinish = () => !this.finished && !this.needDOMUpdate && this.finish();
			this.prevNativeAnimation = animation;
		}

	}

	return TableSelectorLocalRendererAnimation;
});
