(() => {

	const Sim = window.Sim || (window.Sim = {});

	/**
	 * <code>Sim.redefineFunction(object, 'method', function (old) { old(); });</code>
	 *
	 * Original arguments and `this` are passed to `old()` automatically. The return from the last call of `old()` is returned back automatically too.
	 * <code>Sim.redefineFunction(object, 'method', function (old, value1, value2) { old(); old(); });</code>
	 *
	 * All arguments passed to `old()` can be overwritten at once.
	 * <code>Sim.redefineFunction(object, 'method', function (old, value1, value2) { old([value2, value1]); });</code>
	 *
	 * `this` passed to `old()` can be changed.
	 * <code>Sim.redefineFunction(object, 'method', function (old, value1, value2) { old(null, newThis); });</code>
	 *
	 * It is possible to overwrite only arguments at exact position. The rest is passed unchanged. The position is one-based.
	 * <code>Sim.redefineFunction(object, 'method', function (old, value1, value2) { return old({2: value2}); });</code>
	 *
	 * The return can be overwritten by returning non-undefined, instead of automatically returning return from the last call of `old()`.
	 * <code>Sim.redefineFunction(object, 'method', function (old) { old(); return null; });</code>
	 *
	 * @param {Object}
	 * @param {string}
	 * @param {function(function((null|Array.<*>|Object.<number, *>), (null|Object)): *, ...*): *}
	 */
	Sim.redefineFunction = (object, methodName, newFunc) => {
		object[methodName] = Sim.extendFunction(object[methodName], newFunc);
	};

	Sim.redefineFunction.ifPresent = (object, methodName, newFunc) => {
		if (!object || !(methodName in object)) return;
		Sim.redefineFunction(object, methodName, newFunc);
	};

	Sim.extendFunction = (oldFunc, newFunc) => function (...originalArguments) {
		const old = (changeArguments = null, changeThis = null, ...rest) => {
			let args;
			if (changeArguments === null)
			{
				args = originalArguments;
			}
			else if (_.isArray(changeArguments))
			{
				args = changeArguments;
			}
			else if (typeof changeArguments === 'object')
			{
				args = originalArguments.slice();
				for (const [number, value] of Object.entries(changeArguments))
				{
					const index = number - 1;
					if (!Object.hasOwn(args, index))
					{
						throw new Error(`Sim.redefineFunction: argument number ${number} does not exist in original arguments. Can't be overwritten. Argument number is one-based.`);
					}
					args[index] = value;
				}
			}
			else
			{
				throw new Error(`Sim.redefineFunction: invalid argument ${typeof changeArguments}`);
			}
			if (rest.length !== 0)
			{
				throw new Error('Sim.redefineFunction: unexpected argument');
			}
			return old.originalReturn = oldFunc.apply(changeThis ?? old.originalThis, args);
		};
		old.originalThis = this;
		old.originalReturn = undefined;
		if (typeof oldFunc !== 'function')
		{
			throw new Error('Sim.redefineFunction: not a function');
		}
		const newReturn = newFunc.call(this, old, ...originalArguments);
		return newReturn === undefined ? old.originalReturn : newReturn;
	};

})();
