
jQuery.migrateMute = false;
jQuery.migrateTrace = false;

jQuery.migrateDisablePatchesCall = (patches, fn) => {
	patches = (Array.isArray(patches) ? patches : [patches]).filter((patch) => jQuery.migrateIsPatchEnabled(patch));
	jQuery.migrateDisablePatches(...patches);
	try { return fn(); }
	finally { jQuery.migrateEnablePatches(...patches); }
};

(function (console) {

	const nonStrictPatches = new Set;
	const isStrictPatch = (message) => {
		const prefix = 'JQMIGRATE: ';
		if (typeof message === 'string' && message.startsWith(prefix))
		{
			for (const patch of nonStrictPatches)
			{
				if (jQuery.migrateWarnings.includes(`${message.substr(prefix.length)} [${patch}]`))
				{
					return false;
				}
			}
			return true;
		}
		return false;
	};
	jQuery.migrateMakePatchesNonStrict = (...patches) => {
		for (const patch of patches) nonStrictPatches.add(patch);
	};
	jQuery.migrateMakePatchesNonStrictCall = (patches, fn) => {
		patches = (Array.isArray(patches) ? patches : [patches]).filter((patch) => !nonStrictPatches.has(patch));
		jQuery.migrateMakePatchesNonStrict(...patches);
		try { return fn(); }
		finally { for (const patch of patches) nonStrictPatches.delete(patch); }
	};

	if (console && 'log' in console && 'warn' in console)
	{
		var log = console.log;
		var warn = console.warn;
		console.log = function (message) {
			if (message === `JQMIGRATE: Migrate is installed with logging active, version ${jQuery.migrateVersion}`)
			{
				console.log = log;
				return;
			}
			return log.apply(this, arguments);
		};
		console.warn = function (message) {
			isStrictPatch(message) && Sim.triggerWarning(message, 'migrateWarn');
			return warn.apply(this, arguments);
		};
	}

	if (!Sim.config.productionMode && Sim.config.debuggerEnabled && 'Proxy' in window)
	{
		Object.setPrototypeOf(jQuery.fn, new Proxy(Object.setPrototypeOf({}, Object.getPrototypeOf(jQuery.fn)), {
			get: function (target, prop, receiver) {
				if (typeof prop === 'string' && /^le[ngih]/i.test(prop))
				{
					var message = 'typo ' + prop + ' > jQuery.fn.length';
					Sim.triggerWarning(message, 'jQuery.fn.init.length');
					console.error(message);
				}
				return target[prop];
			},
		}));
	}

})(window.console);
