(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.escapeRegex = (() => {
		const re = /[.+*?$(){}<>,=!:#^[\]\-|\\/\s]/g; // eslint-disable-line require-unicode-regexp
		const replacer = (ch) => {
			const u = ch.codePointAt(0).toString(16);
			const l = u.length;
			if (l <= 2) return `\\x${u.padStart(2, '0')}`;
			if (l > 4) throw new Error(`escapeRegex: ${ch}`); // can't use `\\u{${u}}` because non-unicode regexp
			return `\\u${u.padStart(4, '0')}`;
		};
		return (s) => (s || '').replace(re, replacer);
	})();

	Sim.escapeHtml = _.escape;

	jQuery.escapeSelector || (jQuery.escapeSelector = function (...args) {
		const re = /([\x00-\x1f\x7f]|^-?\d)|^-$|[^\x00-\x1f\x7f-\uFFFF\w-]/g; // eslint-disable-line no-control-regex, require-unicode-regexp
		const replacer = (ch, asCodePoint) => {
			if (asCodePoint)
			{
				if (ch === '\0') return '\uFFFD';
				return `${ch.slice(0, -1)}\\${ch.charCodeAt(ch.length - 1).toString(16)} `;
			}
			return `\\${ch}`;
		};
		return (jQuery.escapeSelector = (sel) => String(sel).replace(re, replacer))(...args);
	});

	jQuery.escapeSelector();

})();
