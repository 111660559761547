define(() => {

	class PersonSelectDataProvider
	{

		static getSourceData(provider)
		{
			return (this.singleton || (this.singleton = new this)).getSourceData(provider);
		}

		constructor()
		{
			this.data = new Map;
		}

		getSourceData({identifier, link})
		{
			if (this.data.has(identifier))
			{
				return this.data.get(identifier);
			}
			const promise = this.makeRequest(link);
			this.data.set(identifier, promise);
			promise
				.then((data) => this.data.set(identifier, data))
				.catch(() => this.data.delete(identifier))
			;
			return promise;
		}

		makeRequest(link)
		{
			return new Promise((resolve, reject) => {
				$.ajax({
					url: link,
					dataType: 'json',
					method: 'GET',
					success: (data) => {
						resolve(data.data);
					},
					error: (jqXHR, textStatus) => {
						reject(new Error);
					},
				});
			});
		}

	}

	return PersonSelectDataProvider;
});
