define(['Helpers/ElementFinder'], (ElementFinder) => {

	class AuthPassLogin
	{

		constructor(form)
		{
			const $form = new ElementFinder(form);
			const input = $form.findOne('input[type="text"][name="username"]');
			const link = $form.findOne('.forgotPassword');
			const event = () => {
				const user = input.val();
				const href = user ? link.attr('data-href-user').replace('%25user%25', user) : link.attr('data-href-empty');
				link.attr('href', href);
			};
			link.on('click', event);
			input.on('input change keyup', event);
		}

	}

	return AuthPassLogin;
});
