(function () {

	function GlobalState() {
		this.prefix = 'globalstate-';
		this.el = $(document.documentElement);
		this.states = {};
		this.unloadManager = this._createUnloadManager();
		this._isDOMAborted = this._createIsDOMAborted();
		this._isConnectionOffline = this._createIsConnectionOffline();
		this.addRawUnreportedError = RawUnreportedErrorLog.createInit(this);
	}

	GlobalState.prototype = {

		ERROR: 'error',
		FATAL: 'fatal',
		WARN: 'warn',
		INITIALIZING: 'initializing',
		ARRIVING: 'arriving',
		LEAVING: 'leaving',
		ABORTED: 'aborted',
		OFFLINE: 'offline',
		MAINTENANCE: 'maintenance',
		AJAX: 'ajax',

		set: function (state) {
			((this.states[state] ? ++this.states[state] : (this.states[state] = 1)) === 1) && this.change(state);
			return _.once(function () {
				(--this.states[state] === 0) && this.change(state);
			}.bind(this));
		},

		is: function (state) {
			return !!(
				this.states[state] ||
				(state === this.MAINTENANCE && $('body.IS_MAINTENANCE').length) ||
				(state === this.AJAX && $('html[class*="loading"]').length)
			);
		},

		change: function (state) {
			state === this.ERROR || this.el.toggleClass(this.prefix + state, !!this.states[state]);
			this.el.toggleClass(this.prefix + this.ERROR, !!(this.states[this.ERROR] && !this.states[this.FATAL] && !this.states[this.OFFLINE]));
		},

		isConnectionReliable: function () {
			return (!this.unloadManager.is() && !this._isDOMAborted() && !this._isConnectionOffline());
		},

		/**
		 * user navigation is changing,
		 * can be stopped with ESC or different navigation,
		 * can't detect manually clicking abort in browser
		 */
		_createUnloadManager() {
			var windowUnloaded = false;
			var windowUnloading = false;
			var globalState = this;
			var globalStateBack;
			var timeout;
			const returnFunction = {
				is: () => (windowUnloaded || windowUnloading),
				isUnloaded: () => windowUnloaded,
				isUnloading: () => windowUnloading,
			};
			var expectingBeforeUnload = 0;
			window.addEventListener('unload', function () {
				windowUnloaded = true;
				globalState.set(globalState.LEAVING);
			}, {capture: true});
			window.addEventListener('beforeunload', function (e) {
				if (expectingBeforeUnload !== 0) return;
				windowUnloading = true;
				globalStateBack && globalStateBack();
				const defaultPreventedTimeout = setTimeout(() => {
					if (!e.defaultPrevented)
					{
						globalStateBack = globalState.set(globalState.LEAVING);
					}
				}, 0);
				globalStateBack = () => clearTimeout(defaultPreventedTimeout);
				document.addEventListener('click', click);
				document.addEventListener('submit', submit);
				document.addEventListener('keydown', keydown);
				clearTimeout(timeout);
				timeout = setTimeout(userStayed, 15000);
			});
			var isValidAHrefClick = function (event, ignoreForProtocol) {
				if (event.defaultPrevented) return false;
				if (event.which > 1) return false;
				if (event.shiftKey || event.altKey || event.ctrlKey || (event.metaKey && /Mac/i.test(navigator.platform))) return false;
				var target = $(event.target).closest('a[href]')[0];
				if (target === undefined) return false;
				if (Array.prototype.indexOf.call(ignoreForProtocol, target.protocol) !== -1) return false;
				if (
					target.target !== '' &&
					target.target !== '_self' &&
					target.target !== '_parent' &&
					target.target !== '_top' &&
					target.target !== window.name
				)
				{
					return false;
				}
				if ($(target).is('[download]')) return false;
				return true;
			};
			document.addEventListener('click', function (e) {
				if (isValidAHrefClick(e, [':', 'javascript:', 'http:', 'https:', 'file:']))
				{
					returnFunction.expectingBeforeUnload();
				}
			});
			function userStayed()
			{
				windowUnloading = 1;
				globalStateBack();
				document.removeEventListener('click', click);
				document.removeEventListener('submit', submit);
				document.removeEventListener('keydown', keydown);
				clearTimeout(timeout);
				setTimeout(function () {
					if (windowUnloading === 1) windowUnloading = false;
				}, 250);
			}
			function click(e)
			{
				if (isValidAHrefClick(e, ['javascript:']))
				{
					userStayed();
				}
			}
			function submit(e)
			{
				if (!e.defaultPrevented)
				{
					userStayed();
				}
			}
			function keydown(e)
			{
				if (!e.defaultPrevented && e.which === 27 && !e.shiftKey && !e.altKey && !e.ctrlKey && (!e.metaKey || !/Mac/i.test(navigator.platform))) // esc
				{
					userStayed();
				}
			}
			returnFunction.expectingBeforeUnload = function (fn) {
				var err;
				expectingBeforeUnload++;
				try { fn && fn(); } catch (err) {}
				setTimeout(function () {
					expectingBeforeUnload--;
				}.bind(this));
				if (err !== undefined) throw err;
			};
			return returnFunction;
		},

		/**
		 * user aborting before page fully load
		 * (load (or DOMContentLoaded) never fires but document.readyState is complete)
		 * chrome only
		 */
		_createIsDOMAborted: function () {
			var fullyLoaded = false;
			var globalState = this;
			var globalStateBack = globalState.set(globalState.ARRIVING);
			function event1() {
				if (document.readyState === 'complete')
				{
					globalStateBack();
					fullyLoaded = true;
					window.removeEventListener('load', event1);
					document.removeEventListener('readystatechange', event2);
				}
			}
			function event2() {
				if (document.readyState === 'complete')
				{
					globalStateBack();
					setTimeout(function () {
						(!fullyLoaded) && globalState.set(globalState.ABORTED);
					});
				}
			}
			window.addEventListener('load', event1);
			document.addEventListener('readystatechange', event2);
			event1(); // event may fire before script runs
			return function () { return (!fullyLoaded && document.readyState === 'complete'); };
		},

		/**
		 * not realiable in chrome
		 */
		_createIsConnectionOffline: function () {
			var globalState = this;
			var globalStateBack;
			window.addEventListener('online', function () {
				globalStateBack && globalStateBack();
			});
			window.addEventListener('offline', function () {
				globalStateBack && globalStateBack();
				globalStateBack = globalState.set(globalState.OFFLINE);
			});
			return function () { return (navigator.onLine === false || this.is(this.OFFLINE)); };
		},

		testConnection: function (ifReliable) {
			if (this.isConnectionReliable())
			{
				(this._ping || (this._ping = this._createPing()))(function () {
					if (this.isConnectionReliable()) ifReliable();
				}.bind(this));
			}
		},

		_createPing: function () {
			var ajax = function (url) {
				var abort, promise = new Promise(function (resolve, reject) {
					var req = new XMLHttpRequest;
					abort = function (result) {
						abort = function () {};
						req.onreadystatechange = null;
						req.abort();
						resolve(result);
					};
					req.onreadystatechange = function () {
						if (req.readyState === 2 || req.readyState === 4)
						{
							abort(req.status !== 0);
						}
					};
					req.open('HEAD', url);
					req.send(null);
				});
				promise.abort = function (result) { abort(result); };
				return promise;
			};
			var ajaxAll = function (pings) {
				return new Promise(function (resolve, reject) {
					if (!_.size(pings)) throw new Error;
					var races = _.map(pings, function (ping) {
						return ajax(ping + '?' + _.now());
					});
					_.each(races, function (race) {
						race.then(function (result) {
							result === true && _.each(races, function (race) { race.abort(); });
						});
					});
					Promise.all(races).then(function (results) {
						var totalResult = results.indexOf(true) !== -1; // at least one ping passed
						resolve(totalResult);
					});
				});
			};
			var globalState = this;
			var globalStateBack;
			var runAfter = [];
			var fn;
			var timeout;
			var running = 'not';
			var run = function () {
				running = 'running';
				clearTimeout(timeout);
				ajaxAll(Sim.config.errorReporting.ping)
					.then(function (totalResult) {
						globalStateBack && globalStateBack();
						if (totalResult === false)
						{
							globalStateBack = globalState.set(globalState.OFFLINE);
							timeout = setTimeout(run, 60000);
						}
						while ((fn = runAfter.shift())) fn();
						running = totalResult === false ? 'failed' : 'not';
					})
				;
			};
			return function (after) {
				running === 'failed' ? after() : runAfter.push(after);
				running === 'not' && run();
			};
		},

	};

	function RawUnreportedErrorLog(globalState) {
		this.globalState = globalState;
		this.rawLogRevertable = {};
		this.needHeader = true;
		this.temporaryKey = 'Rentflow.RawUnreportedErrorLog';
		this.format = {
			header: '[\n',
			separator: ',\n\n\n',
			footer: '\n]',
		};
	}
	RawUnreportedErrorLog.createInit = (globalState) =>	{
		const o = new RawUnreportedErrorLog(globalState);
		o.initForTemporary();
		return (...args) => o.add(...args);
	};
	RawUnreportedErrorLog.prototype = {

		initEl(status = 'true') {
			this.initEl = (status2 = 'true') => { status = status2; };
			const initEl = () => {
				var el = $('.globalstate > [data-state="download"]:first');
				if (!el.length && (document.readyState !== 'complete' || !$('#page').length))
				{
					setTimeout(initEl, 500);
					return;
				}
				el.on('click', this.download.bind(this));
				el.attr('data-ready', status);
				this.initEl = (status2 = 'true') => {
					el.attr('data-ready', status2);
				};
			};
			initEl();
		},

		init() {
			if (this.rawLog === undefined)
			{
				this.rawLog = [];
				this.enabled = !!Sim.config.errorReporting.enableRawUnreportedErrorLog;
				if (this.enabled)
				{
					this.restoreTemporary();
					window.addEventListener('beforeunload', () => this.storeTemporary());
					window.addEventListener('unload', () => {
						for (const info of Object.values(this.rawLogRevertable))
						{
							this.addNow(info);
						}
						this.storeTemporary();
					});
					if (this.rawLog.length)
					{
						this.initEl();
					}
				}
			}
			return this.enabled;
		},

		add(info, revertable = false) {
			if (revertable)
			{
				return this.addRevertable(info);
			}
			this.addNow(info);
			this.storeTemporary();
		},

		addRevertable(info) {
			if (!this.init()) return () => {};
			const index = _.uniqueId();
			this.rawLogRevertable[index] = info;
			return () => {
				delete this.rawLogRevertable[index];
			};
		},

		addNow(info) {
			try {
				if (!this.init()) return;
				this.initEl();
				if (this.needHeader)
				{
					this.needHeader = false;
					this.rawLog.push(this.rawLog.length ? this.format.separator : this.format.header);
					this.rawLog.push(this.stringify(this.getHeaderInfo()));
				}
				this.rawLog.push(this.format.separator);
				this.rawLog.push(this.stringify(this.normalizeInfo(info)));
			} catch (err) {
				if (Sim.config.debuggerEnabled) throw err;
				// ignore
			}
		},

		normalizeInfo: function (info) {
			if (_.isFunction(info))
			{
				info = info();
			}
			var compress = this.getOptionalCompress();
			if (compress)
			{
				var clone = JSON.parse(JSON.stringify(info));
				clone[1] && clone[1].responseText && (clone[1].responseText = compress(clone[1].responseText));
				clone.data && clone.data.responseText && (clone.data.responseText = compress(clone.data.responseText));
				clone.data && clone.data.currentHtml && (clone.data.currentHtml = compress(clone.data.currentHtml));
				clone.data && clone.data.html && (clone.data.html = compress(clone.data.html));
				info = clone;
			}
			return info;
		},

		stringify: function (data) {
			return JSON.stringify(data, null, "\t");
		},

		getHeaderInfo: function () {
			var user = $('#signedInPerson');
			var date = new Date;
			return {
				user: user.length ? user.text() + ' # ' + user.attr('data-user') : null,
				date: (date.toISOString ? date.toISOString() : date.toUTCString()) + ' # ' + date.toString(),
				address: window.location.href,
				userAgent: window.navigator.userAgent,
			};
		},

		getOptionalCompress: function () {
			const Gzip = Sim.require.get('Helpers/Gzip', (err) => {
				window.console.error(err);
				return null;
			});
			if (Gzip)
			{
				const compress = (data) => Gzip.encode(data, 'base64');
				this.getOptionalCompress = () => compress;
				return compress;
			}
		},

		createBlob() {
			if (!window.Blob || !window.URL || !URL.createObjectURL) return {};
			if (!this.rawLog.length && this.lastBlob)
			{
				return this.lastBlob;
			}
			var blobName = 'unexpected-error-' + window.location.host.replace(/[^\w]/g, '-') + '-' + _.now() + '.log';
			var blobBits = this.rawLog.concat([this.format.footer]);
			var blob;
			try {
				blob = new File(blobBits, blobName, {type: 'application/octet-stream'});
			} catch (err) {
				blob = new Blob(blobBits, {type: 'application/octet-stream'});
			}
			var blobUrl = URL.createObjectURL(blob);
			this.needHeader = true;
			this.rawLog = [];
			this.initEl('downloaded');
			return this.lastBlob = {blobName, blobUrl};
		},

		download() {
			const {blobName, blobUrl} = this.createBlob();
			if (blobUrl === undefined) return;
			var supportDownload = ('download' in document.createElement('a'));
			if (supportDownload)
			{
				var a = $('<a>')
					.attr('href', blobUrl)
					.text(blobName)
					.attr('download', blobName)
					.hide()
					.appendTo('body')
				;
				a[0].dispatchEvent(new MouseEvent('click'));
				a.remove();
			}
			else
			{
				this.globalState.unloadManager.expectingBeforeUnload(function () {
					window.location.href = blobUrl;
				});
			}
		},

		initForTemporary() {
			if (!('sessionStorage' in window)) return;
			const data = window.sessionStorage.getItem(this.temporaryKey);
			if (data !== null)
			{
				this.init();
			}
		},

		storeTemporary() {
			if (!this.rawLog.length) return;
			if (!this.globalState.unloadManager.is()) return;
			if (!('sessionStorage' in window)) return;
			const blobBits = this.rawLog.join('');
			const success = this.try(() => {
				const data = window.sessionStorage.getItem(this.temporaryKey);
				window.sessionStorage.setItem(this.temporaryKey, blobBits);
				if (data === 'window.name')
				{
					window.name = '';
				}
			});
			if (!success && window.name === '')
			{
				this.try(() => {
					// sessionStorage has ~5MB limit, window.name seems to have no limit and is preserved to next request
					window.name = `${this.temporaryKey}${blobBits}${this.temporaryKey}`;
					window.sessionStorage.setItem(this.temporaryKey, 'window.name');
				});
			}
		},

		restoreTemporary() {
			if (!('sessionStorage' in window)) return;
			let data = window.sessionStorage.getItem(this.temporaryKey);
			if (data !== null)
			{
				window.sessionStorage.removeItem(this.temporaryKey);
			}
			if (data === 'window.name')
			{
				data = window.name;
				if (typeof data === 'string' && data.startsWith(this.temporaryKey) && data.endsWith(this.temporaryKey))
				{
					data = data.substring(this.temporaryKey.length, data.length - this.temporaryKey.length);
				}
				else
				{
					data = null;
				}
				window.name = '';
			}
			if (data !== null)
			{
				this.rawLog.push(data);
			}
		},

		try(fn) {
			try
			{
				fn();
				return true;
			}
			catch (err)
			{
				if (Sim.config.debuggerEnabled && 'console' in window && 'trace' in window.console)
				{
					window.console.trace(err);
				}
				return false;
			}
		},

	};

	(window.Sim || (window.Sim = {})).createGlobalState = function () {
		delete window.Sim.createGlobalState;
		return new GlobalState;
	};
})();
