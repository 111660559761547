(() => {

	const timeout = 60000; // 60s

	let timeoutID;
	const reveal = () => {
		$('.a-privateText').addClass('a-privateText--revealed').each((i, el) => {
			$(el).data('a-privateText--title', $(el).attr('title') || $(el).data('a-privateText--title'));
			$(el).removeAttr('title');
		});

		timeoutID && clearTimeout(timeoutID);
		timeoutID = setTimeout(() => {
			timeoutID = null;
			$('.a-privateText').removeClass('a-privateText--revealed').each((i, el) => {
				$(el).attr('title', $(el).attr('title') || $(el).data('a-privateText--title'));
			});
		}, timeout);

		const expires = new Date;
		expires.setMilliseconds(expires.getMilliseconds() + timeout);
		Cookies.set('a-privateText', '1', {
			expires,
			path: `${Sim.config.basePath}/`,
		});
	};

	const init = () => {
		Cookies.get('a-privateText') && $(reveal);
	};

	Sim.init.addGlobalEvent('.a-privateText:not(.a-privateText--revealed)', 'click focus', () => {
		reveal();
		return false;
	});
	Sim.init.addDirectHandler('.a-privateText', null, init);
	Sim.init.addDirectHandler('.clickthru-edit .edit.a-privateText:not(.a-privateText--revealed)', null, () => {
		return false;
	});
	init();
})();
