define(['Helpers/ElementFinder'], (ElementFinder) => {

	class UnsubscribeForm
	{

		constructor(formEl)
		{
			const $form = new ElementFinder(formEl, 'form');
			const refresh = $form.findOne('input[name="refresh"]');
			const email = $form.findOne('select[name="email"]');
			Sim.enableAjaxSubmit($form.$root)
				.addIsEnabled((form) => refresh.is(form[0]['nette-submittedBy']))
				.onSuccess((form, payload, textStatus, jqXHR, submittedBy) => {
					$.nette.success(payload, textStatus, jqXHR);
				})
			;
			email.on('change', () => { refresh.trigger('click'); });
		}

	}

	return UnsubscribeForm;
});
