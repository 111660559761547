define(() => {

	class PopoverAjax
	{

		constructor()
		{
			this.ajaxCache = {};
			this.AjaxError = Sim.createCustomError(function AjaxError(responseStatusCode, acceptableUserErrorMessage, ...args) {
				this.responseStatusCode = responseStatusCode;
				this.acceptableUserErrorMessage = acceptableUserErrorMessage;
				return this._customErrorSuper(...args);
			});
		}

		getContent(data, forceReload = false)
		{
			if (this.ajaxCache[data.name] === undefined || forceReload)
			{
				const doAsync = async () => {
					const onRetriggerableError = (timeoutSeconds = 0) => {
						if (timeoutSeconds)
						{
							setTimeout(() => onRetriggerableError(), timeoutSeconds * 1000);
						}
						else
						{
							this.ajaxCache[data.name] = undefined;
						}
					};
					const snippets = await this.createAjax(data, onRetriggerableError);
					let found = undefined;
					for (const [name, content] of Object.entries(snippets))
					{
						if (data.name === name)
						{
							found = content;
						}
						else if (this.ajaxCache[name] === undefined)
						{
							this.ajaxCache[name] = Promise.resolve(content);
						}
					}
					if (found === undefined)
					{
						throw new Error(`Popover '${data.name}' not found in result.`);
					}
					return found;
				};
				this.ajaxCache[data.name] = doAsync();
			}
			return this.ajaxCache[data.name];
		}

		createAjax(data, onRetriggerableError)
		{
			if (this.lastAjax !== undefined)
			{
				this.lastAjax.abort();
			}
			return new Promise((resolve, reject) => {
				this.lastAjax = $.ajax({
					url: data.href,
					method: 'POST',
					data: data.MAC,
					dataType: 'json',
					global: false, // ignore Sim.loading
					success: (payload, textStatus, jqXHR) => {
						try
						{
							$.nette.success(payload, textStatus, jqXHR, {allowRedirects: false});
							resolve(payload['Popover::CONTENT_SNIPPET']);
						}
						catch (err)
						{
							if ($.nette.isRedirectPayload(payload) && payload.loggedInInfo && !payload.loggedInInfo.isLoggedIn)
							{
								onRetriggerableError(2);
								reject(new this.AjaxError(
									401,
									$()
										.add($('<span>').text(`${payload.loggedInInfo.errorMessage} `))
										.add($('<a>').text(payload.loggedInInfo.actionMessage).attr('href', payload.redirect))
									,
									err.toString(),
									{cause: err},
								));
							}
							else
							{
								reject(err);
							}
						}
					},
					error: (jqXHR, textStatus, errorThrown) => {
						let acceptableUserErrorMessage = null;
						const errMessage = jqXHR.getResponseHeader('X-SIM-Popup-Error-Message');
						if (errMessage !== null && [404, 403].includes(jqXHR.status))
						{
							jqXHR.ignoreSimErrorReporting = true;
							acceptableUserErrorMessage = JSON.parse(errMessage);
							onRetriggerableError(30);
						}
						else
						{
							onRetriggerableError(textStatus === 'abort' ? 0 : 5);
						}
						reject(new this.AjaxError(
							jqXHR.status,
							acceptableUserErrorMessage,
							`Ajax error: ${textStatus} ${jqXHR.status} ${errorThrown} '${acceptableUserErrorMessage}'`
						));
					},
					complete: () => {
						this.lastAjax = undefined;
					},
				});
			});
		}

	}

	return PopoverAjax;
});
