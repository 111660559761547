
define(['app/model/Services/Stuff/UnicodeToAscii/UnicodeToAsciiTable.jsonp'], function (UnicodeToAsciiTable) {

	var TagTypeahead = function TagTypeahead(el) {
		this.el = this.$input = $(el);
		if (this.el.length !== 1) throw new Error;
		if (!this.el.is('input:text')) throw new Error;
		if (!this.el.data('TagTypeahead'))
		{
			this.inicializeTypeahead();
			this.inicializeTags();
			this.el.data('TagTypeahead', this);
		}
	};

	TagTypeahead.prototype = {

		SEPARATOR: ';', // Semicolon (U+003B) Sim\TagTypeahead::SEPARATOR
		SEPARATOR_FAKE_PLACEHOLDER: ';', // Greek question mark (U+037E) Sim\TagTypeahead::SEPARATOR_FAKE_PLACEHOLDER

		inicializeTypeahead: function () {

			this.th = {
				shown: false,
				focused: false,
				mousedover: false,
				query: '',
				$menu: this.$menu = $('<ul class="m-dropdownMenu"></ul>'),
				options: {
					minLength: parseInt(this.$input.attr('data-minlength') ?? 1, 10),
					items: parseInt(this.$input.attr('data-items') ?? 12, 10),
					footerText: this.$input.attr('data-footer-text'),
				},
			};

			this.th.show = () => {
				const pos = $.extend({}, this.$input.position(), {height: this.$input[0].offsetHeight});
				this.$menu.insertAfter(this.$input).show();
				this.$menu.css({
					top: `${pos.top + pos.height}px`,
					left: `${Sim.config.isRTL ? pos.left - this.$menu.outerWidth() + this.$input.outerWidth() : pos.left}px`,
				});
				this.th.shown = true;
			};

			this.th.hide = () => {
				this.$menu.hide();
				this.th.shown = false;
			};

			this.th.select = () => {
				const val = this.$menu.find('.active').attr('data-value');
				this.$input.val(this.updater(val)).trigger('change');
				this.th.hide();
			};

			this.$menu.on('click', (e) => {
				e.stopPropagation();
				e.preventDefault();
				this.th.select();
				this.$input.trigger('focus');
			});

			this.$input.on('focus', () => {
				this.th.focused = true;
				// Kdyz obsahuje prvek text tak se zobrazi moznosti pri vraceni, nebo kdyz je zaple ze se ma zobrazit vzdy.
				this.th.lookup();
				// Kdyz pri vybrani prvku mysi nad jinym menu poposkoci tak ze mys skonci nad druhym prvkem,
				// tak se i po kliknuti na druhy prvek menu z prvniho prvku nezmizi. Protoze se nezavola ze mys z menu odjela.
				this.th.mousedover = false;
			});

			this.$input.on('blur', () => {
				this.th.focused = false;
				if (!this.th.mousedover && this.th.shown) this.th.hide();
			});

			this.$menu.on('mouseenter', '> .m-dropdownMenu__row', (e) => {
				this.th.mousedover = true;
				this.$menu.find('.active').removeClass('active');
				$(e.currentTarget).addClass('active');
			});

			this.$menu.on('mouseleave', '> .m-dropdownMenu__row', () => {
				this.th.mousedover = false;
				if (!this.th.focused && this.th.shown) this.th.hide();
			});

			this.th.lookup = () => {
				// Kdyz se nic nenajde tak neobsahuje v dom prvky.
				this.$menu.empty();
				this.th.query = this.$input.val() || '';
				if (this.th.query.length < this.th.options.minLength)
				{
					if (this.th.shown) this.th.hide();
					return;
				}
				const items = this.getSource(this.th.query, (arr) => this.th.render(arr));
				if (items) this.th.render(items);
			};

			// Pro prazdne hledani neni prvni moznost aktivni.
			// pass full item to highlighter, but sets only item.text to data-value
			this.th.render = (items) => {
				items = $.grep(items, (item) => this.matcher(item));
				items = this.sorter(items);
				if (!items.length)
				{
					if (this.th.shown) this.th.hide();
					return;
				}
				const total = items.length;
				items = items.slice(0, this.th.options.items);

				const els = items.map((item) => {
					if (typeof item !== 'object')
					{
						item = {text: item};
					}
					const el = $('<li class="m-dropdownMenu__row"><a></a></li>').attr('data-value', item.text);
					el.find('a').html(this.highlighter(item.text, item));
					return el[0];
				});
				if (els.length > 0 && this.$input.val() !== '')
				{
					$(els[0]).addClass('active');
				}
				this.$menu.html(els);
				if (this.th.options.footerText && els.length !== total)
				{
					const text = this.th.options.footerText
						.replace('%number%', els.length)
						.replace('%total%', total)
					;
					this.$menu.append($('<li class="m-dropdownMenu__footer">').text(text));
				}
				this.th.show();
			};

			// Enter se spracovava odlisne.
			// Vzdy prida hodnoty, i kdyz se nic nenajde.
			// A odesila jen kdyz je prvek prazdny.

			this.$input.on('keydown', (e) => {
				let nextOrPrev;
				switch (e.keyCode)
				{
					case 9: // tab
					case 27: // escape
						if (!this.th.shown) return;
						e.preventDefault();
						break;

					case 13: // enter
						if (this.$input.val() === '' && (!this.th.shown || !this.$menu.children('.active').length))
						{
							return;
						}
						e.preventDefault();
						break;

					case 38: // up arrow
					case 40: // down arrow
						if (!this.th.shown) return;
						e.preventDefault();
						nextOrPrev = this.$menu.find('.active').removeClass('active');
						nextOrPrev = e.keyCode === 38 ? nextOrPrev.prev() : nextOrPrev.next();
						if (!nextOrPrev.length)
						{
							nextOrPrev = this.$menu.find('> .m-dropdownMenu__row');
							nextOrPrev = e.keyCode === 38 ? nextOrPrev.last() : nextOrPrev.first();
						}
						nextOrPrev.addClass('active');
						break;
				}
				e.stopPropagation();
			});

			this.$input.on('keyup', (e) => {
				switch (e.keyCode)
				{
					case 40: // down arrow
					case 38: // up arrow
					case 16: // shift
					case 17: // ctrl
					case 18: // alt
						break;

					case 9: // tab
						if (!this.th.shown) return;
						this.th.select();
						break;

					case 13: // enter
						if (this.$input.val() === '' && (!this.th.shown || !this.$menu.children('.active').length))
						{
							return;
						}
						this.th.select();
						this.$input.trigger('focus');
						break;

					case 27: // escape
						if (!this.th.shown) return;
						this.th.hide();
						break;

					default:
						this.th.lookup();
				}
				e.stopPropagation();
				e.preventDefault();
			});

			// Submit prida hodnotu, jako by se mackl enter v prvku.
			this.el.closest('form').on('submit', _.bind(function (e) {
				if (this.el.val() !== '') this.select();
			}, this));
		},

		inicializeTags: function () {
			this.hidden = $('<input type="hidden">').attr('name', this.el.attr('name'));
			this.hidden.insertAfter(this.el);
			this.el
				.removeAttr('name')
				.attr('autocomplete', 'off')
			;

			this.selected = {};
			_.each(this.el.val().split(this.SEPARATOR), this.addText, this);
			this.el.val('');

			// Backspace maze jednu posledni vybranou hodnotu pri prazdnem inputu.
			this.el.on('keydown', _.bind(function (e) {
				this.backspaceOnEmptyPressed = (
					e.which === 8 ? ( // backspace
						this.backspaceOnEmptyPressed === undefined ?
						this.el.val() === '' :
						this.backspaceOnEmptyPressed
					) : false
				);
			}, this));
			this.el.on('keyup', _.bind(function (e) {
				if (e.which === 8 && this.backspaceOnEmptyPressed) // backspace
				{
					this.removeText(_.last(_.keys(this.selected)));
				}
				this.backspaceOnEmptyPressed = undefined;
			}, this));
		},

		select: function () {
			if (this.th.shown && this.th.$menu.children('.active').length)
			{
				this.th.select();
			}
			else if (!this.th.shown && this.el.val() !== '' && this.th.$menu.children().length)
			{
				this.th.$menu.children('.active').removeClass('active');
				this.th.$menu.children(':first').addClass('active');
				this.th.select();
			}
			else if (this.el.val() !== '')
			{
				this.addText(this.el.val());
				this.el.val('');
			}
		},

		addText: function (text) {
			if (typeof text !== 'string' || text === '') return;
			this.getSource(); // init
			var item = text;
			if (item.indexOf(this.SEPARATOR) !== -1)
			{
				item = text.replace(new RegExp(Sim.escapeRegex(this.SEPARATOR), 'gu'), this.SEPARATOR_FAKE_PLACEHOLDER);
			}
			if (text.indexOf(this.SEPARATOR_FAKE_PLACEHOLDER) !== -1)
			{
				text = text.replace(new RegExp(Sim.escapeRegex(this.SEPARATOR_FAKE_PLACEHOLDER), 'gu'), this.SEPARATOR);
			}
			if (this.selected[item] === undefined)
			{
				this.selected[item] = {
					text: text,
					label: this.createLabel(item, text).insertBefore(this.el),
				};
			}
			this.hidden.val(_.keys(this.selected).join(this.SEPARATOR));
		},

		removeText: function (text) {
			if (this.selected[text] !== undefined)
			{
				this.selected[text].label.remove();
				delete this.selected[text];
			}
			this.hidden.val(_.keys(this.selected).join(this.SEPARATOR));
		},

		createLabel: function (item, text, search) {
			if (text === undefined) text = item;
			if (search === undefined) search = text;
			return $('<span>')
				.addClass('TagTypeahead-label')
				.toggleClass('notFound', this.sourceHashMap[item] === undefined)
				.append(
					$('<span>')
						.text(text)
						.attr('title', text)
						.one('dblclick', _.bind(function () {
							this.removeText(item);
							this.select();
							this.el.val(search).trigger('focus');
							return false;
						}, this))
				)
				.append(
					$('<a>')
						.attr('href', '#')
						.attr('title', this.el.attr('data-remove-text'))
						.html('<i class="icon-remove-sign"></i>')
						.one('click', _.bind(function () {
							this.removeText(item);
							return false;
						}, this))
				)
			;
		},

		getSource: function () {
			if (this.source === undefined)
			{
				var items = JSON.parse(this.el.attr('data-source'));
				this.el.removeAttr('data-source'); // faster dom inspector
				this.source = Object.values(this.createSource(items));
				this.sourceHashMap = _.object(this.source.map((item) => [item.text, true]));
			}
			return this.source;
		},

		createSource: function (items) {
			if (!this.el.attr('data-source-keep-order'))
			{
				items = _.filter(items);
				items = _.sortBy(items);
				items = _.uniq(items, true);
			}
			return _.map(items, this.getSource_getItem.bind(this));
		},

		getSource_getItem: function (text) {
			text = typeof text === 'string' ? text : text.toString();
			return {
				text: text,
				normalized: this.normalize(text),
			};
		},

		updater: function (text) {
			this.addText(text);
			return '';
		},

		normalize: function (text) {
			text = text.replace(/[^a-zA-Z0-9 ]/gu, function (char) {
				var r = UnicodeToAsciiTable[char];
				return r === undefined ? '' : r;
			});
			text = text.toLowerCase();
			text = text.replace(/[^a-z0-9]+/gu, '');
			return text;
		},

		matcher: function (item) {
			var query = this.th.query;
			if (!this.lastQuery || this.lastQuery[0] !== query)
			{
				this.lastQuery = [query, _.map(_.filter(query.split(' ')), this.normalize, this), null];
			}
			for (var index = 0, qn = this.lastQuery[1], length = qn.length; index < length; index++)
			{
				if (item.normalized.indexOf(qn[index]) === -1)
				{
					return false;
				}
			}
			return true;
		},

		sorter: function (items) {
			var query = this.th.query;
			if (query.length > 0)
			{
				var definition = this.sorter.definition; // global
				if (definition === undefined)
				{
					definition = this.sorter.definition = (function () {
						var same = function (a, b) { return a === b; };
						var contains = function (a, b) { return a.indexOf(b) !== -1; };
						var lower = function (a, b, item, query, fn) { return fn(a.toLowerCase(), b.toLowerCase()); };
						var normalize = function (a, b, item, query, fn) { return fn(item.normalized, query.normalized); };
						var words = function (a, b, item, query, fn) {
							var as = _.filter(a.split(' '));
							var bs = _.filter(b.split(' '));
							return _.every(bs, function (b) {
								return _.some(as, function (a) {
									return fn(a, b);
								});
							});
						};
						return _.map([
							[same],
							[lower, same],
							[contains],
							[lower, contains],
							[normalize, same],
							[normalize, contains],
							[words, same],
							[words, lower, same],
							[words, contains],
							[words, lower, contains],
							[],
						], function (def) {
							var priority = function () { return true; };
							_.each(def.reverse(), function (fn1) {
								var fn2 = priority;
								priority = function (a, b, item, query) {
									return fn1(a, b, item, query, function (a, b) {
										return fn2(a, b, item, query);
									});
								};
							});
							return function (item, query) {
								return priority(item.text, query.text, item, query);
							};
						});
					}).call({});
				}
				var priorities = _.map(definition, function () { return []; });
				var queryData = {
					text: query,
					normalized: this.normalize(query),
				};
				_.each(items, function (item) {
					_.find(definition, function (priority, number) {
						if (priority(item, queryData))
						{
							priorities[number].push(item);
							return true;
						}
					}, this);
				}, this);
				return Array.prototype.concat.apply([], priorities);
			}
			return items;
		},

		highlighter: function (text, item) {
			var html;
			if (this.th.query.length > 0)
			{
				var normalizedItem = '';
				var splitsPositions = [];
				var splits = [];
				(function () {
					var position = 0;
					_.each(text.split(''), function (char) {
						var split = {
							original: char,
							highlight: false,
						};
						var normalized = this.normalize(char);
						normalizedItem += normalized;
						_.each(normalized.split(''), function () {
							if (splitsPositions[position] === undefined) splitsPositions[position] = [];
							splitsPositions[position].push(split);
							position++;
						});
						splits.push(split);
					}, this);
				}).call(this);

				if (this.th.query !== this.lastQuery[0]) throw new Error;
				if (this.lastQuery[2] === null)
				{
					this.lastQuery[2] = _.map(this.lastQuery[1], function (word) {
						word = word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
						return new RegExp('(' + word + ')', 'ig');
					});
				}
				_.each(this.lastQuery[2], function (re) {
					normalizedItem.replace(re, function ($1, match, offset) {
						var stop = offset + match.length;
						for (var position = offset; position < stop; position++)
						{
							_.each(splitsPositions[position], function (split) {
								split.highlight = true;
							});
						}
					});
				});

				html = '';
				(function () {
					var lastHighlight = false;
					var buffer = '';
					var add = function () {
						html += (lastHighlight ? $('<div/>').append($('<strong/>').text(buffer)) : $('<div/>').text(buffer)).html();
						buffer = '';
					};
					_.each(splits, function (split) {
						if (lastHighlight !== split.highlight)
						{
							add();
							lastHighlight = split.highlight;
						}
						buffer += split.original;
					});
					add();
				}).call(this);
			}
			else
			{
				html = $('<div/>').text(text).html();
			}
			return Sim.BootstrapTypeaheadHighlighterFix.limit(html);
		},

	};

	TagTypeahead.register = function () {
		$('[data-provide="TagTypeahead"]').each(function () {
			new TagTypeahead(this);
		});
	};

	return TagTypeahead;
});
