
define(['app/components/TagTypeahead/TagTypeahead', 'GoogleApi'], function (TagTypeahead, GoogleApi) {
	// https://developers.google.com/maps/documentation/javascript/places-autocomplete

	function LocationFilterTagTypeahead(el, locationFilter) {
		this.lf = locationFilter;
		this.sourceHashMap = {};
		TagTypeahead.call(this, el);
		this.th.options.minLength = 0;
		this.th.options.items = undefined;
	}
	LocationFilterTagTypeahead.prototype = _.extend((function (child, parent) {
		var F = function () {}; F.prototype = parent.prototype; var P = new F; P.constructor = child; return P;
	})(LocationFilterTagTypeahead, TagTypeahead), {

		createLabel: Sim.extendFunction(TagTypeahead.prototype.createLabel, function (old, item) {
			var place = this.lf.getPlace(item, true);
			old([item, place.description, place.search])
				.toggleClass('notFound', !!place.error)
			;
		}),

		addText: Sim.extendFunction(TagTypeahead.prototype.addText, function (old, item) {
			if (item === '' || item === undefined) return;
			var tempEl = this.createLabel(item).insertBefore(this.el);
			tempEl.find('a').css('visibility', 'hidden');
			tempEl.find('span[title]').off('dblclick');
			this.lf.holdSubmit = true;
			this.lf.getSerializedPlace(item, _.bind(function (serialized) {
				var done = _.bind(function (serialized) {
					old([serialized]);
					tempEl.remove();
					this.lf.holdSubmit = false;
				}, this);
				if (item === serialized && this.th.query === item && this.lf.getPlace(serialized, true).error)
				{
					tempEl.find('span').text('LOADING: ' + item);
					this.lf.findPlaces(item, _.bind(function (items) {
						this.lf.getSerializedPlace(items[0] || item, done, true);
					}, this));
					return;
				}
				done(serialized);
			}, this), this.th.query === item);
		}),

		removeText: Sim.extendFunction(TagTypeahead.prototype.removeText, function (old, item) {
			if (item === undefined) return;
			this.lf.getSerializedPlace(item, function (serialized) {
				old([serialized]);
			}, true);
		}),

		getSource: function (query, process) {
			if (arguments.length === 0) return;
			this.loading && this.loading();
			var wasLastSourceFromGoogle = this.th.$menu.hasClass('google-api-logo');
			if (!query.length && !wasLastSourceFromGoogle)
			{
				this.th.$menu.removeClass('google-api-logo');
				return this.lf.defaults;
			}
			if (_.contains(this.lf.defaults, query.toLowerCase()))
			{
				this.th.$menu.removeClass('google-api-logo');
				return [query.toLowerCase()];
			}
			if (query.length < 1)
			{
				return [];
			}
			if (!this.el['blur-google-api-logo-inicialized'])
			{
				this.el.on('blur', _.bind(function () {
					if (this.el.val() === '')
					{
						this.th.$menu.removeClass('google-api-logo');
					}
				}, this))['blur-google-api-logo-inicialized'] = true;
			}
			this.loading = Sim.loading();
			this.lf.findPlaces(query, _.bind(function (items) {
				this.loading();
				if (this.th.query === query)
				{
					this.th.$menu.addClass('google-api-logo');
					process(items);
				}
			}, this), 500);
			return null;
		},

		matcher: function (item) {
			return true;
		},

		sorter: function (items) {
			return items;
		},

		highlighter: function (text) {
			var html = this.lf.getPlace(text).highlight;
			return Sim.BootstrapTypeaheadHighlighterFix.limit(html);
		},

	});


	function LocationFilter(el) {
		this.google = new GoogleApi(function (language) {
			return ['https://maps.googleapis.com/maps/api/js', {
				v: '3.exp',
				libraries: 'places',
				language: language,
			}];
		}, this);
		this.places = {};
		this.services = {};
		this.initDefaultPlaces(el);
		this.tt = new LocationFilterTagTypeahead(el, this);
		if (this.tt.el.is(':focus'))
		{
			this.tt.el.trigger('focus');
		}
		this.tt.el.closest('form').on('submit', _.bind(function (e) {
			if (this.holdSubmit)
			{
				_.delay(function (form, stop) {
					form.trigger('submit');
					stop();
				}, 500, $(e.currentTarget), Sim.loading());
				return false;
			}
		}, this));
	}
	LocationFilter.prototype = {

		initDefaultPlaces: function (el) {
			var $el = $(el);
			var defaults = JSON.parse($el.attr('data-defaults') || '{}');
			$el.removeAttr('data-defaults'); // faster dom inspector
			this.defaults = _.map(defaults, function (text, id) {
				this.places[id] = {
					id: id,
					description: text,
					search: id,
					highlight: this.highlight(text),
					serialized: id,
				};
				return id;
			}, this);
		},

		getPlace: function (by, hideError) {
			try {
				if (_.isObject(by)) by = by.id;
				var item = this.places[by];
				if (!item && by.substr(0, 1) === '{')
				{
					item = _.find(this.places, {serialized: by});
					if (item === undefined)
					{
						item = JSON.parse(by);
						if (!_.isObject(item)) throw new Error(by);
						if (!item.description) throw new Error(by);
						if (!item.en) throw new Error(by);
						item.id = _.uniqueId('@');
						item.serialized = by;
						this.places[item.id] = item;
					}
				}
				if (!item) throw new Error(by);
			} catch (e) {
				item = {
					error: e,
					id: by,
					description: 'ERROR: ' + by,
					search: by,
					serialized: by,
					highlight: this.highlight(`ERROR: ${by}`),
				};
				if (!hideError) setTimeout(function () { throw e; });
			}
			return item;
		},

		getSerializedPlace: function (id, done, hideError) {
			var item = this.getPlace(id, hideError);
			if (item.serialized)
			{
				done(item.serialized);
				return;
			}
			if (item.error) return;
			this.findDetail(item, function (en, local) {
				item.serialized = JSON.stringify({
					description: item.description,
					en: en,
					local: local,
				});
				done(item.serialized);
			});
		},

		cache: function (rawKey, done, fallback, debounce) {
			var key = JSON.stringify(rawKey);
			var cache = (this.cache.cache || (this.cache.cache = {}));
			var s = (cache[key] || (cache[key] = {}));
			if (!s.result)
			{
				if (debounce !== undefined)
				{
					if (!this.cache.debounce || this.cache.debounce[0] !== debounce)
					{
						this.cache.debounce = [debounce, _.debounce(function (f) { f(); }, debounce)];
					}
					this.cache.debounce[1](_.bind(this.cache, this, rawKey, done, fallback, undefined));
					return;
				}
				if (!s.queue)
				{
					s.queue = [];
					fallback.call(this, function () {
						s.result = _.toArray(arguments);
						_.invoke(s.queue, 'apply', null, s.result);
						delete s.queue;
					});
				}
				s.queue.push(done.bind(this));
				return;
			}
			done.apply(this, s.result);
		},

		findPlaces: function (text, done, debounce) {
			var self = this;
			this.cache(['p', Sim.config.language, text], function (results) {
				done(_.map(results, function (result) {
					var place = _.clone(result);
					place.id = _.uniqueId('@');
					this.places[place.id] = place;
					return place.id;
				}, this));
			}, function (done) {
				this.google.get([Sim.config.language], function (google, s) {
					if (!s.autocomplete)
					{
						s.autocomplete = new google.maps.places.AutocompleteService;
					}
					s.autocomplete.getPlacePredictions({
						input: text,
						types: ['geocode'],
					}, function (results, status) {
						if (status !== 'OK' && status !== 'ZERO_RESULTS') throw new Error(status);
						done(_.chain(results)
							.filter(function (result) {
								return !_.contains(result.types, 'establishment');
							})
							.map(function (result) {
								return {
									place_id: result.place_id,
									reference: result.reference,
									description: result.description,
									search: text,
									highlight: self.highlight(result.description, result.matched_substrings),
								};
							})
							.value()
						);
					});
				});

			}, debounce);
		},

		highlight: function (description, matched_substrings) {
			var chars = _.map(description.split(''), function (char) {
				return this.text(char).html();
			}, $('<div/>'));
			_.each(matched_substrings, function (ms) {
				chars[ms.offset] = '<strong>' + chars[ms.offset];
				chars[ms.offset + ms.length - 1] += '</strong>';
			});
			return chars.join('');
		},

		findDetail: function (place, done) {
			var f = {self: this};
			f.find = function (lang, place, done) {
				this.google.get([lang], function (google, s) {
					// 'place.reference' je deprecated od June 24 2014,
					// ale 'place.place_id' nekdy vraci jinou adresu (Napr 'Radlická').
					// Snad to google opravi nez prestane reference fungovat.
					if (!s.places) s.places = new google.maps.places.PlacesService($('<div>')[0]);
					s.places.getDetails(place.reference ? {reference: place.reference} : {placeId: place.place_id}, _.bind(function (place2, status) {
						if (status !== 'OK') throw new Error(status);
						done(place2);
					}));
				});
			};
			f.findEn = function (place, done) {
				f.find('en', place, done);
			};
			f.findLocal = function (place, en, done) {
				var localLang = ((_.find(en.address_components, function (ac) {
					return _.contains(ac.types, 'country');
				}) || {}).short_name || '').toLowerCase();
				// Sim\Model\LanguagesMapper::getDefaultBilingualLanguages
				// Sim\Model\Countries::getPmcLanguages
				if (!_.contains(['cz', 'bg', 'pl', 'sk'], localLang))
				{
					return done(null);
				}
				f.find(localLang, place, done);
			};
			f.fixViewport = function (place, en, done) {
				this.google.get(['en'], function (google, s) {
					if (en.geometry.viewport)
					{
						return done(en);
					}
					if (!s.geocoder) s.geocoder = new google.maps.Geocoder;
					s.geocoder.geocode({address: en.formatted_address}, function (places, status) {
						if (status !== 'OK') throw new Error(status);
						var place3 = _.find(places, function (place3) {
							if (this.fa1 === place3.formatted_address) return true;
							if (this.fa2 === place3.formatted_address) return true;
							if (this.location === place3.geometry.location.toUrlValue()) return true;
							return false;
						}, {
							fa1: place.description,
							fa2: en.formatted_address,
							location: en.geometry.location.toUrlValue(),
						});
						var place4 = _.extend({}, en, {
							geometry: _.extend({}, en.geometry, {
								viewport: place3 ? place3.geometry.viewport : null,
							}),
						});
						done(place4);
					});
				});
			};
			f.removeSame = function (en, local) {
				if (local && JSON.stringify(en) !== JSON.stringify(local))
				{
					return local;
				}
			};
			f.format = function (place) {
				if (!place) return undefined;
				return {
					location: place.geometry.location.toUrlValue(),
					viewport: place.geometry.viewport ? place.geometry.viewport.toUrlValue() : null,
					types: place.types,
					components: place.address_components,
				};
			};

			this.cache(['d', 'en', place.place_id], done, function (done) {

				_.each(f, function (v, n) {
					if (!_.isFunction(v)) return;
					f[n] = function () {
						var last = _.last(arguments);
						if (_.isFunction(last)) last = _.bind(last, f.self);
						return v.apply(f.self, _.initial(arguments).concat([last]));
					};
				});

				f.findEn(place, function (en) {
					f.fixViewport(place, en, function (en) {
						f.findLocal(place, en, function (local) {
							done(f.format(en), f.format(f.removeSame(en, local)));
						});
					});
				});

			});
		},

	};

	return LocationFilter;

});
