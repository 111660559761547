define(() => {

	class UserPreferences
	{

		#ajax;
		#current;
		#timeout;

		constructor(options)
		{
			this.#ajax = options.ajax;
			this.#current = {...options.current};
		}

		updateCompareLanguage(lang)
		{
			this.#updateWithDelay('compareLanguage', lang);
		}

		updateActiveTabLanguage(lang)
		{
			this.#updateWithDelay('activeTabLanguage', lang);
		}

		#updateWithDelay(property, value)
		{
			if (this.#current[property] !== value)
			{
				this.#current[property] = value;
				clearTimeout(this.#timeout);
				this.#timeout = setTimeout(() => this.#updateAjax(), 1000);
			}
		}

		#updateAjax()
		{
			$.ajax({
				url: this.#ajax.url,
				method: 'POST',
				data: Object.entries(this.#current).map(([k, value]) => ({name: this.#ajax.params[k], value})),
				success: () => {},
				error: (jqXHR) => {
					if (Sim.unloadManager.is())
					{
						jqXHR.ignoreSimErrorReporting = true;
					}
				},
				dataType: 'text',
				global: false, // ignore Sim.loading
			});
		}

		getCurrentActiveTabLanguage()
		{
			return this.#current.activeTabLanguage;
		}

	}

	return UserPreferences;
});
