define(['./PopupAjax', './PopupsStack'], (PopupAjax, PopupsStack) => {

	class PopupsHandler
	{

		initOnMouseClick($el)
		{
			if (!$el.hasClass('disabled'))
			{
				this.load($el[0].href, $el[0].getAttribute('data-popup')).catch((err) => { throw err; });
			}
		}

		constructor()
		{
			this.ajax = new PopupAjax;
			this.stack = new PopupsStack(this);
		}

		async load(href, options = {})
		{
			let {
				checkPopupName = null,
				reload = false,
				restoreScroll = undefined,
			} = typeof options === 'object' ? options : {checkPopupName: options};
			let snippets;
			try
			{
				snippets = await this.ajax.loadContentSnippets(href);
			}
			catch (err)
			{
				if (err instanceof this.ajax.AcceptableUserError)
				{
					alert(err.message);
					return;
				}
				throw err;
			}
			for (const [popupName, html] of Object.entries(snippets))
			{
				if (!reload)
				{
					this.add(popupName, html);
				}
				else
				{
					for (const popup of this.stack.query({name: popupName}, false))
					{
						// not ideal, updates content of all popups with the same name, because there is no popupId backend-wise
						popup.updateContent(html, {restoreScroll});
					}
				}
				if (checkPopupName && popupName === checkPopupName)
				{
					checkPopupName = null;
				}
			}
			if (checkPopupName)
			{
				throw new Error(`Popup '${checkPopupName}' not found in '${href}'`);
			}
		}

		add(popupName, html)
		{
			const popups = this.stack.query({name: popupName}, false);
			if (popups.length)
			{
				const popup = popups.pop();
				if (popup.lastHtmlShortCache !== html)
				{
					popup.updateContent(html);
				}
				return popup;
			}
			const popup = this.stack.add(popupName, html);
			popup.lastHtmlShortCache = html;
			setTimeout(() => delete popup.lastHtmlShortCache, 500);
			return popup;
		}

		onClose(query, callback)
		{
			for (const popup of Object.values(this.stack.query(query)))
			{
				popup.onClose(callback);
			}
		}

	}

	return PopupsHandler;
});
