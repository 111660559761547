define(() => {

	class Compare
	{

		#options;
		#form;
		#compareWithSwitch;
		#customTextRepeater;
		#offResizeInaccuracyWarning;

		constructor(options)
		{
			this.#options = options;
			this.#form = options.form;
			this.#form.on('click', 'span.copyCompared', (e) => this.#copyCompared($(e.target)));
			this.#compareWithSwitch = this.#form.find('select[name="compareWith"]');
			this.#compareWithSwitch.on('change', () => this.showCompareBox());
			this.#customTextRepeater = options.customTextRepeater;
		}

		showCompareBox()
		{
			const activeTextContainer = this.#customTextRepeater.getRows().filter('.active');
			const copyAllowed = (
				activeTextContainer.is('.o-messageTemplateLanguageTab--allowedLanguage') &&
				!activeTextContainer.is('.o-messageTemplateLanguageTab--deleted')
			);
			const compareContainer = this.#compareWithSwitch.closest('.o-messageTemplateForm__textBoxCompareWith');
			compareContainer.toggleClass('copyAllowed', copyAllowed);
			if (
				!this.#compareWithSwitch.find('optgroup').length
				|| ( // only one custom text before save
					this.#compareWithSwitch.find('optgroup').length === 1
					&& this.#customTextRepeater.getRows().length === 1
					&& this.#customTextRepeater.getRows().find('textarea[name$="[content]"]:not([data-original-value])').length === 1
				)
			)
			{
				compareContainer.hide();
			}
			else
			{
				compareContainer.show();
				const warningEl = activeTextContainer.find('.o-messageTemplateForm__inaccuracyWarning');
				const fakeEl = compareContainer.find('.o-messageTemplateForm__inaccuracyWarning--fake');
				this.#offResizeInaccuracyWarning && this.#offResizeInaccuracyWarning();
				this.#offResizeInaccuracyWarning = Sim.onResize(warningEl, () => fakeEl.height(warningEl.height()), 50);
				fakeEl.height(warningEl.height()).toggle(activeTextContainer.is('.o-messageTemplateLanguageTab--inaccurate'));
			}
			const selected = this.#compareWithSwitch.val();
			const compareBox = this.#form.find('.compareText');
			const [mode, lang] = selected ? selected.split('_') : [null, null];
			this.#options.updateUserPreferences(lang);
			this.#form.addClass('notCompares');
			if (selected)
			{
				const isOnDefaultMode = this.#form.hasClass('modeDefault');
				let showSaved = true;
				let isUnsaved = false;
				if (!isOnDefaultMode)
				{
					isUnsaved = !!this.#customTextRepeater.getRows().filter(`.o-messageTemplateLanguageTab--changed[data-language="${$.escapeSelector(lang)}"]`).length;
					const activeLang = this.#customTextRepeater.getRows().filter('.active').attr('data-language');
					showSaved = !isUnsaved || lang === activeLang;
				}
				compareBox.toggleClass('unsaved', !showSaved && isUnsaved);
				const textRow = mode === 'default' ? this.#form.find(`.tab-content.default .o-messageTemplateLanguageTab[data-language="${$.escapeSelector(lang)}"]`) : this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(lang)}"]`);
				const subject = textRow.find('input[name$="[subject]"]');
				compareBox.find('input[name="compareSubject"]')
					.val(!showSaved ? subject.val() : subject.attr('data-original-value'))
					.attr('lang', lang);
				const content = textRow.find('textarea[name$="[content]"]');
				compareBox.find('textarea[name="compareContent"]')
					.val(!showSaved ? content.val() : content.attr('data-original-value'))
					.attr('lang', lang);
				compareBox.find('.lastModifiedBox')
					.text(textRow.find('.lastModifiedBox').text());
				this.#form.removeClass('notCompares');
			}
		}

		#copyCompared(copyButton)
		{
			if (this.#form.hasClass('modeDefault')) throw new Error;
			const targetName = copyButton.data('target');
			const ucTargetName = targetName.substring(0, 1).toUpperCase() + targetName.substring(1);
			const sourceElement = copyButton.closest('.m-controlGroup__controls').find(`[name="${$.escapeSelector('compare' + ucTargetName)}"]`).filter(':input');
			const targetElement = this.#customTextRepeater.getRows().filter('.active').find(`[name$="[${$.escapeSelector(targetName)}]"]`).filter(':input');
			targetElement.trigger('select');
			if (!document.execCommand('insertText', false, sourceElement.val()))
			{
				targetElement.val(sourceElement.val()).trigger('input');
			}
		}

		addOption(langCode, languageName, beforeLang = null, afterLang = null)
		{
			const text = this.#options.optionText.replace('%langCode%', langCode.toUpperCase()).replace('%langName%', languageName);
			const label = this.#options.optGroupLabelCustom;
			let optGroup = this.#compareWithSwitch.find(`optgroup[label="${$.escapeSelector(label)}"]`);
			if (!optGroup.length)
			{
				optGroup = $('<optgroup>').attr('label', label).insertAfter(this.#compareWithSwitch.find('option[value=""]'));
			}
			const option = $('<option>').val(`custom_${langCode}`).text(text);
			if (afterLang)
			{
				option.insertBefore(optGroup.find(`option[value="${$.escapeSelector('custom_' + afterLang)}"]`));
			}
			else if (beforeLang)
			{
				option.insertAfter(optGroup.find(`option[value="${$.escapeSelector('custom_' + beforeLang)}"]`));
			}
			else
			{
				optGroup.append(option);
			}
		}

		checkAndDeleteOption(lang)
		{
			if (!this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(lang)}"]`).length)
			{
				this.#compareWithSwitch.find(`option[value="custom_${$.escapeSelector(lang)}"]`).remove();
				const label = this.#options.optGroupLabelCustom;
				const optGroup = this.#compareWithSwitch.find(`optgroup[label="${$.escapeSelector(label)}"]`);
				if (!optGroup.find('option').length)
				{
					optGroup.remove();
				}
			}
		}

	}

	return Compare;
});
