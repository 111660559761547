define(() => ({
	prefix = 'anchor',
	className = 'a-anchorHighlight',
	onAnchor = null,
	manual = true,
} = {}) => {
	const prefixRe = new RegExp(`^#?(${Sim.escapeHtml(prefix)}-.+)$`, 'u');
	let anchor;
	const moveToAnchor = () => {
		if (anchor) anchor.removeClass(className);
		const match = (window.location.hash || '').match(prefixRe);
		if (match)
		{
			anchor = $(`[id="${$.escapeSelector(match[1])}"]`).addClass(className); // allow to match multiple identical IDs on page
			manual && anchor[0] && anchor[0].scrollIntoView();
			if (onAnchor) onAnchor(anchor);
		}
	};
	if (manual) window.history.scrollRestoration = 'manual';
	window.addEventListener('hashchange', moveToAnchor, false);
	$(moveToAnchor);
	return moveToAnchor;
});
