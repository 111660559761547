(function () {

	function ErrorReporting() {
		if (Error.stackTraceLimit < this.stackTraceLimit)
		{
			Error.stackTraceLimit = this.stackTraceLimit;
		}
		window.TracyAutoRefresh = false; // Tracy\Debugger::VERSION 2.9 FileSession
	}

	var globalState = ErrorReporting.globalState = Sim.createGlobalState();

	const isLocalUrl = (url, extraAllowedProtocols = null) => {
		const oUrl = new URL(url, window.location.href);
		return (oUrl.hostname === window.location.hostname || (!!extraAllowedProtocols && extraAllowedProtocols.includes(oUrl.protocol)));
	};

	const ajax = (url, options = {}) => new Promise((resolve, reject) => {
		const req = new XMLHttpRequest;
		req.open(options.method || 'get', url);
		req.onerror = reject;
		req.onreadystatechange = () => {
			if (req.readyState === 4)
			{
				if (req.status >= 200 && req.status < 300)
				{
					resolve(req.responseText);
				}
				else
				{
					reject(new Error(`HTTP status: ${req.status} retrieving ${url}`));
				}
			}
		};
		req.send();
	});

	ErrorReporting.prototype = {
		numberOfReportedErrors: 0,
		stackTraceLimit: 30,
		fatalError: null,
		cache: {
			sourceCache: {},
			sourceMapConsumerCache: {},
		},

		register: function () {
			this.registerJSError();
			this.registerAjaxError();
			this.registerLoadError();
			this.registerCSP();
			this.registerReportingObserver();
			globalState.change(globalState.INITIALIZING);
		},

		registerJSError: function () {
			var old = window['onerror'];
			window.onerror = this.wrap(function (msg, file, line, col, error) {
				if (Sim.redirect && Sim.redirect.isRedirectError(error)) return true;
				this.onError({
					error: error,
					msg: msg,
					file: file,
					line: line,
					col: col,
				});
				return old ? old.apply(window, arguments) : false;
			}, this);
			window.addEventListener('unhandledrejection', this.wrap(function (event) {
				if (Sim.redirect && Sim.redirect.isRedirectError(event.reason)) return;
				this.onError({
					error: event.reason,
					msg: 'Uncaught (in promise)',
				});
			}, this));
		},

		registerAjaxError: function () {
			const extractXTracyErrorLog = (jqXHR, textStatus) => {
				let header = jqXHR.getResponseHeader('X-Tracy-Error-Log');
				if (!header && Sim.config.debuggerEnabled && textStatus === 'parsererror' && jqXHR.status === 200 && jqXHR.responseText)
				{
					// X-Tracy-Error-Log was not set because headers were already sent, but we may be able to read output from Tracy
					[, header] = jqXHR.responseText.match(/\n\(stored in (.+?)\)\n/u) || [null, null];
				}
				return header;
			};
			var self = this;
			jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR) {
				var stackPromise = self.getStackTrace('*.ajax', true);
				const trigger = (jqXHR, textStatus, errorThrown) => {
					if (jqXHR.ignoreSimErrorReporting) return;
					self.onError({
						isConnectionProblem: true,
						stack: stackPromise(),
						error: options.url,
						msg: textStatus + ' ' + errorThrown,
						xTracyErrorLog: extractXTracyErrorLog(jqXHR, textStatus),
					}, {
						url: options.url,
						post: options.data,
						responseText: jqXHR.responseText,
					});
				};
				jqXHR.fail((jqXHR, textStatus, errorThrown) => {
					if (textStatus === 'abort') return;
					setTimeout(() => trigger(jqXHR, textStatus, errorThrown), 0); // after all other fail handlers, so any can set jqXHR.ignoreSimErrorReporting
				});
			});
		},

		registerLoadError: function () {
			var self = this;
			document.addEventListener('error', function (e) {
				var $el = $(e.target);
				var url = (function () {
					if ($el.is('script, img')) return $el.prop('src');
					if ($el.is('link')) return $el.prop('href');
				})();
				if (url && isLocalUrl(url, ['error:']))
				{
					self.onError({
						isConnectionProblem: true,
						stack: self.getStackTrace(null, true),
						error: url,
						msg: $el[0].nodeName + ' GET error',
					}, {
						elHtml: $el[0].outerHTML,
						elSelector: self.makeSelector($el),
					});
				}
			}, true);
		},

		registerCSP: function () {
			var report = {
				error: null,
				violations: [],
				throttle: _.throttle(this.wrap(function () {
					var errorInfo = report.error;
					var additionalData = {violations: report.violations};
					report.error = null;
					report.violations = [];
					this.onError(errorInfo, additionalData);
				}, this), 1500, {leading: false, trailing: true}),
			};
			document.addEventListener('securitypolicyviolation', this.wrap(function (event) {
				var msg =
					(event.disposition === 'report' ? '[Report Only] ' : '') +
					'Refused to load ' + event.violatedDirective + ' \'' + event.blockedURI + '\' because it violates the Content Security Policy directive ' +
					'at ' + event.sourceFile + ':' + event.lineNumber + ':' + event.columnNumber +
					(event.sample ? ' (`' + event.sample + '`) ' : ' ') +
					'for policy (' + event.disposition + ') "' + event.originalPolicy + '".'
				;
				report.error || (report.error = {
					error: msg,
					msg: 'Content Security Policy',
					file: event.sourceFile,
					line: event.lineNumber,
					col: event.columnNumber,
					isRecoverableError: event.disposition === 'report',
				});
				report.violations.push(msg);
				report.throttle();
			}, this));
		},

		registerReportingObserver()
		{
			if ('ReportingObserver' in window)
			{
				const observer = new window.ReportingObserver((reports) => {
					for (const report of reports)
					{
						if (report.type === 'csp-violation') continue; // handled with registerCSP
						this.onError({
							isRecoverableError: true,
							error: `${report.body.message || ''} ${report.body.reason || ''}`,
							msg: `${report.body.id || ''}[${report.type}]`,
							file: report.body.sourceFile,
							line: report.body.lineNumber,
							col: report.body.columnNumber,
						}, {report});
					}
				}, {buffered: true});
				observer.observe();
			}
		},

		createErrorReport: function (errorInfo, additionalData) {
			var error = errorInfo.error;
			if (!(error instanceof Error))
			{
				error = {
					name: errorInfo.msg,
					message: errorInfo.error,
					fileName: errorInfo.file,
					lineNumber: errorInfo.line,
					columnNumber: errorInfo.col,
					stack: ' at (' + errorInfo.file + ':' + (errorInfo.line || 0) + ':' + (errorInfo.col || 0) + ')',
				};
			}
			var stack = errorInfo.stack;
			if (!(stack instanceof Promise))
			{
				stack = this.getStackTrace(stack instanceof Error ? stack : (error instanceof Error ? error : '*.createErrorReport'));
			}

			var stringify = function (data) {
				return typeof data === 'string' ? data : (JSON.stringify(data) || 'undefined');
			};
			var log = {
				address: window.location.href,
				userAgent: window.navigator.userAgent,
				error: {
					name: stringify(error.name),
					message: stringify(error.message),
					nativeStack: stringify(error.stack),
					fileName: errorInfo.file || error.fileName,
					lineNumber: errorInfo.line || error.lineNumber,
					xTracyErrorLog: errorInfo.xTracyErrorLog,
				},
				stack: null,
				data: _.isObject(additionalData) ? additionalData : (additionalData = {}),
			};
			additionalData.currentHtml = this.getCurrentHtml();

			return {
				log: log,
				ready: function () {
					return stack
						.then(function (stackframes) { log.stack = stackframes; })
						.then(function () {
							additionalData.html = this.getHtml(log);
							return Promise.all(_.map(additionalData, function (value, name) {
								return Promise.resolve(value).then(function (value) { additionalData[name] = value; });
							}, this));
						}.bind(this))
						.then(function () { return Promise.resolve(log); })
					;
				}.bind(this),
			};
		},

		onError: function (errorInfo, additionalData) {
			var errorReport = this.createErrorReport.apply(this, arguments);
			if (this.ignoreError(errorReport))
			{
				return;
			}

			globalState.set(errorInfo.isRecoverableError ? globalState.WARN : globalState.ERROR);

			if (
				this.fatalError ||
				this.numberOfReportedErrors > 20 ||
				globalState.unloadManager.isUnloaded() ||
				(errorInfo.isConnectionProblem && !globalState.isConnectionReliable()) // ignore connection problem with unreliable connection
			)
			{
				if (!errorInfo.isConnectionProblem || !globalState.unloadManager.is())
				{
					globalState.addRawUnreportedError(errorReport.log);
				}
				return;
			}

			this.numberOfReportedErrors++;
			const revertUnreported = globalState.addRawUnreportedError(errorReport.log, true);

			errorReport.ready()
				.then(function (log) { return StackTrace.report(log, Sim.config.errorReporting.report); })
				.then(this.showDeveloperError.bind(this))
				.then(revertUnreported)
				.catch(function (err) {
					revertUnreported();
					globalState.addRawUnreportedError(errorReport.log);
					globalState.addRawUnreportedError(function () { return this.createErrorReport({error: err}, {}).log; }.bind(this));
					if (err instanceof Error && /^POST to .+ failed with status: 0$|^HTTP status: 0 retrieving .*$/.test(err.message))
					{
						globalState.testConnection(_.bind(this.handleFatalError, this, err, errorInfo.isRecoverableError));
						return;
					}
					this.handleFatalError(err, errorInfo.isRecoverableError);
				}.bind(this))
			;
		},

		handleFatalError: function (error, isRecoverableError) {
			if (!this.fatalError && !isRecoverableError)
			{
				this.fatalError = true;
				globalState.set(globalState.FATAL);
				alert([
					"Unfortunately you've encountered an unexpected error.",
					"In order to help us improve your experience please could you take a screenshot of this page and sent it along with the page url to simon@simproperty.com.",
					"Many thanks for your assistance and understanding.",
				].join('\n'));
			}
			window.console && console.error && console.error(error); // eslint-disable-line no-console
		},
		wrap: function (fn, context) {
			var self = this;
			return function () {
				try {
					return fn.apply(context || this, arguments);
				} catch (error) {
					self.handleFatalError(error);
				}
			};
		},

		ignoreError: ((obj) => (errorReport) => obj.isIgnored(errorReport))(new (Sim.getIgnoreErrors())(globalState)),

		showDeveloperError: function (response) {
			if (!Sim.config.debuggerEnabled) return;
			if (!response) return;
			if (this.devContainer === undefined)
			{
				this.devContainer = $('<div>')
					.attr('id', 'ErrorLogger')
					.appendTo('body')
				;
			}
			var el = $('<span>')
				.append(
					$('<span>')
						.text('#' + (this.devContainer.counter ? ++this.devContainer.counter : (this.devContainer.counter = 1)) + ' ')
						.on('click', function () {
							if (el.hasClass('open'))
							{
								el.removeClass('open');
								$('html').removeClass('openErrorLogger');
							}
							else
							{
								this.devContainer.find('> span.open').removeClass('open');
								el.addClass('open');
								$('html').addClass('openErrorLogger');
								if (!el.find('> iframe').length)
								{
									const message = _.uniqueId('ErrorReporting.showDeveloperError.tracy-bs-toggle');
									window.addEventListener('message', (e) => {
										if (e.data === message)
										{
											el.find('> span').trigger('click');
										}
									});
									const nonce = Sim.escapeHtml($('script[nonce]').prop('nonce'));
									response = response.replace(/(?<=<script)(?: nonce="\w*"|(?=>))/gui, () => ` nonce="${nonce}"`);
									const extraHtml = `
										<script nonce="${nonce}">
											document.addEventListener('click', (e) => {
												if (!e.target.closest('a[href^="editor://open"]'))
												{
													e.preventDefault();
												}
												if (e.target.closest('#tracy-bs-toggle'))
												{
													e.stopPropagation();
													window.parent.postMessage(${JSON.stringify(message)}, ${JSON.stringify(location.origin)});
												}
											}, {capture: true});
											document.addEventListener('keyup', (e) => {
												if (e.keyCode === 27 && !e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) // esc
												{
													e.stopPropagation();
													window.parent.postMessage(${JSON.stringify(message)}, ${JSON.stringify(location.origin)});
												}
											}, {capture: true});
										</script>
										<link href="${Sim.escapeHtml(Sim.config.basePath)}/public/js/development-adblock.css" rel="stylesheet" type="text/css">
									`;
									$('<iframe>')
										.prop('srcdoc', response + extraHtml)
										.attr('sandbox', 'allow-scripts allow-same-origin allow-top-navigation-to-custom-protocols')
										.appendTo(el)
									;
									response = null;
								}
							}
						}.bind(this))
				)
				.appendTo(this.devContainer)
			;
		},

		getStackTrace: function (startContext, lazy) {
			var options = {
				maxStackSize: this.stackTraceLimit,
				sourceCache: this.cache.sourceCache,
				sourceMapConsumerCache: this.cache.sourceMapConsumerCache,
				ajax: (url, ...rest) => {
					if (!isLocalUrl(url))
					{
						return Promise.reject(new Error('Cannot make ajax request to other domains because of Content Security Policy connect-src'));
					}
					return ajax(url, ...rest);
				},
			};
			options.sourceCache[window.location.href.replace(/#.*$/, '')] = this.getHtml();
			if (startContext && !(startContext instanceof Error))
			{
				var findIndex;
				options.filter = function (frame, index, array) {
					if (findIndex === undefined)
					{
						var startContextFns = _.map(_.isArray(startContext) ? startContext : [startContext], function (startContext) {
							if (startContext.indexOf('*') !== -1)
							{
								var re = new RegExp('^' + startContext.replace(/[^\w.*]/g, '.').replace(/\*\./g, '(?:*\\.|)').replace(/\*/g, '.*') + '$');
								return re.test.bind(re);
							}
							return function (functionName) { return functionName === startContext; };
						});
						findIndex  = _.findIndex(array, function (frame, index) {
							return _.some(startContextFns, function (startContext) {
								return startContext(frame.functionName || '');
							});
						});
					}
					return index > findIndex;
				};
			}
			var error = startContext instanceof Error ? startContext : null;
			if (!error)
			{
				error = (function () { try { throw new Error; } catch (er) { return er; } })();
			}
			if (!error.stack && !error['opera#sourceloc'])
			{
				error = {
					stack: ' at (' + error.fileName + ':' + (error.lineNumber || 0) + ':' + (error.columnNumber || 0) + ')',
				};
			}
			return lazy ? function () { return StackTrace.fromError(error, options); } : StackTrace.fromError(error, options);
		},

		getHtml: (function () {
			var isHtmlNeeded = function (log) {
				if (log === undefined) return true;
				var re = /#.*$/;
				var address = log.address.replace(re, '');
				if (
					(log.error.fileName && log.error.fileName.replace(re, '') === address) ||
					(!log.error.fileName && log.stack[0].fileName && log.stack[0].fileName.replace(re, '') === address) ||
					_.some(log.stack, function (frame) { return (frame.fileName && frame.fileName.replace(re, '') === address); })
				)
				{
					return true;
				}
			};
			var LazyPromise = function (factory) {
				this.factory = factory;
			};
			LazyPromise.prototype.then = function () {
				this.promise = this.promise || new Promise(this.factory);
				return this.promise.then.apply(this.promise, arguments);
			};
			var htmlPromise;
			var getHtml = function (log) {
				if (isHtmlNeeded(log))
				{
					return new LazyPromise(function (resolve, reject) {
						if (htmlPromise === undefined)
						{
							htmlPromise = ajax(Sim.config.errorReporting.getHtml);
						}
						htmlPromise.then(resolve, reject);
					});
				}
				return Promise.resolve(null);
			};
			setTimeout(function () {
				getHtml().then(_.noop, _.noop);
			}, 1000 * 60 * 10); // 10 minutes, before html expire
			return getHtml;
		})(),

		getCurrentHtml: function () {
			var html;
			if (_.isFunction(window.XMLSerializer) && XMLSerializer.prototype.serializeToString)
			{
				html = new XMLSerializer().serializeToString(document);
			}
			else
			{
				html = document.documentElement.outerHTML;
			}
			return html;
		},

		makeSelector: function ($el) {
			var defs = [
				['id', '#', ''],
				['class', '.', '', function (cl) { return _.map(cl.trim().split(/\s+/), jQuery.escapeSelector).join('.'); }],
				['name', '[name="', '"]'],
			];
			function recursive($el) {
				var out = $el[0].nodeName;
				defs.forEach(function (attr) {
					var value = $el.attr(attr[0]);
					if (value !== undefined && value !== '')
					{
						out += attr[1] + (attr[3] ? attr[3](value) : jQuery.escapeSelector(value)) + attr[2];
					}
				});
				var p = $el.parent();
				return (p.length ? recursive(p) + ' > ' : '') + out;
			}
			return recursive($el);
		},

	};

	var er = new ErrorReporting;
	er.register();

	(window.Sim || (window.Sim = {})).triggerWarning = function (message, startContext) {
		er.onError({
			isRecoverableError: true,
			stack: (message instanceof Error && !startContext) ? message : er.getStackTrace(startContext || '*.triggerWarning'),
			msg: 'Sim.triggerWarning',
			error: message,
		});
	};

	window.Sim.unloadManager = globalState.unloadManager;

})();
