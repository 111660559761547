define(['Helpers/ElementFinder'], (ElementFinder) => {

	class MultipleHellForm
	{

		#container;

		constructor(container)
		{
			this.#container = new ElementFinder(container);
			this.root = this.#container.findOne('.o-multipleHellFormPopup');
			this.calculateSums = _.debounce(this.calculateSums, 100);
			this.events();
		}

		events()
		{
			this.root.on('init', () => {
				this.calculateSums();
				this.root.find('.o-multipleHellFormPopup__enable input[type="checkbox"]').trigger('initEnable');
			});

			this.root.on('change keyup', '[data-currency] input:text', () => this.calculateSums());
			this.root.on('change', 'select[name$="[direction]"]', () => this.calculateSums());

			this.root.on('change init', '.o-multipleHellFormPopup__enable input[type="checkbox"]', (e) => this.changed(e.currentTarget));
			this.root.find('.o-multipleHellFormPopup__enable input[type="checkbox"]').trigger('initEnable');

			this.root.on('change', 'input[type="radio"][name="actions"]', () => this.updateAction());
			this.updateAction();
		}

		changed(el)
		{
			const line = $(el).closest('.o-multipleHellFormPopup__line');
			line.toggleClass('disabled', !el.checked);
			if (!el.checked && !('hiddenDisableHelper' in el))
			{
				// unchecked input[type="checkbox"] is not in POST
				this.hiddenDisableHelper = $('<input type="hidden">')
					.attr('name', el.name.replace(/\]?$/u, '__hiddenDisableHelper$&'))
					.insertAfter(el)
				;
			}
			this.updateInputDisableStatus(line);
		}

		updateInputDisableStatus(line)
		{
			const isDisabled = (line.hasClass('disabled') || line.hasClass('disabled-delete') || line.hasClass('disabled-edit'));
			line.find('input,select,textarea')
				.not('input[type="hidden"]')
				.not('.o-multipleHellFormPopup__enable input[type="checkbox"]')
				.prop('disabled', isDisabled)
			;
			this.calculateSums();
		}

		calculateSums()
		{
			const nbsp = '\u00A0';
			const container = this.root.find('.o-multipleHellFormPopup__amountSums');
			const data = {};
			this.root.find('.o-multipleHellFormPopup__line:not(.disabled):not(.disabled-edit) [data-currency] input:text').each((i, el) => {
				const currency = $(el).closest('[data-currency]').attr('data-currency');
				if (!data[currency]) data[currency] = 0;
				const direction = parseInt($(el).closest('.o-multipleHellFormPopup__line').find('select[name$="[direction]"]').val(), 10) || 1;
				data[currency] += Sim.number.parse(el.value) * direction;
			});
			container.empty();
			for (const [currency, number] of Object.entries(data))
			{
				$('<div>').text(`${Sim.number.format(number)}${nbsp}${currency} `).appendTo(container);
			}
		}

		updateAction()
		{
			const value = this.root.find('input[type="radio"][name="actions"]:checked').val();
			const isDeleteMode = value === 'delete';
			this.root.find('.o-multipleHellFormPopup__line')
				.each((i, el) => {
					const line = $(el);
					const className = line.hasClass('hasDeleteButton') ? 'disabled-delete' : 'disabled-edit';
					line.toggleClass(className, isDeleteMode);
					this.updateInputDisableStatus(line);
				})
			;
			this.#container.findSome('input[type="submit"][name="save"]').prop('disabled', isDeleteMode);
		}

	}

	return MultipleHellForm;
});
