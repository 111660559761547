define(() => {

	class TableSelectorAjaxDataLoader
	{

		constructor(options, elements, paginator)
		{
			this.options = options;
			this.elements = elements;
			this.paginator = paginator;
			this.restoreFullPages = {};
			this.ajaxes = new Map;
			this.AbortError = Sim.createCustomError('TableSelectorAjaxDataLoaderAbortError');

			this.ignoreSnippets = [`${this.options.snippetPrefix}filter-filter`];
			jQuery.nette.addSnippetHandler(`${this.options.snippetPrefix}*`, null, (el, content) => {
				if (this.ignoreSnippets.includes(el.attr('id')))
				{
					return false;
				}
				el.contents().detach(); // instead of empty so jquery data are preserved for restore
				el.append(content);
			});
		}

		loadOrRestorePage(page, whenAjaxStart)
		{
			if (!(page in this.restoreFullPages))
			{
				if (this.paginator.remote.page !== page && !this.restoreFullPages[this.paginator.remote.page])
				{
					this.restoreFullPages[this.paginator.remote.page] = Promise.resolve(this._createRestoreFromHtml());
				}
				this.restoreFullPages[page] = new Promise((resolve, reject) => {
					const finishes = whenAjaxStart();
					this._loadRemotePage(page, (payload, textStatus, jqXHR) => {
						jQuery.nette.success(payload, textStatus, jqXHR);
						this.paginator.remote.page = page;
						const restore = this._createFullRestore(payload.snippets);
						finishes(true);
						resolve(restore);
					}, (jqXHR) => {
						if (jqXHR.status === 404 && Sim.unloadManager.is()) // may happen when entity deleted before page fully loaded
						{
							jqXHR.ignoreSimErrorReporting = true;
						}
						delete this.restoreFullPages[page];
						finishes(false);
						reject(new this.AbortError(`page ${page} aborted`));
					});
				});
			}
			return this.restoreFullPages[page].then((restore) => {
				if (this.paginator.remote.page !== page)
				{
					this.paginator.remote.page = page;
					restore();
				}
			});
		}

		hasPageLoaded(page)
		{
			return page in this.restoreFullPages;
		}

		hasNoAjaxRunning()
		{
			return this.ajaxes.size === 0;
		}

		abortAllExcept(page)
		{
			for (const [prevPage, ajax] of this.ajaxes)
			{
				if (page !== prevPage)
				{
					ajax.abort();
				}
			}
		}

		_loadRemotePage(page, success, abort)
		{
			for (const [prevPage, ajax] of this.ajaxes)
			{
				const rFirst = (prevPage - 1) * this.paginator.remote.itemsPerPage;
				const rLast = (prevPage * this.paginator.remote.itemsPerPage) - 1;
				const lFirst = this.paginator.local.start;
				const lLast = this.paginator.local.start + this.paginator.local.itemsPerPage - 1;
				if ((rFirst < lFirst && rLast < lLast) || (rFirst > lFirst && rLast > lLast))
				{
					ajax.abort();
				}
			}
			const ajax = $.ajax({
				url: this.options.link,
				data: _.object([[this.options.page, page]]),
				dataType: 'json',
				success,
				error: abort,
				complete: () => this.ajaxes.delete(page),
			});
			this.ajaxes.set(page, ajax);
		}

		_createFullRestore(snippets) {
			const restoreSnippets = [];
			for (const [id, html] of Object.entries(snippets))
			{
				const el = jQuery.nette.getById(id);
				const cached = el.length ? el.contents() : html;
				restoreSnippets.push([id, cached]);
			}
			return () => {
				for (const [id, html] of restoreSnippets)
				{
					jQuery.nette.updateSnippet(id, html);
				}
			};
		}

		_createRestoreFromHtml()
		{
			const snippets = {};
			for (const el of this.elements.tableSelector.find(`[id^="${$.escapeSelector(this.options.snippetPrefix)}"]`))
			{
				const id = $(el).attr('id');
				if (!this.ignoreSnippets.includes(id))
				{
					snippets[id] = '';
				}
			}
			return this._createFullRestore(snippets);
		}

	}

	return TableSelectorAjaxDataLoader;
});
