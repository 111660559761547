
/**
 * <pre>
 *
 * 	<div id="container">
 * 		<a href="full.img"><img></a>
 * 	</div>
 *
 * 	<script>
 * 		new Gallery('#container', '> a');
 * 	</script>
 *
 * </pre>
 */
define(['libs/magnific-popup/jquery.magnific-popup'], function () {

	/**
	 * @constructor
	 * @param {(string|jQuery)}
	 * @param {?(string)}
	 */
	function Gallery(root, children) {
		if (!children) children = '> a';
		this.root = $(root);
		this.children = children;
		if (this.root.length !== 1) throw new Error;
		this.init();
	}

	Gallery.prototype.init = function () {
		this.root.magnificPopup({
			delegate: this.children,
			type: 'image',
			image: {
				verticalFit: true,
			},
			gallery: {
				enabled: true,
			},
			preload: [3, 3],
			mainClass: 'mfp-no-margins', // class to remove default margin from left and right side
			closeOnContentClick: true,
			closeBtnInside: false,
			enableEscapeKey: false,
		});
		const onEscapePress = Sim.onEscapePress(() => $.magnificPopup.close());
		this.root.on('mfpOpen', () => onEscapePress.on());
		this.root.on('mfpClose', () => onEscapePress.off());
	};

	return Gallery;
});
