define(['app/components/Popover/Popover'], (Popover) => {

	class CheckboxSearchPopover
	{

		#options;
		#data;
		#popover;

		constructor(options)
		{
			const popoverToggle = $(options.toggle);
			if (popoverToggle.length !== 1)
			{
				throw new Error;
			}
			if (popoverToggle.data('CheckboxSearchPopover'))
			{
				throw new Error;
			}
			this.#popover = Popover.init(popoverToggle);
			popoverToggle.data('CheckboxSearchPopover', this);

			this.#options = {
				onHidden: (selected, chsp) => {},
				renderItem: ($item, chsp) => {},
				afterRender: ($popoverTip, chsp) => {},
				showSelectionButtons: JSON.parse(popoverToggle.attr('data-checkbox-search-popover-show-selection-buttons') || 'false'),
				titleSelectedAndDisabled: popoverToggle.attr('data-checkbox-search-popover-title-selected-and-disabled') || null,
				titleSelected: popoverToggle.attr('data-checkbox-search-popover-title-selected') || null,
				titleDisabled: popoverToggle.attr('data-checkbox-search-popover-title-disabled') || null,
				...options,
			};

			this.#data = {temporary: new Set};
			for (const name of ['selected', 'disabled', 'topPlaced', 'defaultShown'])
			{
				const ucfName = name.charAt(0).toUpperCase() + name.slice(1);
				const attrName = name.replace(/[A-Z]/gu, ($0) => `-${$0.toLowerCase()}`);
				const attr = popoverToggle.data(`checkboxSearchPopoverValues${ucfName}`);
				const option = this.#options[`values${ucfName}`];
				if (attr && option) throw new Error(`Both [data-checkbox-search-popover-values-${attrName}] and 'options.values${ucfName}' provided, use only one way.`);
				this.#data[name] = new Set(attr || option || []);
				popoverToggle.removeData(`checkboxSearchPopoverValues${ucfName}`);
				popoverToggle.removeAttr(`data-checkbox-search-popover-values-${attrName}`);
				delete this.#options[`values${ucfName}`];
				this.#options[`${name}ValuesSet`] = Boolean(attr || option);
			}

			popoverToggle.on('hidden', () => this.#onHidden());
			popoverToggle.on('contentReady', () => this.#onContentReady());
			if (!this.#popover.isShown())
			{
				popoverToggle.one('shown', () => this.#initPopover());
			}
			else
			{
				this.#initPopover();
				if (this.#popover.isContentReady())
				{
					this.#onContentReady();
				}
			}
		}

		#initPopover()
		{
			const $tip = this.#popover.getPopoverTip();

			$tip.on('click', '.m-checkboxSearchPopover__selectionButtons > a', (e) => {
				$tip.find('.m-checkboxSearchPopover__bottomPlaced input[type="checkbox"]:not(:disabled)')
					.prop('checked', $(e.target).is('.m-checkboxSearchPopover__selectButton'))
					.trigger('change')
				;
			});

			$tip.on('change', 'input[type="checkbox"]', (e) => {
				const $input = $(e.target);
				const $item = $input.closest('.m-checkboxSearchPopover__item');
				for (const el of $tip.find('.m-checkboxSearchPopover__item').get())
				{
					const $item2 = $(el);
					if (
						$item2.data('value') === $item.data('value') &&
						!$item2.is($item) &&
						$item2.find('input[type="checkbox"]').prop('checked') !== $input.prop('checked')
					)
					{
						$item2.find('input[type="checkbox"]')
							.prop('checked', $input.prop('checked'))
							.trigger('change')
						;
					}
				}
			});

			$tip.on('click', '.m-checkboxSearchPopover__closeCheckboxSearch', () => {
				this.#popover.hide();
			});

			$tip.on('keypress', (e) => {
				if (e.keyCode === 13) // enter
				{
					e.preventDefault();
				}
			});

			$tip.on('input change keyup', 'input.search', () => this.#lookup());
		}

		getPopover()
		{
			return this.#popover;
		}

		getValues(name)
		{
			if (!this.#data[name])
			{
				throw new Error(name);
			}
			return Array.from(this.#data[name]);
		}

		hasValue(name, value)
		{
			this.#checkValue(name, value);
			return this.#data[name].has(value);
		}

		addValue(name, value)
		{
			this.#options[`${name}ValuesSet`] = true;
			if (typeof value === 'object')
			{
				for (const v of value)
				{
					this.addValue(name, v);
				}
				return;
			}
			this.#checkValue(name, value);
			this.#data[name].add(value);
		}

		deleteValue(name, value)
		{
			if (typeof value === 'object')
			{
				for (const v of value)
				{
					this.deleteValue(name, v);
				}
				return;
			}
			this.#checkValue(name, value);
			this.#data[name].delete(value);
		}

		clearValues(name)
		{
			if (!this.#data[name])
			{
				throw new Error(name);
			}
			this.#data[name].clear();
			this.#options[`${name}ValuesSet`] = false;
		}

		#checkValue(name, value)
		{
			if (!this.#data[name])
			{
				throw new Error(name);
			}
			if (this.#options.values[value] === undefined)
			{
				throw new Error(value);
			}
		}

		#onContentReady()
		{
			const $tip = this.#popover.getPopoverTip();
			$tip.find('input.search').trigger('focus')
			this.#lookup();
		}

		#onHidden()
		{
			this.#acceptSelection();
			this.#options.onHidden(this.getValues('selected'), this);
		}

		#acceptSelection(permanent = true)
		{
			if (permanent)
			{
				for (const value of this.getValues('temporary'))
				{
					this.deleteValue('temporary', value);
					this.addValue('selected', value);
				}
			}
			const $tip = this.#popover.getPopoverTip();
			for (const el of $tip.find('.m-checkboxSearchPopover__item').get())
			{
				const $el = $(el);
				const value = $el.data('value');
				const checked = $el.find('input[type="checkbox"]').prop('checked');
				if (checked)
				{
					this.addValue(permanent ? 'selected' : 'temporary', value);
				}
				else if (!checked)
				{
					this.deleteValue(permanent ? 'selected' : 'temporary', value);
				}
			}
		}

		#lookup()
		{
			const $tip = this.#popover.getPopoverTip();
			const searchInput = $tip.find('input.search');
			const value = searchInput.val();
			if (searchInput.data('lastSearchValue') === value)
			{
				return;
			}
			this.#acceptSelection(false);
			const query = value.toLowerCase();
			if (query !== '')
			{
				const values = Object.entries(this.#options.values).filter(([, label]) => label.toLowerCase().indexOf(query) > -1);
				values.sort(([, a], [, b]) => a.toLowerCase().indexOf(query) - b.toLowerCase().indexOf(query));
				this.#render(values.slice(0, 10).map(([v]) => v), query);
			}
			else
			{
				let values = this.getValues('defaultShown');
				if (values.length === 0 && !this.#options.defaultShownValuesSet)
				{
					values = Object.keys(this.#options.values).slice(0, 10);
				}
				this.#render(values);
			}
			searchInput.data('lastSearchValue', value);
		}

		#render(values, query = '')
		{
			const $tip = this.#popover.getPopoverTip();

			const topElements = this.#createItemElements(this.getValues('topPlaced'), query);
			$tip.find('.m-checkboxSearchPopover__topPlaced').html(topElements).toggle(!!topElements.length);

			const bottomElements = this.#createItemElements(values, query);
			$tip.find('.m-checkboxSearchPopover__bottomPlaced').html(bottomElements);

			$tip.find('.m-checkboxSearchPopover__selectionButtons').toggle(Boolean(
				this.#options.showSelectionButtons &&
				bottomElements.length
			));

			this.#options.afterRender($tip, this);

			this.#popover.redraw();
		}

		#createItemElements(values, query = '')
		{
			let $elements = $();
			for (const value of values)
			{
				const label = this.#options.values[value];
				if (label === undefined)
				{
					throw new Error(value);
				}
				const selected = this.hasValue('selected', value) || this.hasValue('temporary', value);
				const disabled = this.hasValue('disabled', value);
				const $input = $('<input type="checkbox" value="1">')
					.prop('checked', selected)
					.prop('disabled', disabled)
				;
				const $label = $('<label class="checkbox inline">')
					.append($input)
					.append(Sim.BootstrapTypeaheadHighlighterFix.highlighter(label, query))
				;
				const $el = $('<li class="m-checkboxSearchPopover__item">')
					.data('value', value)
					.attr('title', this.#getItemTitle(selected, disabled))
					.toggleClass('disabled', disabled)
					.append($label)
				;
				this.#options.renderItem($el, this);
				$elements = $elements.add($el);
			}
			return $elements;
		}

		#getItemTitle(selected, disabled)
		{
			if (selected && disabled)
			{
				return this.#options.titleSelectedAndDisabled;
			}
			if (selected && !disabled)
			{
				return this.#options.titleSelected;
			}
			if (!selected && disabled)
			{
				return this.#options.titleDisabled;
			}
			return null;
		}

	}

	return CheckboxSearchPopover;
});
