define(['app/components/AClickableArea/AClickableArea'], function (AClickableArea) {

	/**
	 * initHellover is customized initBootstrapPopovers for Hellovers (show static on click, specific position and DOM placement)
	 */
	function Hellover(template, url, jsonp) {
		this.setup = {
			expand: {},
		};
		this.contents = {};
		this.template = $('<div>').html(template).contents();
		this.url = url;
		this.jsonp = jsonp;

		this.initEvents();
		this.loadContent();

		new AClickableArea({
			container: this.table,
		});
	}

	Hellover.prototype = {

		initEvents: function () {
			var self = this;
			var slot = $('.hellover-slot');
			if (slot.length !== 1) throw new Error;
			var table = this.table = slot.closest('table');
			if (table.length !== 1) throw new Error;
			table.data('hellover-static', false); // hellover-static is used for behavior decision between hover and static state
			var toggles = table.find('.hellover-toggle').filter(':not(.hellover-initialized)');
			if (toggles.length === 0) throw new Error;

			const onEscapePress = Sim.onEscapePress();

			toggles.each(function(index) {
				var el = $(this);
				el.one('init', function() {
					var hellover = self.template.clone();
					hellover.find('.popover-title .actual').append(el.data('title')); // content filling

					self.getContent(el.data('month'), function (content) {
						var contentContainer = hellover.find('.popover-content');
						contentContainer.find('.content-loading').remove();
						contentContainer.append(content);
						if (el.is('.active'))	{
							hellover.trigger('show');
						}
						Sim.init(hellover);
					});

					var monthCellElements = el.closest('table').find('.hellover-toggle[data-month="' + el.data('month') + '"]');
					hellover.on('show', function () {
						monthCellElements.addClass('active');
					});

					hellover.on('mouseleave', () => {
						if(!table.data('hellover-static'))	{
							hellover.detach(); // Hide from user, but keep associated events and data
							toggles.removeClass('active');
						}
					});
					hellover.on('click', () => { // enable static position
						table.data('hellover-static', true);
						hellover.addClass('hellover-static');
						onEscapePress.on();
					});
					// Stupid next/prev month switching based on DOM structure
					(function () {
						if (toggles.length % 2) throw new Error('is odd');
						var hasPrev = !(index === 0 || index === (toggles.length / 2));
						var hasNext = !(index === (toggles.length - 1) || index === ((toggles.length / 2) - 1));
						if (!hasPrev)
						{
							hellover.find('.popover-title .pagination .prev').parent().addClass('disabled');
						}
						if (!hasNext)
						{
							hellover.find('.popover-title .pagination .next').parent().addClass('disabled');
						}
						hellover.on('click', '.popover-title .pagination a', function (e) {
							e.preventDefault();
							if (hasPrev && $(this).hasClass('prev'))
							{
								toggles.eq(index-1).trigger('click');
							}
							else if (hasNext && $(this).hasClass('next'))
							{
								toggles.eq(index+1).trigger('click');
							}
						});
					})();

					/* Expand rows handling */
					hellover.on('click', '.a-clickableArea__clickAction', function (e) {
						e.preventDefault();
						var contact = $(this).closest('tbody').data('contract');
						self.setup.expand[contact] = !self.setup.expand[contact];
						hellover.trigger('show.expandRows');
					});
					hellover.on('show.expandRows', function () {
						var tbodies = hellover.find('tbody[data-contract]');
						$.each(self.setup.expand, function (contract, show) {
							tbodies.filter('[data-contract="' + contract + '"]').filter(':has(.expandRows a)').toggleClass('open', show);
						});
					});

					// Store as data attribute
					el.data('hellover', hellover);
					el.addClass('hellover-initialized');
				});
			});

			// User actions on toggles
			toggles.on('mouseenter', function() {
				if(table.data('hellover-static')) return; // Switch only in hover state, not static
				var el = $(this);
				el.trigger('init');
				el.addClass('hover'); // show delay decision variable
				setTimeout(function(){
					if(el.hasClass('hover')){ // mouse pointer is at the same position as 200ms before, show hellover then
						slot.children().detach(); // sensitively clear old content
						slot.append(el.data('hellover')); // insert stored jQ object
						toggles.removeClass('active'); // clear old indicators
						el.data('hellover').trigger('show');
					}
				}, 200);
			});
			toggles.on('mouseleave', function() {
				$(this).removeClass('hover'); // mouse pointer is somewhere else, prevents hellover appear
			});
			toggles.on('click', function() {
				var el = $(this);
				el.trigger('init');
				if(table.data('hellover-static')){ // switch displayed hellover to new one, static state enabled
					slot.children().removeClass('hellover-static').detach();
					slot.append(el.data('hellover').addClass('hellover-static'));
					toggles.removeClass('active');
					el.data('hellover').trigger('show');
				}
				// enable static positon and make actual hellover static
				else {
					table.data('hellover-static', true);
					table.find('.o-hellover').addClass('hellover-static');
					onEscapePress.on();
				}
			});

			// Hide actual hellover and disable static position
			var disableStaticAndHide = function() {
				table.data('hellover-static', false);
				slot.find('.o-hellover').removeClass('hellover-static').detach();
				toggles.removeClass('active');
				onEscapePress.off();
				return false;
			};
			slot.on('click', '[data-dismiss="hellover"]', disableStaticAndHide);
			onEscapePress.addThen(disableStaticAndHide);

			Sim.filterHoverElements(toggles).trigger('mouseenter'); // melo hover pred inicializaci hellover
		},

		getContent: function (month, callback) {
			if (this.contents[month] === undefined)
			{
				setTimeout(function () {
					this.getContent(month, callback);
				}.bind(this), 200);
				return;
			}
			callback(this.contents[month]);
		},

		loadContent: function (month, callback) {
			var load = _.once(function () {
				this.table.off('.Hellover_loadContent');
				window[this.jsonp] = function (month, content) {
					this.contents[month] = content;
				}.bind(this);
				var script = document.createElement('script');
				script.src = this.url;
				script.onload = function () {
					delete window[this.jsonp];
				}.bind(this);
				document.head.appendChild(script);
			}.bind(this));
			this.table.one('mouseenter.Hellover_loadContent click.Hellover_loadContent', load);
		},

	};

	return Hellover;
});
