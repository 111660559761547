
define(['app/components/FormControls/FilesUploadWithGrid/FilesUploadWithGrid'], function (FilesUploadWithGrid) {

	function OfferFormImages (main, children) {
		this.initMain(this.getFilesUpload(main));
		_.each($(children), function (child) {
			var childRootEl = $(child);
			this.initChild(this.getFilesUpload(childRootEl.find('.fileupload.filesGrid')), childRootEl);
		}, this);
		this.hideChildConfirm = function (selectedImages, removeCallback, userData) {};
	}

	OfferFormImages.prototype = {

		getFilesUpload: function (selector) {
			var el = $(selector);
			if (el.length !== 1) throw new Error;
			var filesUpload = el.data('filesUpload');
			if (!(filesUpload instanceof FilesUploadWithGrid)) throw new Error;
			return filesUpload;
		},

		initMain: function (main) {
			this.main = main;
			this.children = [];
			this.childUploaded = null;
			(function (self) {
				var wrap = function (old, use, args, fileId) {
					var result = old.apply(this, args);
					_.each(self.getInChildren(fileId), function (itemEl) {
						self.useByItemEl(itemEl, use);
					});
					return result;
				};
				main.addFile = _.wrap(_.partial(wrap, main.addFile, true), function (func, file) {
					return func.call(this, Array.prototype.slice.call(arguments, 1), file.id);
				});
				main.deleteFile = _.wrap(_.partial(wrap, main.deleteFile, false), function (func, fileId) {
					return func.call(this, Array.prototype.slice.call(arguments, 1), fileId);
				});
			})(this);
			main.items.on('mouseenter', '> [data-id]', _.bind(function (event) {
				if (main.items.find('> .ui-sortable-helper').length === 0)
				{
					this.getInChildren(event.currentTarget.getAttribute('data-id')).addClass('glow');
				}
			}, this));
			main.items.on('mouseleave', '> [data-id]', _.bind(function (event) {
				this.getInChildren(event.currentTarget.getAttribute('data-id')).removeClass('glow');
			}, this));
			main.items.on('sortstart', _.bind(function (event, ui) {
				this.getInChildren(ui.item.attr('data-id')).addClass('glow2');
			}, this));
			main.items.on('sortstop', _.bind(function (event, ui) {
				this.getInChildren(ui.item.attr('data-id')).removeClass('glow2');
			}, this));
		},

		initChild: function (childFilesUpload, childRootEl) {
			if (this.childUploaded !== null) throw new Error;

			var child = {
				filesUpload: childFilesUpload,
				rootEl: childRootEl,
				isShowed: true,
				isInit: false,
			};

			var useAll = childRootEl.find('a.useAll');
			var toggleSelectedUnthrottled = function () {
				useAll.toggleClass('has', childFilesUpload.items.find('label input:checkbox:checked').length !== 0);
			};
			child.toggleSelected = _.throttle(toggleSelectedUnthrottled, 300);
			useAll.on('click', function () {
				var has = useAll.hasClass('has');
				var items = childFilesUpload.items.find('label input:checkbox');
				if (has) items = $(items.get().reverse()); // zachova pozadi pri opakovanem pridani/odebrani
				items.prop('checked', !has).trigger('change');
				toggleSelectedUnthrottled();
				return false;
			});

			this.children.push(child);

			_.each(childFilesUpload.items.children('[data-id]'), this.initChildItem, this);

			if (childRootEl.is('[data-type="uploaded"]'))
			{
				this.childUploaded = child;
				child.toggleShow = _.throttle(function () {
					var show = childFilesUpload.items.children('[data-id]').length !== 0;
					child.isShowed = !!show;
					childRootEl.toggleClass('hide', !show);
				}, 300);
				_.each(this.main.items.children('[data-id]'), function (itemEl) {
					this.addOrRemoveFromUploaded(true, $(itemEl).data('file'));
				}, this);
				this.main.addFile = _.wrap(this.main.addFile, _.partial(function (self, func, file) {
					var itemEl = func.apply(this, Array.prototype.slice.call(arguments, 2));
					self.addOrRemoveFromUploaded(true, $(itemEl).data('file'));
					return itemEl;
				}, this));
				child.toggleShow();
			}
			else
			{
				childRootEl.on('show.images', _.bind(function (e, userData) {
					var show = !!userData.has;
					var isInit = !child.isInit++;
					if (child.isShowed === show) return;
					child.isShowed = show;
					childRootEl.toggleClass('hide', !show);

					if (this.childUploaded)
					{
						_.each(childFilesUpload.items.children('[data-id]'), function (itemEl) {
							this.addOrRemoveFromUploaded(!show, $(itemEl).data('file'));
						}, this);
					}
					if (!show && !isInit)
					{
						var selectedItems = childFilesUpload.items.children('[data-id]').has('label input:checkbox:checked');
						if (selectedItems.length !== 0)
						{
							_.defer(this.hideChildConfirm, _.map(selectedItems.find('img'), _.property('src')), _.bind(function () {
								_.each(childFilesUpload.items.children('[data-id]'), function (itemEl) {
									var fileId = itemEl.getAttribute('data-id');
									if (this.main.hasFile(fileId))
									{
										this.useByItemEl(itemEl, false);
									}
								}, this);
							}, this), userData);
						}
					}
				}, this));
			}
		},

		initChildItem: function (itemEl) {
			var item = $(itemEl);
			var template = '<label><input type="checkbox"> use</label>';
			var use = $(template);
			item.append(use);
			var file = item.data('file');
			var checkbox = use.find('input:checkbox');
			var useCallback = _.bind(this.use, this, file, item, checkbox);
			checkbox.on('change', function () {
				useCallback(this.checked);
			});
			useCallback(this.main.hasFile(file.id));
			item.on('mouseenter', _.bind(function () {
				this.getInMain(file.id).addClass('glow');
			}, this));
			item.on('mouseleave', _.bind(function () {
				this.getInMain(file.id).removeClass('glow');
			}, this));
		},

		addOrRemoveFromUploaded: function (add, file) {
			var has = this.childUploaded.filesUpload.hasFile(file.id);
			if (!has && add)
			{
				if (this.main.hasFile(file.id) && this.getInChildren(file.id, true).length === 0)
				{
					var el = this.childUploaded.filesUpload.addFile(file);
					this.initChildItem(el);
				}
			}
			else if (has && !add)
			{
				this.childUploaded.filesUpload.deleteFile(file.id);
			}
			this.childUploaded.toggleShow();
		},

		useByItemEl: function (itemEl, checked) {
			var item = $(itemEl);
			if (item.length !== 1) throw new Error;
			if (!item.attr('data-id')) throw new Error;
			var file = item.data('file');
			var checkbox = item.find('label input:checkbox');
			this.use(file, item, checkbox, checked);
		},

		use: function (file, item, checkbox, checked) {
			item.toggleClass('selected', checked);
			checkbox.prop('checked', checked);

			var has = this.main.hasFile(file.id);
			if (checked && !has)
			{
				var el = this.main.addFile(file, item.data('OfferFormImages.order'));
				el.addClass('glow2');
				setTimeout(function () { el.removeClass('glow2'); }, 500);
				if (this.$isHover(item))
				{
					el.addClass('glow');
				}
				item.removeData('OfferFormImages.order');
			}
			else if (!checked && has)
			{
				var order = this.main.deleteFile(file.id);
				item.data('OfferFormImages.order', order);
			}
			this.main.updateIfSelected();
			_.invoke(this.children, 'toggleSelected');
		},

		getInMain: function (fileId) {
			return this.main.items.find('> [data-id="' + fileId + '"]');
		},

		getInChildren: function (fileId, onlyShowed) {
			var result = $();
			_.each(this.children, function (child) {
				if (onlyShowed && child.isShowed === false) return;
				result = result.add(child.filesUpload.items.find('> [data-id="' + fileId + '"]'));
			});
			return result;
		},

		$isHover: ($el) => !!Sim.filterHoverElements($el).length,

	};

	return OfferFormImages;
});
