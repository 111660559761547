(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.require = {

		/**
		 * Sync when deps is already loaded, otherwise async.
		 * @param {(string|string[])}
		 * @param {function(object|object[])}
		 * @param {function(Error)}
		 */
		callback(deps, callback, onError = undefined)
		{
			if (this._req)
			{
				const single = typeof deps === 'string';
				this._reqSync(single ? [deps] : deps, single ? callback : (...a) => callback(a), onError);
			}
			else
			{
				this._queue.add(() => this.callback(deps, callback, onError));
			}
		},

		/**
		 * @param {(string|string[])}
		 */
		promise(deps)
		{
			return new Promise((resolve, reject) => this.callback(deps, resolve, reject));
		},

		/**
		 * You probably don't want to use this function. This is only for situations where `await promise()` or `callback()` can't be used.
		 * Returns dependencies synchronously, throw error if any dependency is not loaded yet (see onNotLoadedError to overwrite this behaviour).
		 * @param {(string|string[])}
		 * @param {function(Error, (object|object[])): (undefined|*)}
		 * @return {(object|object[])}
		 */
		get(deps, onNotLoadedError = undefined)
		{
			const single = typeof deps === 'string';
			deps = single ? [deps] : deps;
			const res = deps.map((dep) => this._syncOptional(dep));
			const errors = Object.entries(res).filter(([, obj]) => obj === undefined).map(([k]) => deps[k]);
			const result = single ? res[0] : res;
			if (errors.length)
			{
				const err = new Error(
					`Sim.require.get(): Module name '${errors.join(', ')}' has not been loaded yet, ` +
					'you probably wanted to use `await Sim.require.promise()` or `Sim.require.callback()` or put module into dependencies.'
				);
				const errResult = onNotLoadedError === undefined ? undefined : onNotLoadedError(err, result);
				if (errResult === undefined)
				{
					throw err;
				}
				return errResult;
			}
			return result;
		},

		/**
		 * Returns a single dependency synchronously,
		 * may return undefined if dependency is not loaded yet,
		 * but does not try to load dependency, except for development to generate an error.
		 * @param {string}
		 * @return {(object|undefined)}
		 */
		_syncOptional(dep)
		{
			dep = String(dep);
			let obj;
			try { obj = this._req(dep); } catch (err) {} // only if already loaded
			if (obj === undefined)
			{
				if (this._req)
				{
					const modId = this._req.context.makeModuleMap(dep).id;
					const isBundled = (this._req.specified(modId) && !this._req.defined(modId)); // does not cause request
					if (isBundled)
					{
						this._reqSync([modId], (o) => (obj = o), () => {});
					}
				}
				if (Sim.config.debuggerEnabled && !Sim.unloadManager.is())
				{
					this.callback(dep, () => {}); // mainly to cause error during development, but will load dep for later
				}
			}
			return obj;
		},

		/**
		 * Allow define module async. That's either before require is even loaded or with async resolve function or with returning Promise.
		 * @param depName {string}
		 * @param deps {(string|string[])}
		 * @param factory {(function(function(obj), function(err))|function():Promise)|object}
		 */
		defineAsync(depName, deps, factory)
		{
			depName = String(depName);
			if (this._req)
			{
				if (!this.defineAsync.factories)
				{
					window.define('defineAsync', [], () => ({load: (name, req, onload, config) => {
						const [deps2, factory2] = this.defineAsync.factories.get(name) || [];
						this.defineAsync.factories.delete(name);
						const resolve = (obj) => {
							if (!onload) throw new Error(`resolve or reject for ${name} called multiple times (supports: return value, promise, or resolve function)`);
							onload(obj);
							onload = undefined;
							this._req.undef(`defineAsync!${name}`);
						};
						const reject = (err) => {
							onload.error(err);
							onload = undefined;
						};
						this.callback(deps2, (...args) => {
							try
							{
								const result = factory2(...args, resolve, reject);
								if (result !== undefined)
								{
									result instanceof Promise ? result.then(resolve, reject) : resolve(result);
								}
							}
							catch (err)
							{
								reject(err);
							}
						}, reject);
					}}));
					this.defineAsync.factories = new Map;
				}
				this.defineAsync.factories.set(depName, [deps, factory]);
				window.define(depName, [`defineAsync!${depName}`, ...(typeof deps === 'string' ? [deps] : deps)], (obj) => obj);
			}
			else
			{
				this._queue.add(() => this.defineAsync(depName, deps, factory));
			}
		},

		_reqSync(...args)
		{
			const req = this._req;
			if (req)
			{
				const {nextTick} = req.context;
				req.context.nextTick = (fn) => { fn(); };
				try
				{
					return req(...args);
				}
				finally
				{
					req.context.nextTick = nextTick;
				}
			}
		},

		init()
		{
			this._queue && this._queue.size && this._req; // eslint-disable-line @babel/no-unused-expressions
			delete this.init;
		},

		_queue: new Set,

		get _req()
		{
			const req = window.requirejs;
			if (req)
			{
				const context = req.s.contexts['_'];
				if (context)
				{
					context.require.context = context;
					delete this._req;
					this._req = context.require;
					const queue = this._queue;
					delete this._queue;
					for (const fn of queue) fn();
					return this._req;
				}
			}
			return null;
		},

	};

})();
