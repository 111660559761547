
define(function () {
	var LocationSearch = function (root, ajaxUrl, onSelect) {
		this.ajaxUrl = ajaxUrl;
		this.onSelect = onSelect;
		this.input = root.find('input.search-query');
		this.dropdown = root.find('.search-dropdown');
		this.template = this.dropdown.children('.template').detach().removeClass('template');
		_.each(['focus', 'blur', 'keyup', 'keydown'], function (event) {
			this.input.on(event, _.bind(this[event], this));
		}, this);
		this.input.on('keyup cut copy paste', _.bind(this.change, this));
		this.dropdown.on('click', '.m-dropdownMenu__row.item', _.bind(this.select, this));
		this.dropdown.on('mouseenter', '.m-dropdownMenu__row.item', _.bind(this.mouseenter, this));
		this.dropdown.on('mouseleave', '.m-dropdownMenu__row.item', _.bind(this.mouseleave, this));
		root.data('locationSearch', this);
	};
	LocationSearch.prototype = {
		focus: function () {
			this.focused = true;
			this.show();
			this.change();
		},
		blur: function () {
			this.focused = false;
			if (!this.mousedover && this.shown) this.hide();
		},
		keydown: function (e) {
			switch (e.which) {
				case 9: // tab
				case 13: // enter
				case 27: // escape
					e.preventDefault();
					break;

				case 38: // up arrow
					e.preventDefault();
					if (this.shown)
					{
						var prev = this.getActive().removeClass('active').prev();
						if (!prev.length) prev = this.dropdown.children('.m-dropdownMenu__row.item:last');
						prev.addClass('active');
					}
					break;

				case 40: // down arrow
					e.preventDefault();
					if (this.shown)
					{
						var next = this.getActive().removeClass('active').next();
						if (!next.length) next = this.dropdown.children('.m-dropdownMenu__row.item:first');
						next.addClass('active');
					}
					break;
			}
		},
		keyup: function (e) {
			switch (e.which) {
				case 40: // down arrow
				case 38: // up arrow
				case 16: // shift
				case 17: // ctrl
				case 18: // alt
					break;

				case 9: // tab
					if (this.shown) this.getActive().trigger('click');
					break;
				case 13: // enter
					if (this.timeout)
					{
						this.lastValue = undefined;
						this.change(null, true);
					}
					if (this.shown) this.getActive().trigger('click');
					break;

				case 27: // escape
					this.hide();
					break;
			}

			e.stopPropagation();
			e.preventDefault();
		},

		change: function (e, immediate) {
			var value = this.input.val();
			if (!value) this.hide();
			if (this.lastValue === value || !value) return;
			clearTimeout(this.timeout);
			this.timeout = undefined;
			if (this.request)
			{
				this.request.abort();
				this.request = undefined;
			}
			this.lastValue = value;
			this.render([]);
			this.dropdown.removeClass('empty').addClass('wait');
			this.timeout = setTimeout(_.bind(function () {
				this.timeout = undefined;
				this.show();
				this.request = $.ajax({
					context: this,
					url: this.ajaxUrl,
					data: {query: value},
					dataType: 'json',
					success: function (data) {
						this.render(data);
					},
					error: function () {
						this.dropdown.removeClass('empty wait').addClass('error');
					},
					complete: function () {
						this.request = undefined;
					},
				});

			}, this), immediate ? 1 : 2000);
		},

		render: function (data) {
			this.dropdown.removeClass('error wait');
			this.dropdown.toggleClass('empty', !data.length);
			this.dropdown.children('.m-dropdownMenu__row.item').remove();

			_.each(data, function (item) {
				this.template.clone()
					.data('item', item)
					.find('.text').text(item.text).end()
					.appendTo(this.dropdown)
				;
			}, this);
		},
		select: function (e) {
			e.stopPropagation();
			e.preventDefault();
			this.input.trigger('blur');
			this.hide();
			this.getActive().removeClass('active');
			$(e.currentTarget).addClass('active');

			var item = $(e.currentTarget).data('item');
			this.onSelect.call(item, item);
		},
		show: function () {
			if (this.focused)
			{
				this.shown = true;
				this.dropdown.show();
			}
		},
		hide: function () {
			this.shown = false;
			this.dropdown.hide();
			this.getActive().removeClass('active');
		},

		mouseenter: function (e) {
			this.mousedover = true;
			this.getActive().removeClass('active');
			$(e.currentTarget).addClass('active');
		},

		mouseleave: function (e) {
			this.mousedover = false;
			this.getActive().removeClass('active');
			if (!this.focused && this.shown) this.hide();
		},

		getActive()
		{
			return this.dropdown.children('.m-dropdownMenu__row.item.active');
		},

	};

	return LocationSearch;
});
