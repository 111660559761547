(function () {

	var Sim = window.Sim || (window.Sim = {});

	Sim.number = {
		/** @see Sim\Model\RoundingHelper::parseNumber */
		parse: function (string, empty, aNaN) {
			if (typeof string === 'number' && _.isFinite(string))
			{
				return string;
			}
			string = (string === null || string === undefined) ? '' : string.toString();
			string = string
				.replace(/\s+/gu, '')
				.replace(/[\u200E\u200F\u061C\u202A\u202D\u202B\u202E\u202C\u2066\u2067\u2068\u2069]+/gu, '') // Sim\Bidi
				.replace(/^[\u002B\uFF0B\uFB29]+([\d.,]+)$/u, '$1') // +0
				.replace(/^([\d.,]+)[\u002B\uFF0B\uFB29]+$/u, '$1') // 0+
				.replace(/^([\d.,]+)[\u002D\u2212\uFE63\uFF0D]$/u, '-$1') // 0-
				.replace(/^[\u002D\u2212\uFE63\uFF0D]([\d.,]+)$/u, '-$1') // -0
				.replace(/[.,]$/, '$&0')
			;
			/^-?\d+(?:[,]\d{3})+[.]\d+$/.test(string) && (string = string.replace(/[,]/g, ''));
			/^-?\d+(?:[.]\d{3})+[,]\d+$/.test(string) && (string = string.replace(/[.]/g, ''));
			string = string.replace(/[,]/g, '.');
			if (string === '')
			{
				return empty === undefined ? 0 : empty;
			}
			if (/^-?\d*[.]?\d+$/.test(string))
			{
				return parseFloat(string) || 0;
			}
			return aNaN === undefined ? (empty === undefined ? 0 : empty) : aNaN;
		},
		/** @see Sim\TemplateHelpers::number */
		format: function (float, decimals) {
			return (Sim.number.format = Sim.number.createFormatFunction(Sim.config.numberFormat))(float, decimals);
		},
		createFormatFunction: function ([decPoint, thousandsSep, minus]) {
			var reS = /\d(?=(\d{3})+\b)/g;
			var reR = '$&' + thousandsSep;
			const [re0S, re0R] = [/^0+([^0]|0$)/u, '$1'];
			const normalizeFloat = (input) => {
				if (input === null)
				{
					return 0;
				}
				else if (typeof input === 'number' && _.isFinite(input))
				{
					return input;
				}
				else if (typeof input === 'string')
				{
					const float = parseFloat(input);
					if (!isNaN(input - float))
					{
						return float;
					}
				}
				throw new Error(`not a number: ${(typeof input === 'number' || typeof input === 'string') ? input : typeof input}`);
			};
			const roundAbsolute = (float, decimals) => {
				const num = parseFloat(`1e${decimals}`);
				return Math.round((Math.abs(float) + Number.EPSILON) * num) / num;
			};
			const roundAndFormat = (float, decimals) => {
				const rounded = Math.round((Math.abs(float) + Number.EPSILON) * parseFloat(`1e${decimals}`));
				if (!Number.isSafeInteger(rounded))
				{
					throw new Error(`not a safe number: ${rounded}`);
				}
				const string = rounded !== 0 ? rounded.toString(10) : '0'.repeat(Math.max(decimals + 1, 1));
				const [prefix, suffix] = (float < 0 && rounded !== 0) ? minus : ['', ''];
				const stringInteger = decimals > 0 ? string.slice(0, -decimals) : string + '0'.repeat(Math.abs(decimals));
				const stringFraction = decimals > 0 ? string.slice(-decimals) : '';
				return (
					prefix +
					(stringInteger !== '' ? stringInteger.replace(re0S, re0R).replace(reS, reR) : '0') +
					(stringFraction !== '' ? decPoint + stringFraction : '') +
					suffix
				);
			};
			return (float, decimals = 2) => {
				float = normalizeFloat(float);

				if (Array.isArray(decimals) && Object.keys(decimals).join() === '0,1')
				{
					const [decimalsMin, decimalsMax] = decimals;
					const max = roundAbsolute(float, decimalsMax);
					for (decimals = decimalsMin; decimals <= decimalsMax; decimals++)
					{
						const min = roundAbsolute(float, decimals);
						if (min === max)
						{
							break;
						}
					}
					// decimals is being overridden in for-loop
				}

				return roundAndFormat(float, decimals);
			};
		},
	};

	Sim.string = {
		truncate: function (s, maxLen) {
			var append = '…';
			if (s.length > maxLen)
			{
				maxLen = maxLen - append.length;
				return maxLen < 1 ? append : s.substr(0, maxLen) + append;
			}
			return s;
		},
	};

	/** @see Sim\TemplateHelpers::date */
	Sim.formatDate = function (date) {
		return $.datepicker.formatDate(Sim.config.dateFormat, date);
	};

	Sim.loading = function (el, className) {
		if (el === undefined) el = 'html';
		if (className === undefined) className = 'loading';
		var klass = _.uniqueId(className + '-');
		var $el = $(el);
		$el.addClass(klass);
		return function () {
			$el.removeClass(klass);
		};
	};

	Sim.createCustomError = function createCustomError(customConstructor, parentClass = Error, errorName) {
		if (typeof customConstructor === 'string')
		{
			errorName = customConstructor;
			customConstructor = function (...args) { return this._customErrorSuper(...args); };
		}
		Object.setPrototypeOf(customConstructor, parentClass);
		customConstructor.prototype = Object.create(parentClass.prototype, {
			_customErrorSuper: {
				value: function () {
					var instance = parentClass.apply(this, arguments);
					_.extendOwn(instance, this);
					Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
					parentClass.captureStackTrace && parentClass.captureStackTrace(instance, customConstructor);
					return instance;
				},
				enumerable: false,
				writable: false,
				configurable: false,
			},
			constructor: {
				value: customConstructor,
				enumerable: false,
				writable: true,
				configurable: true,
			},
			name: {
				value: errorName || customConstructor.name,
				enumerable: false,
				writable: true,
				configurable: true,
			},
		});
		return customConstructor;
	};

	Sim.redirect = (function () {
		var klass;
		function redirect(url, replace = false) {
			klass !== undefined || (klass = function () {
				return Sim.createCustomError('RedirectAbortError');
			}());
			if (replace)
			{
				window.location.replace(url);
			}
			else
			{
				window.location.href = url;
			}
			throw new klass("Redirect: " + url);
		}
		redirect.isRedirectError = function isRedirectError(error) {
			return (klass !== undefined && error instanceof Error && error instanceof klass);
		};
		return redirect;
	})();

	Sim.idleDebounce = (callback, debounceWait, maximumWait = 1000) => {
		let timeoutId;
		let idleId;
		const {requestIdleCallback = (fn, opt) => setTimeout(() => fn({}), 0)} = window;
		const {cancelIdleCallback = (id) => clearTimeout(id)} = window;
		const cancel = () => {
			timeoutId && clearTimeout(timeoutId);
			idleId && cancelIdleCallback(idleId);
			timeoutId = idleId = null;
		};
		const laterIdle = (args, idleDeadline) => {
			idleId = null;
			callback(...args, idleDeadline);
		};
		const laterTimeout = (args) => {
			timeoutId = null;
			idleId && cancelIdleCallback(idleId);
			idleId = requestIdleCallback((idleDeadline) => laterIdle(args, idleDeadline), {timeout: maximumWait});
		};
		const startTimeout = (args) => {
			cancel();
			timeoutId = setTimeout(() => laterTimeout(args), debounceWait);
		};
		const debounced = Number(debounceWait) === 0 ? (...args) => laterTimeout(args) : (...args) => startTimeout(args);
		debounced.cancel = cancel;
		return debounced;
	};

	Sim.onResize = (() => {
		const onElementsResize = (fn, $els) => {
			if ('ResizeObserver' in window)
			{
				let initRan = false;
				const observer = new ResizeObserver(() => (initRan ? fn() : (initRan = true)));
				observer.observe(document.documentElement);
				if ($els)
				{
					$els.parents().each((i, el) => observer.observe(el));
					$els.each((i, el) => observer.observe(el));
				}
				return () => observer.disconnect();
			}
			return () => {};
		};
		const onWindowResize = (fn) => {
			window.addEventListener('resize', fn, {passive: true});
			return () => window.removeEventListener('resize', fn, {});
		};
		const createTriggerPreventCallback = (fn) => (event) => {
			if (event && !event.isTrusted)
			{
				createTriggerPreventCallback.lastManualTrigger = true;
			}
			try
			{
				fn();
			}
			finally
			{
				createTriggerPreventCallback.lastManualTrigger = false;
			}
		};
		const createIsNeededCallback = ($els, off, fn) => {
			const testElements = ($els && $els.length) ? $els.get() : null;
			const isNeeded = () => {
				if (!testElements) return true;
				for (const el of testElements)
				{
					if ($.contains(document, el))
					{
						return true;
					}
				}
				return false;
			};
			return testElements ? () => (isNeeded() ? fn() : off()) : fn;
		};
		const createDebounce = (fn, debounce = [50, 1000]) => {
			const [debounceWait, maximumWait] = typeof debounce === 'number' ? [debounce, debounce > 500 ? debounce * 2 : 1000] : debounce;
			return Sim.idleDebounce(fn, debounceWait, maximumWait);
		};
		const createBounce = (element, callback, debounce) => {
			let resize = null;
			let offElementsResize;
			let offWindowResize;
			const off = () => {
				resize.cancel();
				offElementsResize && offElementsResize();
				offWindowResize && offWindowResize();
				offElementsResize = offWindowResize = undefined;
			};
			const $els = element ? $(element).filter('*') : null;
			const fn = createIsNeededCallback($els, off, createTriggerPreventCallback(callback));
			resize = createDebounce(fn, debounce);
			const on = () => {
				offElementsResize = (offElementsResize || onElementsResize(resize, $els));
				offWindowResize = (offWindowResize || onWindowResize(resize));
			};
			return {on, off};
		};
		const onResize = (element = null, callback, debounce = 50) => {
			let bounce = createBounce(element, callback, debounce);
			bounce.on();
			return () => (bounce && (bounce = bounce.off()));
		};
		onResize.create = (element = null, callback, debounce = 50) => {
			const bounce = createBounce(element, callback, debounce);
			return {
				on: () => bounce.on(),
				off: () => bounce.off(),
				trigger: () => { onResize.trigger(); },
			};
		};
		onResize.trigger = () => {
			if (createTriggerPreventCallback.lastManualTrigger !== true)
			{
				window.dispatchEvent(new Event('resize'));
			}
		};
		return onResize;
	})();

	Sim.filterHoverElements = (filter) => {
		try
		{
			return $(':hover').filter(filter);
		}
		catch (e)
		{
			return $(); // ignore error, jquery hover may not work in all browsers
		}
	};

	/**
	 * Unified method for closing popups/etc with esc. Last one registered is closed first.
	 * If something is using raw keydown/keyup event, it has priority over this one (if it calls event.preventDefault() in keydown or keyup).
	 */
	Sim.onEscapePress = (() => {
		const isEscapeKey = (e, any = false) => (
			e.which === 27 && // esc
			(any || (
				!e.shiftKey &&
				!e.altKey &&
				!e.ctrlKey &&
				!e.metaKey
			))
		);
		const handlers = {};
		const runHandler = () => {
			const handler = Object.values(handlers).pop(); // last
			if (handler)
			{
				for (const then of handler.then)
				{
					then();
				}
			}
		};
		const lazyPreventDefaultHandler = (event, callPreventDefault, changePrevented, donePrevented) => {
			let preventDefaultCalled = event.defaultPrevented;
			changePrevented(preventDefaultCalled); // not prevented between now and when setTimeout or preventDefault triggers
			if (!preventDefaultCalled)
			{
				if (callPreventDefault)
				{
					event.preventDefault();
				}
				const {preventDefault} = event;
				event.preventDefault = (...args) => {
					if (!preventDefaultCalled)
					{
						changePrevented(true);
						preventDefaultCalled = true;
					}
					return preventDefault.apply(event, args);
				};
				setTimeout(() => {
					event.preventDefault = preventDefault;
					donePrevented(preventDefaultCalled);
				}, 0);
			}
		};
		let eventsRegistered = false;
		let keydownPressed = false;
		const create = () => {
			if (!eventsRegistered)
			{
				document.addEventListener('keydown', (event) => {
					if (isEscapeKey(event, true))
					{
						keydownPressed = false;
						if (isEscapeKey(event))
						{
							lazyPreventDefaultHandler(
								event,
								!!_.size(handlers),
								(prevented) => (keydownPressed = !prevented),
								(prevented) => {}
							);
						}
					}
				});
				document.addEventListener('keyup', (event) => {
					if (isEscapeKey(event) && keydownPressed)
					{
						lazyPreventDefaultHandler(
							event,
							!!_.size(handlers),
							(prevented) => (keydownPressed = (keydownPressed && !prevented)),
							(prevented) => (keydownPressed && !prevented && runHandler())
						);
					}
					else if (isEscapeKey(event, true))
					{
						keydownPressed = false;
					}
				});
				eventsRegistered = true;
			}
			const local = {
				id: _.uniqueId('onEscape'),
				then: [],
			};
			return {
				on: () => {
					delete handlers[local.id];
					handlers[local.id] = local;
				},
				off: () => { delete handlers[local.id]; },
				addThen: (then) => local.then.push(then),
			};
		};
		return (then) => {
			const handler = create();
			if (then) handler.addThen(then);
			return handler;
		};
	})();

})();
