define(['Helpers/Scrolling'], (Scrolling) => {

	class TableSelectorToggler
	{

		constructor(elements, paginator, localTable, fullTable)
		{
			this.elements = elements;
			this.paginator = paginator;
			this.localTable = localTable;
			this.fullTable = fullTable;

			this.elements.whenChildrenAddedOrRemoved(this.elements.tableFiltering[0], () => this._updateTableFiltering());
			this.elements.tableSelector.find('.toggleViewMode').on('click', () => this.toggle());

			this.elements.tsTableSticky.on('click', 'table > tbody > tr.tsRow.m-table__row--selected a.tsRowLink', (event) => {
				event.preventDefault();
				const row = $(event.currentTarget).closest('tr.tsRow[data-position]');
				this.toggle(row.attr('data-position'));
			});
		}

		toggle(position)
		{
			const toContentView = !this.elements.tableSelector.hasClass('tsShowContent');
			const after = toContentView ? this._scrollToContentView(position) : this._scrollToTableView(position);
			this.elements.tableSelector.toggleClass('tsShowContent', toContentView).toggleClass('tsShowTable', !toContentView);
			this.elements.tableFiltering.toggleClass('cleanTHead', toContentView);
			this._updateTableFiltering();
			after();
		}

		_updateTableFiltering()
		{
			const hasClassShowTable = this.elements.tableSelector.hasClass('tsShowTable');
			const widthPercent = 100 / this.localTable.localRenderer.localTDIndexes.length;
			let colspan = 0;
			this.elements.tableFiltering.find('> tr:first > th').each((index, el) => {
				const visible = (hasClassShowTable || this.localTable.localRenderer.localTDIndexes.indexOf(index) !== -1);
				$(el)
					.toggle(visible)
					.css('width', (!hasClassShowTable && visible) ? `${widthPercent}%` : '')
				;
				visible && (colspan += el.colSpan);
			});
			if (this.lastTableFilteringColspan !== colspan)
			{
				this.lastTableFilteringColspan = colspan;
				this.elements.realTable.find('> * > tr > td[column-span-all], > * > tr > th[column-span-all]').attr('colspan', colspan);
			}
		}

		_scrollToContentView(moveToPosition)
		{
			if (moveToPosition === undefined)
			{
				const rows = this.elements.realTable.find('> tbody > tr[data-position]');
				const first = _.find(rows, (el) => Scrolling.isStartMostlyInViewport(el, 0, 0.382)) || rows[0];
				moveToPosition = first ? $(first).attr('data-position') : 0;
			}
			return () => {
				this.localTable.moveToPosition(moveToPosition, undefined, false);
				document.documentElement.scrollTop = 0;
			};
		}

		_scrollToTableView(moveToPosition = this.paginator.local.start)
		{
			return async () => {
				await this.fullTable.moveToPosition(moveToPosition, true);
				if (this.paginator.remote.itemCount === 0)
				{
					document.documentElement.scrollTop = 0;
					return;
				}
				const el = this.elements.realTable.find(`> tbody > tr[data-position="${$.escapeSelector(moveToPosition)}"]`)[0];
				if (!el) throw new Error(moveToPosition);
				if ((Number(moveToPosition) % this.paginator.remote.itemsPerPage) === 0)
				{
					if ('scrollIntoViewIfNeeded' in Element.prototype)
					{
						el.scrollIntoViewIfNeeded(); // chrome only
					}
					else if (document.documentElement.scrollTop !== 0)
					{
						el.scrollIntoView();
					}
				}
				else
				{
					el.scrollIntoView();
				}
			};
		}

	}

	return TableSelectorToggler;
});
