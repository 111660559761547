define(['app/components/Popups/Popups'], (Popups) => {

	class ContractEdit
	{
		#form;
		#popupTemplate;
		#deleteWithAdjustmentConfirmText;

		constructor(options)
		{
			this.#form = $(options.form);
			this.#popupTemplate = options.popupTemplate;
			this.#deleteWithAdjustmentConfirmText = options.deleteWithAdjustmentConfirmText;

			this.#form.on('change', 'input[name$="[dateStart]"]', (e) => {
				const thisInput = $(e.target);
				const date = thisInput.getDatepickerDate();
				if (date)
				{
					const prevDate = new Date(date.getTime() - 1);
					const prevStageRow = thisInput.closest('.stageRow').prev('.stageRow');
					prevStageRow.find('input[name$="[dateEnd]"], input.endDateDisabled').val(Sim.formatDate(prevDate));
				}
			});

			this.#form.on('click', '.loadPrevious', (e) => {
				this.#updateActive(true);
			});

			this.#form.on('click', '.openDeletePopup', () => this.#openDeletePopup());

			this.#updateActive();
			this.#updateDelete();
		}

		#updateActive(clicked = false)
		{
			const loadPrevious = this.#form.find('.loadPrevious');
			const counter = loadPrevious.find('> input');
			if (clicked)
			{
				counter.val(Number(counter.val()) + 1);
			}
			const actives = this.#form.find('.stageExtend').slice(-Number(counter.val()));
			actives.addClass('stageExtend--active');
			const next = this.#form.find('.stageExtend:not(.stageExtend--active):last');
			loadPrevious.toggleClass('loadPrevious--enabled', !!next.length);
			loadPrevious.find('> span').text(next.data('prevButtonText') || '');
		}

		#createDeletePopupContent(popupId)
		{
			const htmlIdsPostfix = `-delPopup${popupId}`;
			const cFormRSC = this.#form.find('.rentStagesContainer').clone();
			cFormRSC.find('*[id]').attr('id', (i, val) => val + htmlIdsPostfix);
			cFormRSC.find('*[for]').attr('for', (i, val) => val + htmlIdsPostfix);
			cFormRSC.find('select, input:not([type="checkbox"])').attr('disabled', true);

			const content = $('<div>').html(this.#popupTemplate).contents();
			content.find('.contractDeletePopupContent').append(cFormRSC);

			return content;
		}

		#openDeletePopup()
		{
			Popups.add('rentStagesDeletePopup', (popup) => {
				popup.$el.addClass('medium');
				const content = this.#createDeletePopupContent(popup.id);
				const popupRows = content.find('.stageExtend > .stageRow');

				popup.$el.on('click', '.deleteStageRows', () => {
					const checked = popupRows.find('.stageRow__deleteFrom input:checked');
					if (checked.is('[data-has-adjustment-after]') && !confirm(this.#deleteWithAdjustmentConfirmText))
					{
						return;
					}
					const popupFirstToDeleteRow = checked.first().closest('.stageRow');
					const fromIndex = popupRows.index(popupFirstToDeleteRow);
					this.#updateDelete(fromIndex);
					this.#form.trigger('Rentflow:ContractLengthCount.update');
					popup.close();
				});

				popup.$el.on('mouseenter mouseleave', '.stageRow__deleteFrom:not(.stageRow__deleteFrom--disabled) label', (e) => {
					$(e.target).parents('.stageRow, .stageExtend').toggleClass('stageRow--deleteHover', e.type === 'mouseenter');
				});

				popup.$el.on('change', '.stageRow__deleteFrom input', (e) => {
					const input = $(e.target);
					const stageRow = input.closest('.stageRow');
					const fromIndex = input.prop('checked') ? popupRows.index(stageRow) : -1;
					this.#updateDelete(fromIndex, popup.$el);
				});

				return content;
			});
		}

		#updateDelete(fromIndex = null, root = this.#form)
		{
			let deletedFromHere = false;
			let $prevRow;
			for (const [index, el] of Object.entries(root.find('.stageExtend > .stageRow').get()))
			{
				const $row = $(el);
				const $checkbox = $row.find('.stageRow__deleteFrom input');
				const deleted = fromIndex === null ? Boolean($checkbox.prop('checked')) : fromIndex === Number(index);
				$prevRow && $prevRow.toggleClass('stageRow--lastNotDeleted', deleted && !deletedFromHere);
				$prevRow = $row;
				deletedFromHere = deletedFromHere || deleted;
				$checkbox.prop('checked', deletedFromHere);
				$row.toggleClass('stageRow--toDelete', deletedFromHere);
			}
			this.#form.find('.openDeletePopup').toggleClass('openDeletePopup--deleted', deletedFromHere);
			$prevRow && $prevRow.toggleClass('stageRow--lastNotDeleted', !deletedFromHere);
		}

	}

	return ContractEdit;
});
