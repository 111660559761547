(() => {

	const Sim = window.Sim || (window.Sim = {});
	const inits = [];
	let mainSelector = '';

	const whenElementFullyLoaded = ($el, then) => {
		if (jQuery.isReady === true || !$el.length)
		{
			return then();
		}
		let p = $el;
		do
		{
			if (p.next().length)
			{
				return then();
			}
		}
		while ((p = p.parent(':not(body, body > *)')).length);
		_.delay(whenElementFullyLoaded, 200, $el, then);
	};

	const add = (selector, eventType, fn, preventDefaultGlobalInitEvents = false) => {
		const calledOn = new Set;
		const callFn = (el, event) => {
			if (!calledOn.has(el[0]))
			{
				calledOn.add(el[0]);
				return fn(el, event);
			}
		};
		mainSelector += (mainSelector ? ',' : '') + selector;
		inits.push((els) => {
			els.filter(selector).each(function () {
				callFn($(this));
			});
		});
		const nsEventType = (eventType || '').replace(/(\S+)/gu, '$1.siminit');
		const globalEventFn = (event) => {
			const el = $(event.currentTarget);
			const result = whenElementFullyLoaded(el, () => callFn(el, event));
			if (preventDefaultGlobalInitEvents && !calledOn.has(el[0]))
			{
				event.preventDefault();
			}
			return result;
		};
		$(document).on(nsEventType, selector, globalEventFn);
	};

	const init = (rootNode = $(document.body)) => {
		const els = rootNode.find(mainSelector).add(rootNode.filter(mainSelector));
		els.length && _.invoke(inits, 'call', null, els);
	};

	init.ready = () => {
		$(() => {
			$(document).off('.siminit');
			init();
		});
		for (const p of ['ready', 'addGlobalEvent', 'addDirectEvent', 'addDirectHandler'])
		{
			delete init[p];
		}
	};

	init.addGlobalEvent = (selector, eventType, fn) => {
		$(document).on(eventType, selector, function (event) {
			return fn.call(this, $(this), event);
		});
	};

	init.addDirectEvent = (selector, eventType, fn) => {
		add(selector, eventType, (el, event) => {
			el.on(eventType, function (event2) {
				return fn.call(this, $(this), event2);
			});
			if (event) return fn.call(el[0], el, event);
		});
	};

	init.addDirectHandler = (selector, eventType, fn) => {
		add(selector, eventType, (el, event) => { fn.call(el[0], el, (eventType && event) ? event : undefined); }, true);
	};

	Sim.init = init;
})();
