define(['./PopupNameCompress'], (PopupNameCompress) => {

	class PopupParameters
	{

		/**
		 * <pre>
		 *	<a id="x" n:popup="Foo param1 => abc">
		 *	PopupParameters.modifyOnElement($('#x'), {param2 => bca});
		 * </pre>
		 */
		static modifyOnElement(el, extraParameters = {})
		{
			if (!el.is('a[data-popup][data-popup-source]')) throw new Error;
			const {href, popupName} = this.createFromElement(el, extraParameters);
			el.attr({href, 'data-popup': popupName});
		}

		static createFromElement(el, extraParameters = {})
		{
			const source = JSON.parse(el.attr('data-popup-source'));
			const parameters = _.extend({}, source[1], extraParameters);
			return this.createRaw(source[0], parameters, el.attr('href'), source[2]);
		}

		static createRaw(name, parameters = {}, hrefReference = '', popupComponentName = 'popup')
		{
			const SEPARATOR = '__'; // Sim\PopupNameEncoder::SEPARATOR
			const encodeParams = (params) => { // Sim\PopupNameEncoder::encodeParams
				params = JSON.stringify(params);
				params = encodeURIComponent(params);
				params = params.replace(/[^a-zA-Z0-9%]/gu, (match) => `%${match.charCodeAt(0).toString(16).toUpperCase()}`);
				params = params.replace(/%/gu, '_');
				return params;
			};
			let popupName = name + SEPARATOR + encodeParams(parameters);
			popupName = PopupNameCompress.encode(popupName);
			const url = new URL(hrefReference, location.href);
			url.searchParams.delete('do'); // Nette\Application\UI\Presenter::SIGNAL_KEY
			url.searchParams.delete('_fid'); // Nette\Application\UI\Presenter::FLASH_KEY
			url.searchParams.set(popupComponentName, popupName);
			return {href: url.href, popupName};
		}

	}

	return PopupParameters;
});
