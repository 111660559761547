
define(['GoogleApi'], function (GoogleApi) {

	function LocationSearch(rootSelector, ajaxLink) {
		var root = $(rootSelector);
		this.elements = {
			input: root.find('input:text.input'),
			expand: root.find('input[type="number"].expand'),
			by: root.find('select.by'),
			language: root.find('select.language'),
			repeat: root.find('button.repeat'),
			request: root.find('div.request'),
			result: root.find('div.result'),
		};
		this.ajaxLink = ajaxLink;
		this.google = new GoogleApi(function (language) {
			return ['https://maps.googleapis.com/maps/api/js', {
				v: '3.exp',
				libraries: 'places',
				language: language,
			}];
		}, this);
		this.google.getTOP([Sim.config.language], this.initEvents);
	}

	LocationSearch.prototype = {

		initEvents: function (google) {
			// https://developers.google.com/maps/documentation/javascript/places-autocomplete
			var service = new google.maps.places.Autocomplete(this.elements.input[0], {types: ['geocode']});
			service.addListener('place_changed', _.bind(function () {
				var place1 = service.getPlace();
				if (!place1.place_id)
				{
					google.maps.event.trigger(this.elements.input[0], 'keydown', {keyCode: 40}); // (down arrow) accepted
					google.maps.event.trigger(this.elements.input[0], 'keydown', {keyCode: 13}); // (enter) accepted
					google.maps.event.trigger(this.elements.input[0], 'blur', {});
					return;
				}
				this.findPlace(place1);
			}, this));

			this.elements.expand.on('change', () => {
				if (this.lastPlace) this.changePlace(this.lastPlace);
			});
			this.elements.by.on('change', () => {
				this.updateExpandDisabled();
				if (this.lastPlace) this.changePlace(this.lastPlace);
			});
			this.elements.language.on('change', () => {
				if (this.lastPlace) this.findPlace(this.lastPlace);
			});
			this.elements.repeat.on('click', () => {
				if (this.lastPlace) this.changePlace(this.lastPlace);
			});

			this.elements.input.one('focus', _.bind(this.google.get, this.google, ['en'], function () {}));

			var hideIrrelevant = function () {
				var found, domItems;
				_.some(service.gm_accessors_ ? service.gm_accessors_.place : null, function (value) {
					if (!_.isObject(value) || !_.isObject(value = value.predictions) || _.isEmpty(value)) return false;
					domItems = $('body > div.pac-container > div.pac-item');
					if (value.length !== domItems.length) return false;
					return found = _.map(value, function (value) {
						return value.types;
					});
				});
				_.each((found && domItems) ? domItems : null, function (el, index) {
					$(el).css('opacity', _.contains(found[index], 'establishment') ? '0.4' : '');
				});
			};
			this.elements.input.on('keyup', function () {
				_.each([0, 250, 500, 750, 1000], _.partial(setTimeout, hideIrrelevant));
			});

			this.updateExpandDisabled();

			setTimeout(_.bind(function () {
				this.elements.input.not('[value=""]').trigger('focus');
			}, this), 500);
		},

		findPlace: function (place1) {
			this.elements.request.empty();
			this.elements.result.empty();
			this.google.get([this.elements.language.val()], function (google, service) {
				if (!service.places)
				{
					service.places = new google.maps.places.PlacesService($('<div>')[0]);
					service.geocoder = new google.maps.Geocoder;
				}
				// 'place.reference' je deprecated od June 24 2014,
				// ale 'place.place_id' nekdy vraci jinou adresu (Napr 'Radlická').
				// Snad to google opravi nez prestane reference fungovat.
				service.places.getDetails(place1.reference ? {reference: place1.reference} : {placeId: place1.place_id}, _.bind(function (place2, status) {
					if (status !== 'OK') return;
					if (place1.address_components.length !== place2.address_components.length)
					{
						// ochrana proti bugu pri pouziti place_id
						place2 = _.extend({}, place1, {
							address_components: _.map(place1.address_components, function (c1) {
								c1 = _.clone(c1);
								_.each(place2.address_components, function (c2) {
									if (c1.types.join() === c2.types.join())
									{
										c1.long_name = c2.long_name;
										c1.short_name = c2.short_name;
									}
								});
								return c1;
							}),
						});
					}
					if (!place2.geometry.viewport)
					{
						service.geocoder.geocode({address: place2.formatted_address}, _.bind(function (places, status) {
							if (status !== 'OK') return;
							var place3 = _.find(places, function (place3) {
								if (this.fa === place3.formatted_address) return true;
								var location3 = place3.geometry.location.toUrlValue();
								if (this.location1 === location3) return true;
								if (this.location2 === location3) return true;
								return false;
							}, {
								fa: place2.formatted_address,
								location1: place1.geometry.location.toUrlValue(),
								location2: place2.geometry.location.toUrlValue(),
							});
							var place4 = _.extend({}, place2, {
								geometry: _.extend({}, place2.geometry, {
									viewport: place3 ? place3.geometry.viewport : null,
								}),
							});
							this.changePlace(place4);
						}, this));
					}
					else
					{
						this.changePlace(place2);
					}
				}, this));
			});
		},

		changePlace: function (place) {
			this.lastPlace = place;
			this.updateExpandDisabled();
			var result = {
				location: place.geometry.location.toUrlValue(),
				viewport: place.geometry.viewport ? place.geometry.viewport.toUrlValue() : null,
				expand: Math.max(this.elements.expand.prop('disabled') ? 0 : parseInt(this.elements.expand.val(), 10, 0)) * 1000,
				types: place.types,
				components: place.address_components,
			};
			this.renderPlaceRequest(place, result);
			$.get(this.ajaxLink, {
				location: result,
				by: this.elements.by.val(),
			}, this.renderPlaceResult());
		},

		renderPlaceRequest: function (place, result) {
			this.elements.request.html(_.template(this.elements.request.attr('data-template'))({
				language: this.elements.language.val(),
				by: this.elements.by.find('option:selected').text(),
				formatted_address: place.formatted_address,
				result: result,
				joinTypes: function (types) {
					return _.without(types, 'political').join(', ');
				},
			}));
		},

		updateExpandDisabled: function () {
			var hasExpand = (this.lastPlace && this.lastPlace.geometry.viewport) && (this.elements.by.val() & 1); // 1 = Sim\Model\LocationSearch::VIEWPORT
			this.elements.expand.prop('disabled', !hasExpand);
		},

		renderPlaceResult: function () {
			var div = this.elements.result.empty();
			return function (data) {
				div.html(_.template(div.attr('data-template'))({
					data: data,
				}));
			};
		},

	};

	return LocationSearch;

});
