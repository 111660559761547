define(['./PopoverAjax'], (PopoverAjax) => {

	class PopoverLazyContent
	{

		constructor(options, updatePopoverHtmlContent)
		{
			if (PopoverLazyContent.ajax === undefined)
			{
				PopoverLazyContent.ajax = new PopoverAjax;
			}
			this._updatePopoverHtmlContent = updatePopoverHtmlContent;
			this.titleHtml = this._extractTextualOption(options, 'title');
			this.contentHtml = this._extractTextualOption(options, 'content');
			this.lazy = options.lazy;
		}

		_extractTextualOption(options, type)
		{
			const valueText = options[`${type}Text`];
			const valueHtml = options[`${type}Html`];
			const valueBoth = options[type];
			const {html} = options; // deprecated but still supported
			if ((valueText && valueHtml) || (valueText && valueBoth) || (valueHtml && valueBoth))
			{
				throw new Error(`Popover can use only one option to set ${type}: [data-${type}-text] or [data-${type}-html] or [data-${type}]`);
			}
			if (html !== undefined && (valueText || valueHtml))
			{
				throw new Error(`Popover [data-html] is deprecated, use [data-${type}-text] or [data-${type}-html]`);
			}
			if (valueText || (!html && valueBoth))
			{
				return Sim.escapeHtml(valueText || valueBoth);
			}
			if (valueHtml || (html && valueBoth))
			{
				return valueHtml || valueBoth;
			}
			return '';
		}

		getTitleHtml()
		{
			return this.titleHtml || '';
		}

		getContentHtml()
		{
			const content = this.contentHtml || '';
			const spinnerTemplate = '<div><i class="icon-spinner icon-spin"></i></div>';
			return this.lazy ? `${content}${spinnerTemplate}` : content;
		}

		startLoading(showLoading = false, reload = false)
		{
			if (!this.lazy)
			{
				if (reload) throw new Error('reload after successful load is not implemented');
				return;
			}
			const promise = PopoverLazyContent.ajax.getContent(this.lazy, reload);
			if (showLoading && (this.loading !== false || reload))
			{
				this.loading && this.loading();
				this.loading = Sim.loading();
			}
			if (!this.handledLoading)
			{
				promise
					.finally(() => {
						this.loading && this.loading();
						this.loading = false;
					})
					.then((html) => {
						this._updateHtml(html, true, 'lazy');
					})
					.catch((err) => {
						this.handledLoading = false;
						this._updateHtml(this.getErrorHtml(err), false, 'error');
						if (!(err instanceof PopoverLazyContent.ajax.AjaxError))
						{
							throw err;
						}
					})
				;
				this.handledLoading = true;
			}
		}

		getErrorHtml(err)
		{
			const {responseStatusCode, acceptableUserErrorMessage} = err instanceof PopoverLazyContent.ajax.AjaxError ? err : {};
			const htmlMessage = acceptableUserErrorMessage instanceof jQuery ?
				$('<b>').append(acceptableUserErrorMessage).html() :
				(Sim.escapeHtml(acceptableUserErrorMessage || 'Content could not be loaded.'))
			;
			return `
				<div class="alert alert-error">
					<b>${htmlMessage}</b>
					<br>
					<i>${Sim.escapeHtml(responseStatusCode || '')} error</i>
					<br>
					<a class="btn btn-mini" href="javascript:void(0)" data-reload-popover-content><i class="las la-lg la-redo-alt"></i></a>
				</div>
			`;
		}

		stopLoading()
		{
			this.loading && this.loading();
		}

		_updateHtml(html, persistent = false, updateSourceType)
		{
			let title = undefined;
			let content = html;
			if (html.includes('popover-title'))
			{
				const el = $('<div>').html(html);
				const titleEl = el.find('> .popover-title');
				if (titleEl.length)
				{
					titleEl.detach();
					content = el.html();
					title = titleEl.html();
				}
			}
			if (persistent)
			{
				this.lazy = false;
			}
			this._updatePopoverHtmlContent({title, content, persistent, updateSourceType});
		}

	}

	return PopoverLazyContent;
});
