define(['ContinuousAjaxUpdated'], (ContinuousAjaxUpdated) => {

	class RepetitionCountAjax
	{

		#options;
		#el;
		#elements = {};
		#text;
		#toggleElements = {};
		#ajax;

		#supportsBreakDays = new Map([
			[null, 'breakDayNone'],
			[false, 'breakDayNone'],
			[7, 'breakDayWeek'],
			[31, 'breakDayMonth'],
		]);

		constructor(options)
		{
			const form = $(options.form);
			this.#el = form.find(options.el);
			if (this.#el.length !== 1) throw new Error;

			const defaults = {
				finiteText: '%s payments',
				infiniteText: 'more than %s payments',
				elementDateStart: 'input[name="dateStart"]',
				elementDateEnd: 'input[name="dateEnd"]',
				elementRepetitionType: 'select[name="repetition"]',
				elementRepetitionTimes: 'input[name="repetitionTimes"]',
				elementBreakDay: 'input[name="breakDay"]',
				ifElements: [],
			};
			this.#options = {...defaults, ...options, ...this.#el.data()};

			this.#prepareElements(form, (el) => {
				if (el.is(':input'))
				{
					el.on('change', (e) => this.#change(e.currentTarget));
					if (el.is('input'))
					{
						el.on('input', (e) => this.#change(e.currentTarget));
					}
				}
			});

			this.#text = $('<span class="text">').appendTo(this.#el);
			this.#ajax = new ContinuousAjaxUpdated({
				...this.#options.ajax,
				spinner: $('<i class="loading icon-spinner icon-spin">').appendTo(this.#el),
				before: (...a) => this.#before(...a),
				update: (...a) => this.#update(...a),
				args: () => this.#getArguments(),
				disabled: () => (this.#isEnabled() ? null : {text: '', roundToBreakDay: null}),
			});
			this.#change();
		}

		#isEnabled()
		{
			if (!this.#elements.dateStart.val() && !Object.keys(this.#toggleElements).length)
			{
				return false;
			}
			for (const [elName, testValue, result, operator = 'equal'] of this.#options.ifElements)
			{
				if (['equal', 'in'].indexOf(operator.toLowerCase()) === -1) throw new Error(operator);
				const el = this.#elements[elName];
				if (!el) throw new Error(elName);
				let test = false;
				test = operator.toLowerCase() === 'equal' && this.#elements[elName].val() === testValue ? true : test;
				test = operator.toLowerCase() === 'in' && testValue.indexOf(this.#elements[elName].val()) > -1 ? true : test;
				if (test !== Boolean(result))
				{
					return false;
				}
			}
			return true;
		}

		#getArguments()
		{
			const repetitionType = this.#elements.repetitionType.val();
			const repetition = repetitionType.replace('#', parseInt(this.#elements.repetitionTimes.val(), 10) || 0);
			let breakDayEl = this.#elements.breakDay;
			if (!breakDayEl)
			{
				const roundToBreakDay =
					this.roundToBreakDay ??
					this.#options.breakDayDefaultToggles[repetitionType] ??
					(repetitionType === '' ? null : undefined)
				;
				breakDayEl = this.#elements[this.#supportsBreakDays.get(roundToBreakDay)];
				if (!breakDayEl)
				{
					throw new Error(`no breakDay element for ${roundToBreakDay}`);
				}
			}
			return {
				repetition,
				dateStart: Sim.parseDatePickerValue(this.#elements.dateStart),
				dateEnd: Sim.parseDatePickerValue(this.#elements.dateEnd),
				breakDay: parseInt(breakDayEl.val(), 10) || null,
				translateFinite: this.#options.finiteText,
				translateInfinite: this.#options.infiniteText,
				'#roundToBreakDay': this.roundToBreakDay, // to invalidate args in ContinuousAjaxUpdated, value of breakDay depends on this
			};
		}

		#before()
		{
			this.#text.text('');
		}

		#update({text, roundToBreakDay}, args)
		{
			this.#toggleBreakDay(roundToBreakDay);
			this.#text.text(text);
			this.roundToBreakDay = roundToBreakDay;
		}

		#change(el = null)
		{
			if (el && (this.#elements.repetitionType.is(el) || this.#elements.repetitionTimes.is(el)))
			{
				this.roundToBreakDay = undefined;
			}
			this.#ajax.request();
		}

		#toggleBreakDay(roundToBreakDay)
		{
			if (!this.#supportsBreakDays.has(roundToBreakDay))
			{
				throw new Error(roundToBreakDay);
			}
			for (const [type, element] of Object.entries(this.#toggleElements))
			{
				if (!Array.from(this.#supportsBreakDays.values()).includes(type))
				{
					throw new Error(type);
				}
				element.toggle(type === this.#supportsBreakDays.get(roundToBreakDay));
			}
		}

		#prepareElements(form, onEach)
		{
			const elementTypes = {
				element: this.#elements,
				toggleElement: this.#toggleElements,
			};
			for (const [key, value] of Object.entries(this.#options))
			{
				if (value !== null)
				{
					for (const [prefix, container] of Object.entries(elementTypes))
					{
						if (key.startsWith(prefix))
						{
							const el = value.startsWith('<') ? $(value) : form.find(value);
							if (el.length === 0) throw new Error(`${key} ${value}`);
							if (el.length !== 1 && prefix === 'element') throw new Error(key);
							const name = key.substr(prefix.length, 1).toLowerCase() + key.substr(prefix.length + 1);
							container[name] = el;
							onEach(el);
						}
					}
				}
			}
		}

	}

	return RepetitionCountAjax;
});
