define(() => {

	class ElementFinder
	{

		static $one(selector, requireOne = true)
		{
			const $el = $(selector);
			if (requireOne ? $el.length !== 1 : $el.length > 1)
			{
				throw new Error(`${requireOne ? 'Exactly one element' : 'Element'} for '${selector}' not found (${$el.length}).`);
			}
			return $el;
		}

		#root;

		constructor(root, isSelector = null)
		{
			this.#root = ElementFinder.$one(root);
			if (isSelector !== null && !this.#root.is(isSelector))
			{
				throw new Error(`Root '${root}' expected to be '${isSelector}'`);
			}
		}

		findOne(selector, requireOne = true)
		{
			const $el = this.findAny(selector);
			if (requireOne ? $el.length !== 1 : $el.length > 1)
			{
				throw new Error(`${requireOne ? 'Exactly one element' : 'Element'} for '${selector}' inside of root not found (${$el.length}).`);
			}
			return $el;
		}

		findSome(selector, requireSome = true)
		{
			const $el = this.#root.find(selector);
			if (requireSome && $el.length === 0)
			{
				throw new Error(`No element for '${selector}' inside of root found (${$el.length}).`);
			}
			return $el;
		}

		findAny(selector, requireSome = false)
		{
			return this.findSome(selector, requireSome);
		}

		*iterateSome(selector, requireSome = true)
		{
			for (const el of this.findSome(selector, requireSome))
			{
				yield $(el);
			}
		}

		iterateAny(selector, requireSome = false)
		{
			return this.iterateSome(selector, requireSome);
		}

		on(selector = undefined, types, fn = null)
		{
			if (typeof types === 'object' && fn === null)
			{
				for (const [type, fn2] in Object.entries(types))
				{
					this.#root.on(type, selector, fn2);
				}
			}
			else if (typeof types === 'string' && typeof fn === 'function')
			{
				this.#root.on(types, selector, fn);
			}
			else
			{
				throw new Error;
			}
		}

		derive(selector)
		{
			return new ElementFinder(this.findOne(selector));
		}

		deriveClosest(selector)
		{
			return new ElementFinder(this.#root.closest(selector));
		}

		get $root()
		{
			return this.#root;
		}

	}

	return ElementFinder;
});
