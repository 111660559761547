define(['app/components/DropdownMenuSearch/DropdownMenuSearch'], (DropdownMenuSearch) => {

	class SearchInput extends DropdownMenuSearch
	{

		#changeButton;
		#selectedText;
		#hiddenInputs;
		#searchInput;

		constructor(options)
		{
			options.searchInputSelector = 'input[type="text"].o-searchInput__searchInput';
			super(options);
			this.#searchInput = this.$find(options.searchInputSelector);

			this.#hiddenInputs = this.$find().findAny('input[type="hidden"].o-searchInput__hiddenInput');
			for (const el of this.#hiddenInputs)
			{
				if ($(el).data('SearchInput'))
				{
					throw new Error($(el).attr('name'));
				}
			}
			this.#hiddenInputs.data('SearchInput', true);

			this.#changeButton = this.$find('.o-searchInput__changeButton');
			this.#changeButton.on('click', (e) => {
				this.selectItem(null, null, e);
				setTimeout(() => this.#searchInput.trigger('focus'));
			});

			this.#selectedText = this.$find('.o-searchInput__selectedText');
			if (this.#selectedText.data('submittedValue')) // [data-submitted-value]
			{
				const el = $('<span>');
				const value = this.renderItem(el, this.#selectedText.data('submittedValue'));
				this.#selectedText.html(el);
				super.selectItem(null, value, undefined); // skips this is to fill super.#lastValue
				this.#changeButton.toggleClass('o-searchInput__changeButton--hideIfDefault', !!options.hideChangeButtonIfDefault);
			}
			else if (options.focusSearchIfFilled && (options.minLength === 0 || this.#searchInput.val() !== '') && this.#searchInput.is(':visible'))
			{
				this.#searchInput.trigger('focus');
			}
		}

		renderItem(el, item, key)
		{
			if (item.disabled || typeof item.disabled === 'string')
			{
				el.closest('li')
					.addClass('m-dropdownMenu__row--disabled')
					.attr('title', typeof item.disabled === 'string' ? item.disabled : null)
				;
			}
			return super.renderItem(el, item.text, item.value);
		}

		selectItem(el, value, event)
		{
			super.selectItem(el, value, event);
			this.#hiddenInputs.val('');
			for (const [name, id] of Object.entries(value || []))
			{
				const $el = this.#hiddenInputs.filter(`[name$="[${$.escapeSelector(name)}]"]`);
				if ($el.length !== 1) throw new Error(name);
				$el.val(id);
			}
			this.#hiddenInputs.trigger('change');
			this.#selectedText.html(el ? el.contents().clone() : ''); // keeps highlighting
			this.#changeButton.removeClass('o-searchInput__changeButton--hideIfDefault');
		}

	}

	return SearchInput;
});
