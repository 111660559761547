
define(['app/components/FormRepeater/FormRepeater', 'app/components/CurrencyFormChanger/CurrencyFormChanger'], function (FormRepeater, CurrencyFormChanger) {

	function OfferFormAmounts (currencyInputId, amountsParameters, amountsObjectsParameters) {
		this.currency = new CurrencyFormChanger(document.getElementById(currencyInputId));

		this.amounts = new FormRepeater(amountsParameters.rootId, amountsParameters.formName, amountsParameters.containerName);
		this.amountsObjects = [];
		this.amountsObjectsParameters = amountsObjectsParameters;

		this.amounts.changed.setTriggerDebounce(50);
		this.amounts.changed.setTriggerCreateArguments(this.createChangedArguments.bind(this));

		Sim.redefineFunction(this.amounts, 'add', function (old) {
			old();
			this.triggerAddAmount();
		}.bind(this));

		this.initEvents();
		this.triggerAddAmount();
	}

	OfferFormAmounts.prototype = {

		initEvents: function () {
			var self = this;

			this.amounts.$target.on('change', '.amountRow .types :radio', function () {
				$(this).closest('.amountRow').attr('data-type', this.value);
				var c = self.amounts.$target.find('.amountRow .types :radio:checked[value="objects"]');
				var isDisabled = c.length !== 0;
				self.amounts.$target.find('.amountRow .types :radio[value="objects"]').not(c)
					.prop('disabled', isDisabled).closest('label').toggleClass('disabled', isDisabled)
				;
			});
			this.amounts.$target.on('click', '.amountRow .rangeChange', function () {
				$(this).closest('.amountRow')
					.find('.types :radio')
					.filter('[value="single"], [value="range"]')
					.filter(':not(:checked)')
					.prop('checked', true)
					.trigger('change')
				;
			});

			this.amounts.$target.on('change keyup', 'input.object-price, input.object-charges', function () {
				self.updateSums(this);
			});

			this.currency.initEvents(self.amounts.changed.trigger);
		},

		triggerAddAmount: function () {
			var self = this;

			this.amounts.$target.find('.amountRow .types :radio:checked').trigger('change');

			this.amounts.$target.find('.objects[data-repeater]').each(function () {
				if ($(this).data('repeater')) return;
				self.createAmountsObject($(this).closest('.amountRow').attr('data-repeater-row'));
			});
		},

		triggerObjectsChanged: function (objects) {
			this.lastObjects = objects;
			_.each(this.amountsObjects, function (repeater) {
				this.fillObjects(repeater, objects);
			}, this);
		},

		createAmountsObject: function (amountIndex) {
			var p = this.amountsObjectsParameters;
			var r = new FormRepeater(this.amounts.fillIndex(p.rootId, amountIndex), p.formName, this.amounts.fillIndex(p.containerName, amountIndex));
			this.amountsObjects.push(r);
			this.fillObjects(r, this.lastObjects);
		},

		fillObjects: function (repeater, objects) {
			var oldElements = (repeater.oldElements ? repeater.oldElements : repeater.oldElements = {});

			_.each(repeater.getRows(), function (el) {
				var $el = repeater.remove(el);
				var objectIdentifier = $el.find('input[type="hidden"].objectIdentifier').val();
				oldElements[objectIdentifier] = $el;
			});

			_.each(objects, function (object) {
				var old = _.reduce([object.objectIdentifier].concat(object.wasObjectIdentifiers), function (memo, objectIdentifier) {
					return (memo || oldElements[objectIdentifier]);
				}, null);
				var $el = repeater.add(old ? old.clone(true) : null);
				$el.find('input[type="hidden"].objectIdentifier').val(object.objectIdentifier);
				$el.find('.objectName').text(object.label);
				oldElements[object.objectIdentifier] = $el;
			});

			this.updateSums(repeater.$root);
		},

		updateSums: function (el) {
			var $el = $(el).closest('.amountRow');
			_.each(['object-price', 'object-charges'], function (type) {
				var sum = _.reduce($el.find('input.' + type), function (memo, el) {
					return memo + Sim.number.parse(el.value);
				}, 0);
				$el.find('[data-type="objects"] .sum.' + type).text(Sim.number.format(sum));
			});
		},

		createChangedArguments: function () {
			return [
				function getPrimaryAmount(type) {
					if (type === 'currency')
					{
						return this.currency.val();
					}
					if (type !== 'price' && type !== 'charges') throw new Error;
					var sum = 0;
					var primary = this.amounts.getRows().first();
					if (primary.attr('data-type') === 'single')
					{
						sum = Sim.number.parse(primary.find('[data-type~="single"] input[name$="[' + type + 'From]"]').val());
					}
					else if (primary.attr('data-type') === 'range')
					{
						var avg = function (from, to) {
							if (from === '') return Sim.number.parse(to);
							if (to === '') return Sim.number.parse(from);
							return (Sim.number.parse(to) + Sim.number.parse(from)) / 2;
						};
						sum = avg(
							primary.find('[data-type~="range"] input[name$="[' + type + 'From]"]').val(),
							primary.find('[data-type~="range"] input[name$="[' + type + 'To]"]').val()
						);
					}
					else if (primary.attr('data-type') === 'objects')
					{
						sum = Sim.number.parse(primary.find('[data-type~="objects"] .sum.object-' + type).text());
					}
					return sum;
				}.bind(this),
				function getObjectAmount(type, objectIdentifier) {
					var repeater = this.amounts.getRows().filter('[data-type="objects"]:first').find('.objects[data-repeater]').data('repeater');
					if (!repeater) return null;
					var $el = repeater.oldElements[objectIdentifier];
					if (!$el) return null;
					if (type !== 'price' && type !== 'charges') throw new Error;
					var val = $el.find('input.object-' + type).val();
					return Sim.number.parse(val);
				}.bind(this),
			];
		},

	};

	return OfferFormAmounts;
});
