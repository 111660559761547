define(['Helpers/ElementFinder', 'ContinuousAjaxUpdated', 'Nette'], (ElementFinder, ContinuousAjaxUpdated, Nette) => {

	class RelatedSelectBox
	{

		static NAME_KEY = '__controlName';
		#options;
		#form;
		#ajaxRequests = new Map;
		#disableSubmit;

		constructor(options)
		{
			this.#options = options;
			this.#form = new ElementFinder(options.form);
			this.#disableSubmit = Sim.disableSubmit.create(this.#form.$root);

			this.#form.on('[data-related-select-box-trigger]', 'change input', (e) => {
				const selector = `[data-related-select-box-dependencies*='"${$.escapeSelector(e.currentTarget.name)}":']`;
				for (const $relatedSelectBox of this.#form.iterateAny(selector))
				{
					this.#getAjax($relatedSelectBox).request();
				}
			});

			Sim.observeNodes(this.#form.$root, '[data-related-select-box-dependencies]', {
				addedNodes: true,
				callbackEach: ($el) => this.#init($el),
			});
		}

		#init($selectBox)
		{
			if (this.#disabled($selectBox)) // not trigger ajax when stuff are loaded, but update titles etc when disabled
			{
				this.#getAjax($selectBox).request();
			}
		}

		#getAjax($relatedSelectBox)
		{
			const name = $relatedSelectBox.attr('name');
			if (!this.#ajaxRequests.has(name))
			{
				const ajax = new ContinuousAjaxUpdated({
					...this.#options.ajax,
					before: (...a) => this.#before($relatedSelectBox, ...a),
					update: (...a) => this.#update($relatedSelectBox, ...a),
					after: (...a) => this.#after($relatedSelectBox, ...a),
					args: () => ({
						[RelatedSelectBox.NAME_KEY]: $relatedSelectBox.attr('data-related-select-box-path'),
						...this.#getDependencyValues($relatedSelectBox),
					}),
					disabled: (...a) => this.#disabled($relatedSelectBox, ...a),
				});
				this.#ajaxRequests.set(name, ajax);
			}
			return this.#ajaxRequests.get(name);
		}

		#createTemporaryDisabledPrompt($selectBox, label = '', value = '', title = '')
		{
			const prompt = $('<option data-is-temporary>');
			prompt.text(label);
			prompt.val(value);
			$selectBox.find('> option, > optgroup').remove();
			prompt.appendTo($selectBox);
			$selectBox.prop('disabled', true);
			$selectBox.attr('title', title || null);
		}

		#disabled($selectBox)
		{
			let original = $selectBox.data('RelatedSelectBox-original');
			if (!original)
			{
				$selectBox.data('RelatedSelectBox-original', original = {
					value: $selectBox.val(),
					label: $($selectBox[0].selectedOptions).text(),
					title: $selectBox.attr('title'),
					disabled: $selectBox.prop('disabled'),
					prompt: $selectBox.find('> option[value=""]:not([data-is-temporary])'),
				});
			}
			if (original.disabled)
			{
				return {disabled: {
					value: original.value,
					label: original.label,
					title: original.title,
				}};
			}
			const externallyDisabled = $selectBox.data('RelatedSelectBox-externallyDisabled');
			if (externallyDisabled)
			{
				return {disabled: externallyDisabled};
			}
			if (!this.#validateDependencies($selectBox))
			{
				return {disabled: this.#options.notValid};
			}
		}

		#before($selectBox)
		{
			this.#disableSubmit.start();
			this.#createTemporaryDisabledPrompt($selectBox, this.#options.loadingText);
		}

		#update($selectBox, data)
		{
			if (data.disabled)
			{
				this.#createTemporaryDisabledPrompt($selectBox, data.disabled.label, data.disabled.value, data.disabled.title);
				$selectBox.trigger('change');
			}
		}

		#after($selectBox, data)
		{
			this.#disableSubmit.stop();
			if (!data.disabled)
			{
				const list = [];
				for (const [valueOrLabel, textOrOptions] of data.options)
				{
					let option;
					if (typeof textOrOptions === 'object' && textOrOptions)
					{
						option = $('<optgroup>').attr('label', valueOrLabel);
						for (const [value, text] of Object.entries(textOrOptions))
						{
							option.append($('<option>').val(value).text(text));
						}
					}
					else
					{
						option = $('<option>').val(valueOrLabel).text(textOrOptions);
					}
					list.push(option);
				}
				if (list.length === 1)
				{
					list[0].filter('option').prop('selected', true);
				}

				const original = $selectBox.data('RelatedSelectBox-original');
				$selectBox.find('> option').remove();
				$selectBox.append(original.prompt).append(list);
				$selectBox.prop('disabled', original.disabled);
				$selectBox.attr('title', original.title || null);
				if ($selectBox.find(`> option[value="${$.escapeSelector(original.value)}"]`).length)
				{
					$selectBox.val(original.value);
				}
				$selectBox.data('RelatedSelectBox-original', null);
				if (
					data.newSelectedValue !== undefined &&
					$selectBox.find(`> option[value="${$.escapeSelector(data.newSelectedValue)}"]`).length
				)
				{
					$selectBox.val(data.newSelectedValue);
				}
				$selectBox.trigger('change');
			}
		}

		#getControlInput(name)
		{
			const selector = `[name="${$.escapeSelector(name)}"]`;
			const multi = name.endsWith('[]');
			const $control = multi ? this.#form.findSome(selector) : this.#form.findOne(selector);
			return {multi, $control};
		}

		#getDependencyValues($relatedSelectBox)
		{
			const values = {};
			for (const [name, flatName] of Object.entries($relatedSelectBox.data('relatedSelectBoxDependencies')))
			{
				const {multi, $control} = this.#getControlInput(name);
				const value = $control.attr('data-datepicker-input') ? Sim.parseDatePickerValue($control[0]) : Nette.getValue($control[0]);
				values[flatName + (multi ? '[]' : '')] = (typeof value === 'object' && value !== null && !value.length) ? null : value; // param with [] is omitted by $.ajax
			}
			return values;
		}

		#validateDependencies($relatedSelectBox)
		{
			for (const [name] of Object.entries($relatedSelectBox.data('relatedSelectBoxDependencies')))
			{
				const $control = this.#getControlInput(name).$control.not(':disabled');
				if ($control.length === 0 || !Nette.validateControl($control[0], null, true))
				{
					return false;
				}
			}
			return true;
		}

		disableElement(el, disable = true, title, value, label)
		{
			const $selectBox = $(el);
			if ($selectBox.length !== 1) throw new Error;
			if (!$selectBox.is('[data-related-select-box-dependencies]')) throw new Error;

			const oldObj = $selectBox.data('RelatedSelectBox-externallyDisabled') || null;
			const newObj = disable ? {title, value, label} : null;
			if (oldObj !== newObj) // compares only null
			{
				$selectBox.data('RelatedSelectBox-externallyDisabled', newObj);
				this.#getAjax($selectBox).request();
			}
		}

	}

	return RelatedSelectBox;
});
