
define(() => {

	class DownloadFrame
	{

		constructor(linkDownload)
		{
			this.linkDownload = linkDownload;
			this.events();
		}

		events()
		{
			const zoom = (e) => $(e.target).closest('.downloadFrame__container--zoomable').toggleClass('downloadFrame__container--zoom');
			$('.downloadFrame__container--zoomable > *').on('click', zoom);

			$(() => {
				if ($('.downloadFrame__fallback').is(':visible'))
				{
					this.simulateDownload();
				}
			});

			const object = $('object.downloadFrame__container');
			if (object.length && !this.supportsMimeType(object.attr('type')))
			{
				this.simulateDownload();
			}

			$('.downloadFrame__container').find('> audio, > video').on('error', () => this.simulateDownload());
		}

		supportsMimeType(mimeType)
		{
			const nav = window.navigator;
			return (
				(mimeType === 'application/pdf' && nav.userAgent.indexOf('irefox') !== -1) || // firefox does not have it in navigator.mimeTypes
				!('mimeTypes' in nav) || // assume yes
				(mimeType in nav.mimeTypes)
			);
		}

		simulateDownload()
		{
			// if not possible to display, simulate download behaviour
			$(() => {
				$('.downloadFrame').remove(); // white page
				window.addEventListener('beforeunload', () => setTimeout(() => window.close(), 2000));
				Sim.redirect(this.linkDownload, true);
			});
		}

	}

	return DownloadFrame;
});
