(() => {

	const Sim = window.Sim || (window.Sim = {});

	const disable = (submits) => {
		submits
			.prop('disabled', true)
			.addClass('disabled')
			.attr('data-disabled', (i, value) => (parseInt(value, 10) || 0) + 1)
		;
	};

	const enable = (submits) => {
		submits
			.attr('data-disabled', (i, value) => (parseInt(value, 10) || 0) - 1)
			.filter('[data-disabled=0]')
			.prop('disabled', false)
			.removeClass('disabled')
			.removeAttr('data-disabled')
		;
	};

	const getSubmitButtons = (el) => {
		const form = $(el).closest('form');
		if (form.length !== 1)
		{
			throw new Error;
		}
		return form
			.not('[data-ignore-for-disable-submit]')
			.find('input[type="submit"], button[type="submit"]')
			.not('[disabled]:not([data-disabled]), .disabled:not([data-disabled]), [data-ignore-for-disable-submit]')
		;
	};

	Sim.disableSubmit = (el) => {
		let submits = getSubmitButtons(el);

		let timeout;
		const disableSubmit = () => {
			disable(submits);
			timeout = null;
		};
		if (submits.filter('[data-disabled]').length === 0)
		{
			timeout = setTimeout(disableSubmit, 0);
		}
		else
		{
			disableSubmit();
		}

		const restoreSubmit = () => {
			if (timeout)
			{
				clearTimeout(timeout);
			}
			else if (submits)
			{
				enable(submits);
			}
			timeout = null;
			submits = null;
		};

		restoreSubmit.useNativeSubmitEvent = (event) => {
			if (!(event instanceof $.Event)) throw new Error;
			if (event.type !== 'submit') throw new Error(event.type);
			setTimeout(() => { // later so any even listener can cancel
				if (event.isDefaultPrevented()) // revoke for not ran submits
				{
					restoreSubmit();
				}
				else if (Sim.unloadManager.is())
				{
					const interval = setInterval(() => {
						if (!Sim.unloadManager.is()) // user stopped the navigation, or 15s timeout
						{
							clearInterval(interval);
							restoreSubmit();
						}
					}, 250);
				}
			}, 0);
		};

		return restoreSubmit;
	};

	Sim.disableSubmit.create = (el) => {
		let restoreSubmit;
		return {
			start: () => {
				restoreSubmit && restoreSubmit();
				restoreSubmit = Sim.disableSubmit(el);
			},
			stop: () => {
				restoreSubmit && restoreSubmit();
				restoreSubmit = null;
			},
			isDisabledElsewhere: () => {
				let selects = getSubmitButtons(el).filter('[data-disabled]');
				if (restoreSubmit)
				{
					selects = selects.not('[data-disabled=1]');
				}
				return selects.length !== 0;
			},
		};
	};

})();
