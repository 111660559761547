(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.parseDatePickerValue = (el) => {
		const date = el.getDatepickerDate({allowNonDatepickerFallback: true});
		return date ? $.datepicker.formatDate($.datepicker.W3C, date) : null;
	};

})();
