(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.getCurrentScript = () => {
		const document = window.document;
		return ('currentScript' in document) ? document.currentScript : (() => {
			const scripts = document.getElementsByTagName('script');
			return scripts[scripts.length - 1];
		})();
	};

})();
