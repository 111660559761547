define(['Helpers/ElementFinder'], (ElementFinder) => {

	class BankTransactionMarkDuplicate
	{

		#options;
		#getAmountAndCurrency;
		#root;
		#inputs;
		#dateSelector = 'input[name="actual[date]"]';
		#receiverSelector = 'select[name="actual[receiver]"], select[name="actual[directions][transfer][receiver]"], select[name="actual[directions][deposit][receiver]"]';
		#selectButton;

		constructor(options, getAmountAndCurrency, rootFinder)
		{
			this.#options = options;
			this.#getAmountAndCurrency = getAmountAndCurrency;
			this.#root = rootFinder;

			this.#inputs = {};
			for (const $el of this.#root.iterateSome('input[type="hidden"][name^="search["]'))
			{
				this.#inputs[$el.attr('name').replace(/^search\[(.+)\]$/u, '$1')] = $el;
			}

			this.#root.on(this.#dateSelector, 'change input', () => this.#lookup());
			this.#root.on(this.#receiverSelector, 'change', () => this.#lookup());

			this.#selectButton = this.#root.findOne('input[type="submit"][data-ref="AjaxSubmitFillingFormFactory"]');
			this.#root.on('input[type="radio"][name="linkWithActualPayment"]', 'change', (e) => {
				if ($(e.target).val() !== (this.#getWarning().attr('data-value') || 'no'))
				{
					this.#selectButton.trigger('click');
				}
			});
		}

		check(amountAndCurrency = null)
		{
			this.#lookup.cancel();
			setTimeout(() => this.#lookupNow(amountAndCurrency), 0);
		}

		#lastRequest = {
			queryData: undefined,
			response: undefined,
			abort: undefined,
			ajax: undefined,
			ajaxDelay: undefined,
		};

		#lookup = _.debounce((...a) => this.#lookupNow(...a), 100);

		#lookupNow(amountAndCurrency = null)
		{
			const {amount, currencyCode} = amountAndCurrency || this.#getAmountAndCurrency();
			const data = {
				..._.mapObject(this.#inputs, ($el) => $el.val()),
				amount,
				currencyCode,
				date: amount === null ? null : Sim.parseDatePickerValue(this.#root.findOne(this.#dateSelector)),
				receiver: null,
			};
			if (amount !== null)
			{
				const $receiver = this.#root.findAny(this.#receiverSelector).filter(':visible');
				if ($receiver.length > 1) throw new Error(_.map($receiver, (el) => $(el).attr('name')).join(', '));
				data.receiver = $receiver.val() || null;
			}
			if (this.#lastRequest.queryData === JSON.stringify(data))
			{
				if (
					this.#lastRequest.response !== undefined &&
					this.#lastRequest.abort === undefined &&
					this.#getWarning().hasClass('o-bankTransactionMark__duplicateWarning--uninitialized')
				)
				{
					this.#render(this.#lastRequest.response);
				}
				return;
			}
			if (this.#lastRequest.abort !== undefined)
			{
				this.#lastRequest.abort();
			}
			if (this.#getWarning().length === 0)
			{
				return;
			}
			this.#renderLooking();
			this.#lastRequest.queryData = JSON.stringify(data);
			this.#lastRequest.response = undefined;
			this.#ajaxDelay(500, {
				url: this.#options.ajax.url,
				method: 'POST',
				data: {[this.#options.ajax.parameter]: data},
				dataType: 'json',
				success: (json) => {
					this.#render(this.#lastRequest.response = json);
				},
				error: () => {
					this.#lastRequest.queryData = undefined;
					this.#lastRequest.response = undefined;
				},
			});
		}

		#ajaxDelay(delay, settings)
		{
			if (this.#lastRequest.abort !== undefined)
			{
				this.#lastRequest.abort();
			}
			const clear = () => {
				if (this.#lastRequest.ajaxDelay)
				{
					clearTimeout(this.#lastRequest.ajaxDelay);
					this.#lastRequest.ajaxDelay = undefined;
				}
			};
			const trigger = () => {
				this.#lastRequest.ajax = $.ajax({
					...settings,
					complete: () => {
						this.#lastRequest.ajax = undefined;
						this.#lastRequest.abort = undefined;
					},
				});
			};
			this.#lastRequest.ajaxDelay = setTimeout(trigger, delay);
			this.#lastRequest.abort = () => {
				clear();
				this.#lastRequest.ajax && this.#lastRequest.ajax.abort();
				this.#lastRequest.abort = undefined;
			};
		}

		#renderLooking()
		{
			const $warning = this.#getWarning();
			if ($warning.hasClass('o-bankTransactionMark__duplicateWarning--found') && !$warning.hasClass('o-bankTransactionMark__duplicateWarning--uninitialized'))
			{
				$warning.removeClass('o-bankTransactionMark__duplicateWarning--found');
				$warning.addClass('o-bankTransactionMark__duplicateWarning--looking');
			}
		}

		#render(results)
		{
			const $warning = this.#getWarning();
			if ($warning.length === 0)
			{
				return;
			}
			const warningFinder = this.#root.derive($warning);
			warningFinder.findAny('.o-bankTransactionMark__duplicateWarningRadio:not([data-yes-label])').remove();
			if (results.length)
			{
				const $template = warningFinder.findOne('.o-bankTransactionMark__duplicateWarningRadio[data-yes-label]');
				for (const res of results)
				{
					const id = `o-bankTransactionMark__duplicateWarningRadio-yes-id-${res.actualPaymentId}`;
					const $el = $template.clone().removeAttr('data-yes-label');
					const elFinder = new ElementFinder($el);
					elFinder.findOne('input[type="radio"]')
						.attr('value', res.actualPaymentId)
						.prop('checked', String(res.actualPaymentId) === String($warning.attr('data-value')))
						.attr('id', id)
					;
					const $date = $('<span class="a-hoverableText">').attr('title', res.insertedTime).text(res.insertedDate);
					const html = Sim.escapeHtml($template.attr('data-yes-label').replace('%user%', res.addedBy));
					elFinder.findOne('label')
						.html(html.replace('%date%', $date[0].outerHTML))
						.attr('for', id)
					;
					$el.insertBefore($template);
				}
			}
			else
			{
				warningFinder.findOne('input[type="radio"][value="no"]').prop('checked', true);
			}
			if (!$warning.hasClass('o-bankTransactionMark__duplicateWarning--uninitialized'))
			{
				warningFinder.findOne('input[type="radio"][value="no"]').prop('checked', !results.length);
			}
			$warning.toggleClass('o-bankTransactionMark__duplicateWarning--found', !!results.length);
			$warning.removeClass('o-bankTransactionMark__duplicateWarning--looking');
			$warning.removeClass('o-bankTransactionMark__duplicateWarning--uninitialized');
		}

		#getWarning()
		{
			return this.#root.findOne('.o-bankTransactionMark__duplicateWarning', false);
		}

	}

	return BankTransactionMarkDuplicate;
});
