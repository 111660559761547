define([], function () {

	function CurrencyFormChanger(currencyInput) {
		this.input = $(currencyInput);
		if (this.input.length !== 1 || !this.input.is('select')) throw new Error;
		this.form = this.input.closest('form');
		if (this.form.length !== 1) throw new Error;
	}
	CurrencyFormChanger.prototype = {

		val: function () {
			return this.input.val();
		},

		code: function () {
			var el = this.input.find('option:selected');
			return el.attr('data-code') || el.text();
		},

		initEvents: function (onChanged) {
			var self = this;

			var changedMessage = self.input.attr('data-currency-changed-message');
			changedMessage && self.input.data('currency-changed-last', self.code());

			const change = (els, event) => {
				const code = this.code();
				els.text(code);
				if (onChanged) onChanged(event);
				if (changedMessage && this.input.data('currency-changed-last') !== code)
				{
					this.input.data('currency-changed-last', code);
					if (els
						.filter(':visible')
						.closest('.input-append')
						.find('input[data-amount-input]')
						.filter(function () { return Sim.number.parse(this.value) !== 0; })
						.length !== 0
					)
					{
						alert(changedMessage);
					}
				}
			};

			this.input.one('change', () => {
				Sim.observeNodes(this.form, '[data-currency]', {
					addedNodes: true,
					callbackAll: change,
				});
				this.input.on('change', (event) => { change(this.form.find('[data-currency]'), event); });
			});
		},

	};

	return CurrencyFormChanger;
});
