define(() => {

	class TableSelectorFullTable
	{

		constructor(elements, paginator, ajaxDataLoader)
		{
			this.elements = elements;
			this.paginator = paginator;
			this.ajaxDataLoader = ajaxDataLoader;
		}

		async moveToPosition(position, abortAllOthers = false)
		{
			const page = Math.floor(parseInt(position, 10) / this.paginator.remote.itemsPerPage) + 1;
			abortAllOthers && this.ajaxDataLoader.abortAllExcept(page);
			await this.moveToPage(page);
		}

		async moveToPage(page)
		{
			page = Math.max(Math.min(parseInt(page, 10), this.paginator.remote.maxPage), 1);
			if (this.paginator.remote.page !== page)
			{
				await this.ajaxDataLoader.loadOrRestorePage(page, () => this._whenAjaxStart());
			}
		}

		preloadPage(direction)
		{
			if (direction !== 0 && this.ajaxDataLoader.hasNoAjaxRunning())
			{
				const currentPage = Math.floor(this.paginator.local.start / this.paginator.remote.itemsPerPage) + 1;
				const page = Math.max(Math.min(currentPage + (direction > 0 ? 1 : -1), this.paginator.remote.maxPage), 1);
				if (!this.ajaxDataLoader.hasPageLoaded(page))
				{
					if (this.idlePreloadPage === undefined)
					{
						this.idlePreloadPage = Sim.idleDebounce((_page, idleDeadline) => {
							if (idleDeadline.didTimeout === true) return;
							if (this.elements.tableSelector.hasClass('tsShowTable')) return;
							this.ajaxDataLoader.loadOrRestorePage(_page, () => () => {}).catch(() => {});
						}, 0, 3000);
					}
					this.idlePreloadPage(page);
				}
			}
		}

		_whenAjaxStart()
		{
			const loadingClass = this.paginator.current.start > this.paginator.local.start ? 'loadingPrev' : 'loadingNext';
			const loading = Sim.loading(this.elements.tsTableSticky, loadingClass);
			return () => {
				loading();
			};
		}

	}

	return TableSelectorFullTable;
});
