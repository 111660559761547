(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.flashMessage = (text, type = 'info') => {
		if (!['info', 'warning', 'success', 'error'].includes(type))
		{
			Sim.triggerWarning(`flashMessage only info|warning|success|error type expected but got ${type}`);
		}
		const $container = $('#snippet--flashes');
		if ($container.length !== 1) throw new Error;
		const $msg = $('<div>').addClass(`alert alert-${type}`).text(text);
		$msg.hide().appendTo($container).slideDown(300);
	};

})();
