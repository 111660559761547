
define(() => {

	class TranslationExampleRandomParameter
	{

		constructor(config)
		{
			this.config = config;
			this.defaults = {
				'%pmcId%': this.number(6),
				'%ref%': this.number(6),
				'%variableNumber%': this.number(6),
				'%id%': this.number(3),
				'%maxFileSize%': `${this.number(3)} MB`,
				'%min%': this.number(1),
				'%max%': this.number(3),
				'%inserted%': this.date(),
				'%userName%': this.name(),
				'%name%': this.name(),
				'%addedBy%': this.name(),
				'%user%': this.name(),
				'%email%': `${this.name().toLowerCase().replace(/[^\w]+/gu, '-')}@example.com`,
				'%rootOwnerShortName%': this.config.rootOwnerShortName,
				'%rootOwnerFullName%': this.config.rootOwnerFullName,
				'%currency%': this.currency(),
				'%country%': this.country(),
				'%list%': _.shuffle(['aaa', 'bbb', 'ccc']).join(', '),
				'?': this.unknownParameter(),
			};
			this.defaultIncludes = {
				'inkStart%': (n) => n,
				'inkStop%': (n) => n,
				'%amount': () => this.number(4, true),
				'%percent': () => this.number(2),
				'%decimal': () => this.number(2, 1),
				'%date': () => this.date(),
				'%lang': () => this.language(),
				'%number': () => this.number(2),
				'%count': () => this.number(2),
				'%total': () => this.number(2),
				'%items': () => this.number(2),
			};
		}

		getRandomParameter(name)
		{
			if (!(name in this.defaults))
			{
				const compare = (memo, v, n) => ((n.length > memo.length && name.includes(n)) ? n : memo);
				const found = _.reduce(this.defaultIncludes, compare, '');
				this.defaults[name] = found ? this.defaultIncludes[found](name) : this.defaults['?']();
			}
			return this.defaults[name];
		}

		unknownParameter()
		{
			let list = _.shuffle(['aaaa', 'bbbb', 'cccc', 'dddd', 'xxxx', 'yyyy', 'zzzz']);
			return () => _.first(list = _.union(_.rest(list), _.last(list, 1)));
		}

		number(n, float)
		{
			const int = _.random(Math.pow(10, n - 1), Math.pow(10, n) - 1);
			return float ? Sim.number.format(int, float === true ? 2 : float) : int;
		}

		date()
		{
			return [_.random(1, 30), _.random(1, 12), _.random((new Date).getFullYear() - 10, (new Date).getFullYear() + 5)].join('.');
		}

		name()
		{
			return _.sample(['John Doe', 'Jane Doe']);
		}

		currency() {
			return _.sample(this.config.currencies).toUpperCase();
		}

		country() {
			return _.sample(this.config.countries).toUpperCase();
		}

		language() {
			return _.sample(this.config.languages);
		}

	}


	class TranslationExample
	{

		constructor(example, config)
		{
			this.config = config;
			this.example = example;
			this.exampleText = example.find('> .text');
			this.input = example.closest('.translation-control').find('textarea.translation');
			this.parameters = example.data('parameters');
			this.numbers = example.data('numbers');
			this.random = new TranslationExampleRandomParameter(config);
			this.getNumber = _.throttle(() => _.sample(this.numbers), 5000);
			this.update();
			const throttleUpdate = _.throttle(() => this.update(), 5, {leading: false});
			for (const event of ['keyup', 'cut', 'paste', 'input'])
			{
				this.input[0].addEventListener(event, throttleUpdate, {passive: true});
			}
			$(this.input).on('change', throttleUpdate);
		}

		update()
		{
			const val = this.input.val();
			const {isDifferent, text, html} = this.createText(val);
			const {isBidiMixed, bidiSplits} = this.createBidiSplits(text);
			const showExample = (isDifferent || isBidiMixed);
			html === undefined ? this.exampleText.text(showExample ? text : '') : this.exampleText.html(showExample ? html : '');
			this.exampleText.attr('title', isBidiMixed ? bidiSplits : null);
			this.example.toggleClass('visible', !!showExample);
		}

		createText(val)
		{
			let text = val;
			if (_.size(this.parameters))
			{
				_.each(this.parameters, (name) => {
					if (name.substr(-1, 1) !== '%') return; // printf
					text = this.replace(text, name, this.random.getRandomParameter(name));
				});
			}
			if (_.size(this.numbers))
			{
				const n = this.getNumber();
				const p = _.find(this.parameters, (name) => name.substr(-1, 1) !== '%');
				if (p)
				{
					text = this.replace(text, p, n);
				}
				else
				{
					text = `${n} ${text}`;
				}
			}
			text = text.replace(/\\%/gu, '%'); // escape

			let html;
			if (this.parameters.some((p) => p.includes('inkSt')))
			{
				const matched = new Set;
				const re = /(\s*)%(\w*[lL]ink)St(?:art|op)%(\s*)(.*?)(\s*)(?:%\2St(?:art|op)%|$|(?=%\w*[lL]ink(?:Start|Stop)%))(\s*)/gu;
				html = Sim.escapeHtml(text).replace(re, ($0, space1, name, space2, innerHtml, space3, space4) => {
					let outHtml;
					if (matched.has(name))
					{
						outHtml = innerHtml;
					}
					else
					{
						matched.add(name);
						const el = $('<a>')
							.html(innerHtml || '*')
							.attr('href', 'javascript:void(0)')
							.attr('title', name)
						;
						outHtml = el[0].outerHTML;
					}
					return space1 + space2 + outHtml + space3 + space4;
				});
				text = text.replace(/%\w*[lL]inkSt(?:art|op)%/gu, '');
			}

			const isDifferent = (val !== '' && val !== text);
			return {isDifferent, text, html};
		}

		createBidiSplits(text)
		{
			if (this.createBidiSplits.replaces === undefined)
			{
				const ltr = 'A-Za-z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFE00-\uFE6F\uFEFD-\uFFFF';
				const rtl = '\u0591-\u07FF\uFB1D-\uFDFF\uFE70-\uFEFC';
				this.createBidiSplits.re = new RegExp(`([${ltr}]+)|([${rtl}]+)|( )|(.)`, 'gu'); // eslint-disable-line no-misleading-character-class
				this.createBidiSplits.replaces = Object.entries(this.config.bidiCharacters)
					.concat([['SPACE', ' ']])
					.map(([label, char]) => [`[${label}]`, char])
				;
			}
			const mixed = {};
			const splits = [];
			text.replace(this.createBidiSplits.re, (split, ltr, rtl, space, other) => {
				mixed.ltr || (mixed.ltr = ltr !== undefined);
				mixed.rtl || (mixed.rtl = rtl !== undefined);
				mixed.space || (mixed.space = space !== undefined);
				mixed.other || (mixed.other = other !== undefined);
				for (const [label, char] of this.createBidiSplits.replaces)
				{
					if (split === char)
					{
						split = label;
						break;
					}
				}
				splits.push(split);
			});
			const bidiSplits = this.config.bidiCharacters.LRO + splits.join('\n') + this.config.bidiCharacters.PDF;
			const isBidiMixed = (
				(mixed.rtl && (mixed.ltr || mixed.other)) ||
				(this.config.isRTLLanguage && (mixed.other || (mixed.ltr && mixed.space)))
			);
			return {isBidiMixed, bidiSplits};
		}

		replace(text, from, to)
		{
			const open = from.substr(-1, 1) !== '%'; // printf
			const re = new RegExp(`([^\\\\]|^)${Sim.escapeRegex(from)}(${open ? '$|[^\\w]|[\\w]*(?:[^%\\w]|$)' : ''})`, 'u');
			if (!open && this.config.isRTLLanguage)
			{
				to = this.config.wrapRTLParameter[0] + to + this.config.wrapRTLParameter[1];
			}
			return text.replace(re, `$1${to}$2`);
		}

	}


	class Translation
	{

		static createOnceForElement(selector, factory)
		{
			const $el = $(selector);
			if (!$el.data(`createOnceForElement.${selector}`))
			{
				$el.data(`createOnceForElement.${selector}`, factory($el) || true);
			}
		}

		constructor(exampleConfig)
		{
			Translation.createOnceForElement('form.translationEdit', ($form) => {
				this.initExample($form, exampleConfig);
				this.initOverwrite($form);
				this.initEdit($form);
				this.initAutogrow($form);
				this.initMarkAsInaccurate($form);
				this.initBidi($form);
				this.initAjax($form);
			});
		}

		initExample($form, exampleConfig)
		{
			$form.find('.translation-control textarea.translation ~ div.example').each((i, el) => {
				new TranslationExample($(el), exampleConfig);
			});
		}

		initOverwrite($form)
		{
			$form.find('.translation-control a.overwrite').on('click', (e) => {
				const $el = $(e.currentTarget);
				const val = $el.attr('data-value') || $el.closest('.translation-control').find('textarea.translation, textarea.source').val();
				$form.find('textarea.translation:not([readonly])').val(val).trigger('change');
				return false;
			});
			$form.find('a.overwriteByLanguage').on('click', (e) => {
				const values = $(e.currentTarget).data('values');
				const all = $form.find('textarea.translation:not([readonly])');
				_.each(values, (val, type) => all.filter(`.${type}`).val(val).trigger('change'));
				return false;
			});
		}

		initEdit($form)
		{
			$form.find('.translation-control .description.canEdit a.editLink').on('click.description', (e) => {
				const description = $(e.currentTarget).closest('.description');
				description.addClass('edit');
				description.find('> textarea').trigger('focus');
				return false;
			});
			$form.filter('.description.canEdit.edit a.editLink').trigger('click.description');
		}

		initAutogrow($form)
		{
			const autogrow = (node, lineHeight, padding, maxHeight) => {
				node.style.height = `${lineHeight}px`;
				node.style.overflow = 'hidden';
				const {scrollHeight} = node;
				node.style.height = `${scrollHeight - padding}px`;
				if (maxHeight !== null && scrollHeight > maxHeight)
				{
					node.style.overflow = null;
				}
				if (scrollHeight === 0 && $(node).is(':visible'))
				{
					setTimeout(() => autogrow(node, lineHeight, padding, maxHeight), 100);
				}
			};
			const els = $form.find('.translation-control textarea.autogrow').get();
			const parse = (value, fallback = 0) => (typeof value === 'number' ? value : fallback);
			const fns = els.map((node) => {
				const lineHeight = parse($.css(node, 'lineHeight', true));
				const maxHeight = parse($.css(node, 'maxHeight', true), null);
				const padding = parse($.css(node, 'paddingTop', true)) + parse($.css(node, 'paddingBottom', true));
				const nodeAutogrow = () => autogrow(node, lineHeight, padding, maxHeight);
				const throttleAutogrow = _.throttle(nodeAutogrow, 5, {leading: false});
				for (const event of ['keyup', 'cut', 'paste', 'input', 'focusin'])
				{
					node.addEventListener(event, throttleAutogrow, {passive: true});
				}
				$(node).on('change', throttleAutogrow);
				return nodeAutogrow;
			});
			Sim.onResize($form, () => _.invoke(fns, 'call'), 400);
			$(() => _.invoke(fns, 'call'));
		}

		initMarkAsInaccurate($form)
		{
			const chs = $form.find('input[type="checkbox"][name^="markAsInaccurate"][data-code]');
			const b = $form.find('button[name="markAsInaccurate[mark]"]');
			const s = b.find('.selected');
			const change = () => {
				const codes = chs.filter(':checked').get().map((el) => $(el).attr('data-code'));
				b.prop('disabled', !codes.length);
				s.text(codes.length ? `(${codes.join(', ')})` : '');
			};
			chs.on('change', change);
			change();
		}

		initBidi($form)
		{
			const focus = ($el, delay = true) => {
				const $input = $el.closest('.translation-control').find('textarea.translation');
				delay && setTimeout(() => $input.trigger('focus'), 0);
				$input.trigger('focus');
				return $input;
			};
			const replaceAtCursor = (input, text) => {
				const isSuccess = document.execCommand('insertText', false, text);
				if (!isSuccess)
				{
					const start = input.selectionStart;
					const end = input.selectionEnd;
					if (typeof input.setRangeText === 'function')
					{
						input.setRangeText(text);
					}
					else
					{
						const {value} = input;
						input.value = value.slice(0, start) + text + value.slice(end);
					}
					input.setSelectionRange(start + text.length, start + text.length);
					input.dispatchEvent(new UIEvent('input', {bubbles: true}));
				}
			};
			$form.find('.translation-control textarea.translation ~ .bidi')
				.on('mousedown mouseenter', '.popover', (e) => focus($(e.currentTarget)))
				.on('click', (e) => {
					const $el = $(e.target);
					const $input = focus($el, false);
					const $insert = $el.closest('[data-insert]');
					if ($insert.length)
					{
						replaceAtCursor($input[0], $insert.attr('data-insert'));
					}
				})
			;
		}

		initAjax($form)
		{
			Sim.enableAjaxSubmit($form)
				.onSuccess((form, payload, textStatus, jqXHR, submittedBy) => {
					const activatedClass = payload.translationEditSuccess ? '' : ' m-table__row--selected';
					if (payload.translationEditSuccess && $(submittedBy).is('button.openNext'))
					{
						const next = $('#translations > .o-translationTable > .m-table__row--selected').next('.m-table__row');
						if (next.length)
						{
							payload.translationEditSuccess = false;
							next.trigger('click');
						}
					}
					if (payload.translationEditSuccess)
					{
						form.find('[data-dismiss="leftRightPanel"]').trigger('closeAndDestroyLeftRightPanelModal');
					}
					const rowId = `snippet--translations.row.${form.data('id')}`;
					const rowHtml = payload.snippets && payload.snippets[rowId];
					if (rowHtml)
					{
						const row = $('<div>').html(rowHtml).find('> div');
						$.nette.getById(rowId).attr('class', row.attr('class') + activatedClass);
						payload.snippets[rowId] = row.html();
					}
					$.nette.success(payload, textStatus, jqXHR);
				})
			;
		}

	}

	return Translation;
});
